import { useState } from "react";
import { InputAdornment, Stack, TextField } from "@mui/material";
import { Button } from "../../../../../components/Button";
import { MdOutlineChevronLeft } from "react-icons/md";
import { SiPix } from "react-icons/si";
import { colors } from "../../../../../styles/global";
import handleDoc from '../../../../../utils/valida_cpf_cnpj';
import * as configsSnackbar from '../../../../../utils/configDefaultSnackbar';
import Mask from 'vanilla-masker';
import { useSnackbar } from "notistack";
import * as apiInterpays from '../../../../../services/apiInterpays';

interface IProps {
    account: any;
    setDestiny: (destinty: any) => void;
    onBack: () => void;
}
/* eslint-disable */
export function SearchKey(props: IProps) {
    const { enqueueSnackbar } = useSnackbar();
    const [searching, setSearching] = useState(false);
    const [key, setKey] = useState('');
    const [typeKey, setTypeKey] = useState<string | null>(null);
    const [messageError, setMessageError] = useState<string | null>(null);
    const [firstSubmit, setFirstSubmit] = useState(false);

    const handleOnSubmit = async () => {
        if (!firstSubmit) setFirstSubmit(true);

        if (!Boolean(messageError)) {
            setSearching(true)
            try {
                const result = await searchKey();
                props.setDestiny(result);
            } catch (error: any) {
                const message = error.response?.data.message ?? 'Ocorreu um erro ao fazer requisição';
                enqueueSnackbar(message, configsSnackbar.warning);
            }
            setSearching(false);
        }
    }

    const searchKey = async () => {

        let keyPix = key;

        if (typeKey === 'PHONE')
            keyPix = `55${key.replace(/\D/g, '')}`
        else if (typeKey === 'CPF' || typeKey === 'CNPJ')
            keyPix = keyPix.replace(/\D/g, '');

        const options = {
            method: 'POST',
            url: '/transfer/check-key',
            data: {
                virtualAccount: props.account,
                keyPix,
                typeKey
            }
        }

        const response = await apiInterpays.API.request(options);

        return { ...response.data, key: keyPix, typeKey };
    }

    const checkKey = (event: any) => {
        const regexKeyEVP = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        let newMessageError = null;
        let newTypeKey = null;
        if (event.target.value.indexOf('@') > -1) {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(key)) {
                newMessageError = 'E-mail inválido!';
            }
            newTypeKey = 'EMAIL';
        } else if (event.target.value.split('-').length > 2) {
            if (!regexKeyEVP.test(key)) {
                newMessageError = 'Chave aleatória inválida';
            }

            newTypeKey = 'EVP';
        } else if (handleDoc.valida_cpf(event.target.value.replace(/\D/g, ''))) {
            event.target.value = Mask.toPattern(event.target.value, '999.999.999-99');
            newTypeKey = 'CPF';
        } else if (handleDoc.valida_cnpj(event.target.value.replace(/\D/g, ''))) {
            event.target.value = Mask.toPattern(event.target.value, '99.999.999/9999-99');
            newTypeKey = 'CNPJ';
        } else if (event.target.value.replace(/\D/g, '').length === 11) {
            event.target.value = Mask.toPattern(event.target.value, '(99) 9 9999 9999');
            newTypeKey = 'PHONE';
        } else {
            newMessageError = 'Chave pix inválida!';
        }

        setMessageError(newMessageError);
        setTypeKey(newTypeKey);
        setKey(event.target.value);
    }

    return (
        <Stack padding={2} spacing={3}>
            <Stack direction="row" alignItems="center">
                <Button type="button" onClick={props.onBack} color="transparent"><MdOutlineChevronLeft size={18} /></Button>
                <h5>Transferência via Pix</h5>
            </Stack>

            {/* <Stack sx={{ flexDirection: 'row', flexWrap: 'wrap' }} spacing={2}>
                <span>Selecione um tipo de chave</span>
                <Grid container spacing={1} justifyContent="center">
                    <Grid lg={3} item>
                        <CheckBoxThema
                            onChange={async (checked) => {
                                if (checked)
                                    setTypeKey('CPF');
                                else
                                    setTypeKey('');
                            }}
                            checked={typeKey === 'CPF'}
                            label="CPF"
                        />
                    </Grid>
                    <Grid lg={3} item>
                        <CheckBoxThema
                            onChange={async (checked) => {
                                if (checked)
                                    setTypeKey('CNPJ');
                                else
                                    setTypeKey('');
                            }}
                            checked={typeKey === 'CNPJ'}
                            label="CNPJ"
                        />
                    </Grid>
                    <Grid lg={3} item>
                        <CheckBoxThema
                            onChange={async (checked) => {
                                if (checked)
                                    setTypeKey('PHONE');
                                else
                                    setTypeKey('');
                            }}
                            checked={typeKey === 'PHONE'}
                            label="Telefone"
                        />
                    </Grid>
                    <Grid lg={3} item>
                        <CheckBoxThema
                            onChange={async (checked) => {
                                if (checked)
                                    setTypeKey('EMAIL');
                                else
                                    setTypeKey('');
                            }}
                            checked={typeKey === 'EMAIL'}
                            label="E-mail"
                        />
                    </Grid>
                    <Grid lg={3} item>
                        <CheckBoxThema
                            onChange={async (checked) => {
                                if (checked)
                                    setTypeKey('EVP');
                                else
                                    setTypeKey('');
                            }}
                            checked={typeKey === 'EVP'}
                            label="Aleatória"
                        />
                    </Grid>
                </Grid>
            </Stack> */}

            <Stack>
                <TextField
                    fullWidth
                    label="Chave pix"
                    size="small"
                    placeholder="CPF, CNPJ, Telefone, E-mail ou Aleatória"
                    // value={key}
                    onChange={event => checkKey(event)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <SiPix color={colors.textLight} />
                        </InputAdornment>
                    }}
                    error={firstSubmit && Boolean(messageError)}
                    helperText={firstSubmit && messageError}
                />
            </Stack>
            <Stack justifyContent="space-between">
                <Button type="button" typeLoad="clock" onClick={handleOnSubmit} load={searching} color="turquoise">Continuar</Button>
            </Stack>
        </Stack>
    )
}