import { Container } from "./styles"
import { useContext, useState } from "react";
import { ContextMenu } from "../../../contexts/Contexts";
import { IconType } from 'react-icons';
import { Link, useLocation } from "react-router-dom";

interface IMenuItem {
    title?: string;
    Icon?: IconType;
    path: string;
}

const getStylePoppover = (top?: number) => {
    const style: React.CSSProperties = {
        top,
        position: 'fixed',
        marginLeft: '37px',
        padding: '0.8rem',
        background: 'var(--white)',
        boxShadow: '3px 3px 3px 0 rgba(0, 0, 0, 0.2)',
        maxWidth: 350,
        minWidth: 230,
        marginTop: -20,
        height: 48,
        display: 'flex',
        alignItems: 'center'
    }

    return style;
}

export function MenuItem({ title, Icon, path }: IMenuItem) {
    const location = useLocation();
    const menuContext = useContext(ContextMenu);
    const classActiveItemMenu = location.pathname.replace('/app/', '') === path ? 'active-item-menu' : '';
    const [stylePoppover, setStylePoppover] = useState<any>(null);

    const mouseInOrOut = (mouseIn: boolean, top?: number) => {
        if (!menuContext.menuIsOpen && mouseIn) setStylePoppover(getStylePoppover(top))
        else setStylePoppover(null);
    }

    return (
        <Link to={path}>
            <Container className={classActiveItemMenu} onMouseEnter={(event: any) => mouseInOrOut(true, event.clientY)}
                onMouseLeave={() => mouseInOrOut(false)} menuIsOpen={menuContext.menuIsOpen}>
                {Icon && (<Icon size={24} />)}
                <div style={stylePoppover}>
                    <span>{title}</span>
                </div>
            </Container>
        </Link>
    )
}