import { Stack, TextField, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from 'yup';
// import * as snackConfig from '../../../utils/configDefaultSnackbar';
import { useRef } from "react";
import { useSnackbar } from 'notistack';
import * as configsSnackbar from '../../../utils/configDefaultSnackbar';
import { Button } from "../../../components/Button";
import { Link } from "react-router-dom";
// import { AxiosRequestConfig } from "axios";
import * as api from '../../../services/apiInterpays';

interface IRegister {
    id: string;
    type: string,
    doc: string,
    name: string,
    birthdate: Date | null,
    phone: string,
    term: boolean,
    companyName: string,
    email: string
    step: number,
    fileFrontDOCPF: string,
    fileVersusDOCPF: string,
    fileCIPJ: string,
    fileCSPJ: string,
}

interface IProps {
    data: IRegister;
    setOnData: (data: IRegister) => void;
}

const schemaForm = Yup.object().shape({
    code1: Yup.string().required('Código não informado'),
    code2: Yup.string().required('Código não informado'),
    code3: Yup.string().required('Código não informado'),
    code4: Yup.string().required('Código não informado'),
    code5: Yup.string().required('Código não informado'),
    code6: Yup.string().required('Código não informado')
});

function maxLength(object: any) {
    if (object.target.value.length > object.target.maxLength) {
        return (object.target.value = object.target.value.slice(0, object.target.maxLength));
    }
}

export function FormValidatePhone({ data, setOnData }: IProps) {
    const { enqueueSnackbar } = useSnackbar();
    const refs: any = {
        code1: useRef(null),
        code2: useRef(null),
        code3: useRef(null),
        code4: useRef(null),
        code5: useRef(null),
        code6: useRef(null),
    }

    const formik = useFormik({
        initialValues: {
            code1: '',
            code2: '',
            code3: '',
            code4: '',
            code5: '',
            code6: ''
        },
        validationSchema: schemaForm,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                await handleSave(values);
                setOnData({ ...data, step: 2 });
            } catch (error: any) {
                const message = error.response?.data.message ?? 'Ocorreu um erro ao fazer requisição';
                enqueueSnackbar(message, configsSnackbar.warning);
            }
        }
    });

    const handleSave = async (values: any) => {
        const options = {
            method: 'PUT',
            url: '/create-validate-phone',
            data: {
                idUser: data.id,
                pin: `${values.code1}${values.code2}${values.code3}${values.code4}${values.code5}${values.code6}`
            }
        }

        const response = await api.API.request(options);

        return response.data;
    }

    const { values, errors, touched, handleChange, isSubmitting } = formik;

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='h5' sx={{ fontSize: 28, color: 'black' }} marginTop={3} >Validando seu telefone</Typography>
            <Typography variant='body2' width={450} align='center' marginTop={2}>Enviamos um código para seu para o telefone <strong>{data.phone}</strong>, para validarmos seu número, informe o codigo recebido:</Typography>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" className="form">

                    <Stack direction="row" spacing={2} marginTop={4} marginBottom={4} justifyContent="center">
                        {Object.keys(values).map((key: string, indice: number) => {
                            const touchedObject = touched as any;
                            const errorsObject = errors as any;

                            return (
                                <TextField
                                    key={key}
                                    inputRef={refs[key]}
                                    onChange={event => {
                                        event.target.value = event.target.value.toUpperCase().replace(/\D/g, '');
                                        handleChange({ target: { name: key, value: event.target.value.replace(/\D/g, '') } });
                                        if ((indice + 1) < Object.keys(values).length) {
                                            if (refs[`code${(indice + 1)}`].current.value !== "")
                                                refs[`code${(indice + 2)}`].current.focus();
                                        }
                                    }}
                                    placeholder="-"
                                    size="medium"
                                    onInput={maxLength}
                                    inputMode="numeric"
                                    type="text"
                                    error={Boolean(touchedObject[key] && errorsObject[key])}
                                    inputProps={{
                                        maxLength: 1,
                                        inputMode: 'numeric',
                                        sx: {
                                            p: 0,
                                            textAlign: 'center',
                                            width: { xs: 40, sm: 40 },
                                            height: { xs: 40, sm: 40 }
                                        }
                                    }}
                                />
                            )
                        })}

                    </Stack>
                    <Button type='submit' color='turquoise' typeLoad='clock' load={isSubmitting} className='margin-top2' style={{ height: 36, alignItems: 'center', fontSize: '16px' }}>
                        Continuar
                    </Button>
                </Form>
            </FormikProvider>
            <div className='flex-direction-column display-flex text-align-center margin-top2 margin-bottom2'>
                <Link to="/login" style={{ fontSize: '14px', margin: '25px 0' }}>Iniciar Sessão</Link>
                {/* <Link to="#" style={{ marginTop: 10 }}>Criar uma conta</Link> */}
            </div>
        </div>
    )
}