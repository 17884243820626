import React from "react";
import FirstPage from "./FirstPage";
import Navbar from "./Navbar";
import Advantages from "./Advantages";
import AnnabankValues from "./AnnabankValues";
import ContactRequest from "./ContactRequest";
import OurProduct from "./OurProduct";
import Footer from "./Footer";

export default function Landing() {
  return (
    <>
      <Navbar />
      <FirstPage />
      {/*<Advantages />*/}
      {/*<Devlab />*/}
      {/*<AnnabankValues />*/}
      {/*<ContactRequest />*/}
      {/*<OurProduct />*/}
      <Footer />
       {/*<WhatsAppWidget />*/}
    </>
  );
}
