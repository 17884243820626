import { Box, Typography } from '@mui/material';
import logo from '../../../assets/logos/logo.png';
import image1 from '../../../assets/images/firstimage1.png';
import image2 from '../../../assets/images/firstImage2.png';
import image3 from '../../../assets/images/firstImage3.png';

const FirstPage = () => {
  return (
    <Box component="section" id="Home" sx={{ backgroundColor: 'background.paper' }}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          padding: '2rem', // Padding responsivo
        }}
      >
        <Box
          component="img"
          src={image2}
          alt="Desenho da página"
          sx={{
            position: 'absolute',
            bottom: { xs: '-4rem', sm: '-6rem', md: '-8rem', lg: '-8rem', xl: '-10rem' },
            left: { xs: '-4rem', sm: '-5.5rem', md: '-7rem', lg: '-9rem', xl: '-10rem' },
            maxWidth: { xs: '20rem', sm: '30rem', md: '40rem', lg: '40rem', xl: '50rem' },
            height: 'auto',
            zIndex: 1,
          }}
        />
        <Box
          component="img"
          src={image1}
          alt="Splash 1"
          sx={{
            position: 'absolute',
            top: { xs: '-2rem', sm: '-1.5rem', md: '-3rem', lg: '-3.5rem', xl: '-4rem' },
            right: { xs: '-6rem', sm: '-8rem', md: '-10rem', lg: '-12rem', xl: '-14rem' },
            maxWidth: { xs: '20rem', sm: '25rem', md: '30rem', lg: '35rem', xl: '40rem' },
            height: 'auto',
            zIndex: 1,
          }}
        />
        <Box
          component="img"
          src={image3}
          alt="Desenho adicional"
          sx={{
            position: 'absolute',
            bottom: { xs: '-2rem', sm: '-3rem', md: '-4rem', lg: '-5rem', xl: '-6rem' },
            right: { xs: '-6rem', sm: '-8rem', md: '-10rem', lg: '-12rem', xl: '-14rem' },
            maxWidth: { xs: '20rem', sm: '25rem', md: '25rem', lg: '35rem', xl: '40rem' },
            height: 'auto',
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            textAlign: 'center',
            zIndex: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h1" sx={{ fontSize: { xs: '2rem', sm: '2rem', md: '3rem', lg: '4rem', xl: '5rem' }, marginBottom: '1rem' }}>
            Acelere seu negócio com o
          </Typography>
          <Box
            component="img"
            src={logo}
            alt="Logo Annabank"
            sx={{
              width: { xs: '18rem', sm: '18rem', md: '20rem', lg: '20rem', xl: '20rem' },
              height: 'auto',
              marginBottom: '1rem',
            }}
          />
          <Typography variant="h2" sx={{ fontSize: { xs: '1.75rem', sm: '1.5rem', md: '1.75rem', lg: '2rem', xl: '2rem' } }}>
            Onde inovação e tecnologia se encontram.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FirstPage;
