import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { AppContent } from "../../../components/AppContent";
import { Breadcrumps } from "../../../components/Breadcrumbs";
import { FaQuestion } from "react-icons/fa6";
import * as apiInterpays from "../../../services/apiInterpays";
import * as handleRequestError from "../../../utils/handleRequestError";
import { List, ListItem, ListItemButton, ListItemText, Collapse, Typography, CircularProgress, Card, Grid } from "@mui/material";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

export function Faq() {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<any[]>([]);
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [cardHeight, setCardHeight] = useState(calculateCardHeight());

  function calculateCardHeight() {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    return windowHeight - 165;
  }

  useEffect(() => {
    function handleResize() {
      setCardHeight(calculateCardHeight());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleFaq = async () => {
    try {
      const response = await apiInterpays.API.get("/v2/perguntas-frequentes");
      const options: any[] = response.data.map((item: any) => ({
        question: item.pergunta,
        answer: item.resposta,
      }));
      setData(options);
    } catch (error: any) {
      const responseError = error.response;
      if (responseError && responseError.data && responseError.data.message) {
        handleRequestError.handleError(responseError, enqueueSnackbar);
      } else {
        console.error("Erro ao processar a resposta da API:", error);
        enqueueSnackbar("Ocorreu um erro ao processar a resposta da API", { variant: "error" });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFaq();
  }, []);

  return (
    <AppContent>
      <Breadcrumps
        Icon={FaQuestion}
        title="Perguntas Frequentes"
        links={[
          { name: "Suporte", href: "#" },
          { name: "Perguntas Frequentes", href: "/app/suporte/perguntas-frequentes" },
        ]}
      />
      <Grid container justifyContent="center" marginTop={-4} padding={1}>
        <Grid xs={12} sm={12} lg={12} item>
          <Card sx={{ p: 2, mt: 3, textAlign: "center", height: `${cardHeight}px` }}>
            {loading ? (
              <CircularProgress sx={{ display: "block", margin: "auto" }} />
            ) : data.length > 0 ? (
              <List sx={{ margin: "auto" }}>
                {data.map((faq, index) => (
                  <React.Fragment key={index}>
                    <ListItem sx={{ borderBottom: "1px solid #0e8ba4", paddingLeft: "0px", paddingRight: "0px" }}>
                      <ListItemButton sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", "&:hover": { background: "#f9f9f9" } }} onClick={() => handleToggle(index)}>
                        <FaQuestion size={24} color={"#0e8ba4"} style={{ marginRight: "10px" }} />
                        <ListItemText>
                          <strong>{faq.question}</strong>
                        </ListItemText>
                        {openIndex === index ? <MdExpandLess size={24} color={"#0e8ba4"} /> : <MdExpandMore size={24} color={"#0e8ba4"} />}
                      </ListItemButton>
                    </ListItem>
                    <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                      <Typography variant="body1" gutterBottom sx={{ padding: 4 }}>
                        {faq.answer}
                      </Typography>
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            ) : (
              <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
                Não há perguntas frequentes disponíveis.
              </Typography>
            )}
          </Card>
        </Grid>
      </Grid>
    </AppContent>
  );
}
