import { AxiosResponse } from "axios";
import * as configsSnackbar from "./configDefaultSnackbar";
import * as enqueueNotifications from "./handleEnqueueNotifications";

const statusFromLogout = [401, 403, 440];

interface ApiErrors {
  type: string;
  value: string;
  msg: string;
  path: string;
  location: string;
}

export const handleError = async (response: AxiosResponse, enqueueSnackbar: any) => {
  if (statusFromLogout.includes(response?.status)) {
    enqueueNotifications.handle("Sessão expirada ou usuário não autenticado.");

    window.location.href = "/logout";
  } else {
    const message = response.data.message ?? "Ocorreu um erro ao fazer sua requisição";
    enqueueSnackbar(message, configsSnackbar.warning);
  }
};

export const handleValidationErrors = (errors: any, enqueueSnackbar: any) => {
  if (errors.errors && Array.isArray(errors.errors)) {
    errors.errors.forEach((error : ApiErrors) => {
      enqueueSnackbar(error.msg, { variant: "error" });
    });
  } else {
    enqueueSnackbar("Ocorreu um erro desconhecido.", { variant: "error" });
  }
};
