import { useRoutes, RouteObject, Navigate } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { MdQrCodeScanner, MdDashboard, MdOutlineChecklist, MdSettings, MdBuild, MdOutlineSupportAgent } from "react-icons/md";
import { FaMoneyBillTransfer, FaFileInvoiceDollar, FaListCheck } from "react-icons/fa6";
import { BsShop } from "react-icons/bs";
import { TbReportMoney } from "react-icons/tb";
import { SiPix } from "react-icons/si";
import { BiBarcodeReader } from "react-icons/bi";
import { FaUserCog, FaBarcode, FaLaptopCode, FaQuestion } from "react-icons/fa";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { RiFileList3Line } from "react-icons/ri";
import { Authenticate } from "../components/Authenticate";

/**Layouts sistema interno e paginas externas  */
import { AppLayout } from "../layouts/AppLayout";
import { Payment } from "../pages/Payment";
/**Paginas */
import { Page404 } from "../pages/Page404";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { Register } from "../pages/Register";
import { Dashboard } from "../pages/App/Dashboard";
import { Extract } from "../pages/App/Extract";
import { InternalPage404 } from "../pages/App/InternalPage404";
import { Pix } from "../pages/App/Tranfers/Pix";
import { Interna } from "../pages/App/Tranfers/Interna";

import { Logout } from "../pages/Logout";
import { Privacy } from "../pages/PrivacyPolicy";
import { Notice } from "../pages/PrivacyNotice";
import { TermsAndConditions } from "../pages/TermsAndConditions";
import { CookiesPolicy } from "../pages/CookiesPolicy";

import { CreateInvoices } from "../pages/App/Invoices/CreateInvoices";

import { Faq } from "../pages/App/Faq/";
import { Tickets } from "../pages/App/Tickets/";
import { ApiConf } from "../pages/App/Config/Api";
import { UsersConf } from "../pages/App/Config/Users";
import { PagamentosBarras } from "../pages/App/Pagamentos/ComCodigoBarras";
import { PagamentosPix } from "../pages/App/Pagamentos/CopiaCola";
import { Redirect } from "../pages/App/Redirect";
import { GiReceiveMoney, GiPayMoney } from "react-icons/gi";

import { ListInvoices } from "../pages/App/Invoices/ListInvoices";
import { Suspense, lazy } from "react";
import { CashIn } from "../pages/App/Cashin";
import { CashOut } from "../pages/App/CashOut";
const Landing = lazy(() => import("../pages/Landing"));

const RECAPTCHA_KEY = "6LclW6AjAAAAAPLkhLbKVWL6lbxoESE6Zy6srbWC";

interface IRoute extends Omit<RouteObject, "children"> {
  title?: string;
  itemMenu?: boolean;
  iconMenu?: any;
  children?: IRoute[];
}

/**paginas externas */
const routesMain: RouteObject[] = [
  {
    path: "/",
    element: (
      <Suspense>
        <Landing />
      </Suspense>
    ),
    children: [
      { path: "", element: <Home /> },
      { path: "/alerta-de-privacidade", element: <Notice /> },
      { path: "/politica-de-privacidade", element: <Privacy /> },
      { path: "/politica-de-cookies", element: <CookiesPolicy /> },
      { path: "/termos-de-uso", element: <TermsAndConditions /> },
      { path: "*", element: <Page404 /> },
    ],
  },
  {
    path: "/payment/:id",
    element: <Payment />,
  },
  {
    path: "/login",
    element: (
      <GoogleReCaptchaProvider useRecaptchaNet reCaptchaKey={RECAPTCHA_KEY} scriptProps={{ async: true, defer: true, appendTo: "body" }}>
        <Login />
      </GoogleReCaptchaProvider>
    ),
  },
  {
    path: "/register",
    element: (
      <GoogleReCaptchaProvider useRecaptchaNet reCaptchaKey={RECAPTCHA_KEY} scriptProps={{ async: true, defer: true, appendTo: "body" }}>
        <Register />
      </GoogleReCaptchaProvider>
    ),
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/politica-de-privacidade",
    element: <Privacy />,
  },
  {
    path: "/alerta-de-privacidade",
    element: <Notice />,
  },
  {
    path: "/termos-de-uso",
    element: <TermsAndConditions />,
  },
  {
    path: "/politicas-de-cookies",
    element: <CookiesPolicy />,
  },
  { path: "*", element: <Page404 /> },
];

/**paginas do sistema interno */
export const routesApp: IRoute = {
  path: "app",
  element: (
    <Authenticate>
      <AppLayout />
    </Authenticate>
  ),
  children: [
    {
      path: "",
      title: "Dashboard",
      element: <Navigate to="/app/dashboard" />,
    },
    {
      path: "*",
      element: <InternalPage404 />,
    },
    {
      itemMenu: true,
      title: "Dashboard",
      iconMenu: MdDashboard,
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      itemMenu: true,
      title: "Extrato",
      iconMenu: TbReportMoney,
      path: "extrato",
      element: <Extract />,
    },
    {
      itemMenu: true,
      title: "Cobranças",
      iconMenu: GiReceiveMoney,
      path: "cashin",
      element: <CashIn />,
    },
    {
      itemMenu: true,
      title: "Saques",
      iconMenu: GiPayMoney,
      path: "cashout",
      element: <CashOut />,
    },
    {
      itemMenu: false,
      title: "Transferências",
      iconMenu: FaMoneyBillTransfer,
      path: "transferencias",
      children: [
        { path: "", element: <Navigate to="/" /> },
        {
          itemMenu: true,
          title: "Pix",
          iconMenu: SiPix,
          path: "pix",
          element: <Pix />,
        },
        {
          itemMenu: true,
          title: "Interna",
          iconMenu: HiOutlineSwitchHorizontal,
          path: "interna",
          element: <Interna />,
        },
      ],
    },
    {
      itemMenu: false,
      title: "Pagamentos",
      iconMenu: FaBarcode,
      path: "pagamentos",
      children: [
        { path: "", element: <Navigate to="/" /> },
        {
          itemMenu: true,
          title: "Com código de barras",
          iconMenu: BiBarcodeReader,
          path: "com-codigo-de-barras",
          element: <PagamentosBarras />,
        },
        {
          itemMenu: true,
          title: "Pagar via PIX",
          iconMenu: MdQrCodeScanner,
          path: "pix",
          element: <PagamentosPix />,
        },
      ],
    },
    {
      itemMenu: false,
      title: "Cobranças",
      iconMenu: FaFileInvoiceDollar,
      path: "cobrancas",
      children: [
        { path: "", element: <Navigate to="/" /> },
        {
          itemMenu: true,
          title: "Boleto com Pix",
          iconMenu: BiBarcodeReader,
          path: "pix",
          element: <Pix />,
        },
        {
          itemMenu: true,
          title: "Pix QrCode",
          iconMenu: MdQrCodeScanner,
          path: "interna",
          element: <Interna />,
        },
      ],
    },
    {
      itemMenu: false,
      title: "Marketplace",
      iconMenu: BsShop,
      path: "faturas",
      children: [
        { path: "", element: <Navigate to="/" /> },
        {
          itemMenu: true,
          title: "Produtos",
          iconMenu: MdOutlineChecklist,
          path: "products",
          element: <CreateInvoices />,
        },
        {
          itemMenu: true,
          title: "Criar",
          iconMenu: MdOutlineChecklist,
          path: "criar",
          element: <CreateInvoices />,
        },
        {
          itemMenu: true,
          title: "Listar",
          iconMenu: RiFileList3Line,
          path: "listar",
          element: <ListInvoices />,
        },
      ],
    },
    {
      itemMenu: true,
      title: "Configurações",
      iconMenu: MdSettings,
      path: "configuracoes",
      children: [
        { path: "", element: <Navigate to="/" /> },
        {
          itemMenu: false,
          title: "Api Anna Bank",
          iconMenu: MdBuild,
          path: "api",
          element: <ApiConf />,
        },
        {
          itemMenu: true,
          title: "Contas",
          iconMenu: FaUserCog,
          path: "contas",
          element: <UsersConf />,
        },
      ],
    },
    {
      itemMenu: true,
      title: "Suporte",
      iconMenu: MdOutlineSupportAgent,
      path: "suporte",
      children: [
        { path: "", element: <Navigate to="/" /> },
        {
          itemMenu: true,
          title: "Perguntas frequentes",
          iconMenu: FaQuestion,
          path: "perguntas-frequentes",
          element: <Faq />,
        },
        {
          itemMenu: true,
          title: "Meus tickets",
          iconMenu: FaListCheck,
          path: "meus-tickets",
          element: <Tickets />,
        },
      ],
    },
    {
      itemMenu: true,
      title: "Devlab < />",
      iconMenu: FaLaptopCode,
      path: "devlab",
      element: <Redirect />,
    }
  ],
};

export function Routes() {
  const elements = useRoutes(routesMain.concat([routesApp as RouteObject]));
  return elements;
}
