import { createGlobalStyle } from 'styled-components';

export const colors = {
    white: '#fff',
    black: '#000',
    textLight: '#666',
    textclean: '#dedede',
    textCleanLight: '#b5b3b3',
    primary: '#125da1',
    primaryLight: '#d9f4fc',
    turquoise: '#0e8ba4',
    turquoiseDark: '#0d7e8b',
    turquoiseLight: '#5fcdbe',
    colorShadow: '#888888',
    background: '#f7f8f9',/*#ededed*/
    success: '#05ad43',
    red: '#ff0a0a',
    warning: '#deb928'
}

export const GlobalStyle = createGlobalStyle`
:root {
        --background: ${colors.background};
        --white: ${colors.white};
        --black: ${colors.black};
        --text-light: ${colors.textLight};
        --text-clean: ${colors.textclean};
        --primary: ${colors.primary};
        --primary-light: ${colors.primaryLight};
        --turquoise-dark: ${colors.turquoiseDark};
        --turquoise: ${colors.turquoise};
        --turquoise-light: ${colors.turquoiseLight};
        --text-Clean-light: ${colors.textCleanLight};
        --shadow: ${colors.colorShadow};
        --success: ${colors.success};
        --red: ${colors.red};
        --background-color: #ffffff;
        --primary-color: #00579f;
        --secondary-color: #5dc4b8;
    }

    *{  
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
        &:focus {
            text-decoration: none;
        }
    }

    /* html {
        @media(max-width: 1080px){
            font-size: 93.75%;
        }

        @media(max-width: 720px){
            font-size: 87.4%;
        }
    } */

    body {
        -webkit-font-smothing: antialiased !important;
        background: var(--background) !important;
        font-size: 14px !important;
        min-height: 100vh !important;
        text-shadow: unset !important;
        line-height: normal !important;
    }

    * {
        font-family: Montserrat,-apple-system,Roboto,Arial,sans-serif,sans-serif !important;
        font-weight: 400 !important;
    }

    input, textarea {
        font-size: 16px !important;
    }

    /* h1, h2, h3, h4, h5, h6{
        font-weight: 500 !important;
    }    */

    strong {
        font-weight: 600 !important;
    }

    .display-flex {
        display: flex;
    }

    .flex-direction-column {
        flex-direction: column;
    }

    .justify-content-center {
        justify-content: center;
    }

    .justify-content-end {
        justify-content: end;
    }

    .justify-content-space-between {
        justify-content: space-between;
    }

    .rs-uploader-trigger-customize {
        display:flex;
        align-items: center; 
        justify-content: center;
    }

    .align-items-center {
        align-items: center;
    }

    .text-align-center {
        text-align: center;
    }

    .active-item-menu {
        background: var(--turquoise);
        color: var(--white);

        * {
            color: var(--white)
        }
    }

    .rs-picker-toggle-value {color:#575757 !important}
    .margin-top1 {
        margin-top: 10px;
    }

    .margin-top2 {
        margin-top: 20px;
    }

    .margin-top3 {
        margin-top: 30px;
    }
    .margin-top4 {
        margin-top: 40px;
    }
    .margin-top5 {
        margin-top: 50px;
    }

    .margin-right1 {
        margin-right: 10px;
    }

    .margin-right2 {
        margin-right: 20px;
    }

    .margin-right3 {
        margin-right: 30px;
    }
    .margin-right4 {
        margin-right: 40px;
    }
    .margin-right5 {
        margin-top: 50px;
    }

    .margin-left1 {
        margin-left: 10px;
    }

    .margin-left2 {
        margin-left: 20px;
    }

    .margin-left3 {
        margin-left: 30px;
    }
    .margin-left4 {
        margin-left: 40px;
    }
    .margin-left5 {
        margin-left: 50px;
    }

    .margin-bottom1{
        margin-bottom: 10px;
    }

    .margin-bottom2{
        margin-bottom: 20px;
    }

    .margin-bottom3{
        margin-bottom: 30px;
    }

    .margin-bottom4{
        margin-bottom: 40px;
    }

    .margin-bottom5{
        margin-bottom: 50px;
    }

    .width-100 {
        width: 100%;
    }

    .padding-right1{
        padding-right: 10px;
    }

    .padding-left1{
        padding-left: 10px;
    }

    .padding-right2{
        padding-right: 20px;
    }

    .padding-1{
        padding: 1rem;
    }

    .button-open-account a {
        border: 1px solid var(--turquoise) !important;
        background: #fff !important;
        color: var(--turquoise) !important;
    }

    .button-open-account-login a {
        border: 1px solid var(--turquoise) !important;
        background: #fff !important;
        color: var(--turquoise) !important;
        width:100%;
        text-align :center
    }

    .logo-top-annabank {
        height:21px !important
    }

    .logo-form-annabank {
        width:86px !important
    }
`