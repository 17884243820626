import { colors } from '../../../../styles/global';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    height: 100vh;
   `

export const Content = styled.div`
       height: calc(100vh - 66px);
       display: flex;
       flex-direction: column;
       flex-direction: column;
       justify-content: center;
       align-items: center;
   `

export const Card = styled.div`
        display: flex;
        flex-direction: column;
        /* border-radius: 15px; */
        box-shadow: 0px 0px 15px -2px rgba(0,0,0, 0.5);
        padding: 1.25rem;
        width: 463px;
        align-items: center;

        img {
            margin-top: 1.25rem;
            width: 30px;
            height: auto;
        }

        .form {
            margin-top: 1.5rem;
            margin-bottom: 3rem;
            display: flex;
            flex-direction: column;
            max-width: 328px;
            width: 100%;
            
            .button-next {
                padding: 5px;
                background-color: #fbfbfd;
                border-radius: 20px;
                color: ${colors.textLight};
                
                &:disabled {
                    border: 1px solid ${colors.textclean};
                    color: ${colors.textCleanLight};
                    cursor: text;
                }
            }
        }

        @media(max-width: 600px) {
            box-shadow: none;
            width: 100%;
        }

`

export const Footer = styled.div`
    /* position: absolute;
    bottom: 0; */
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #f5f5f7;
    padding: 1.5rem;

    a { 
        margin-top: 10px;
        color: ${colors.textLight};
        font-size: 12px;
        padding: 0 12px;
    }

    span {
        color: ${colors.textLight};
        font-size: 12px;
    }

    @media(max-width: 600px) {
        flex-direction:  column;
        align-items: center;
        justify-content: center;
    }
   `

export const HelperTextError = styled.span`
        color: red;
        font-size: 12px;
        margin: 5px;
`