// TicketListContainer.tsx
import React from "react";
import { List, ListItem, ListItemText, Collapse, Button, CircularProgress, Typography, Grid } from "@mui/material";
import { MdExpandLess, MdExpandMore, MdOutlineSupportAgent } from "react-icons/md";
import MessageBubble from "./MessageBubble";

interface TicketListContainerProps {
  loading: boolean;
  tickets: any[];
  openIndex: number | null;
  handleToggle: (index: number) => void;
  handleEncerrarTicket: (ticket: any) => void;
  setId: React.Dispatch<React.SetStateAction<string>>;
  setModalReplyOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TicketListContainer: React.FC<TicketListContainerProps> = ({ loading, tickets, openIndex, handleToggle, handleEncerrarTicket, setId, setModalReplyOpen }) => {
  return (
    <List>
      {loading ? (
        <CircularProgress sx={{ display: "block", margin: "auto" }} />
      ) : tickets.length > 0 ? (
        tickets.map((ticket, index) => (
          <React.Fragment key={index}>
            <ListItem onClick={() => handleToggle(index)}>
              <MdOutlineSupportAgent color={"#0e8ba4"} size={24} style={{ marginRight: "10px" }} />
              <ListItemText primary={ticket.titulo.length > 25 ? ticket.titulo.substring(0, 25) + "..." : ticket.titulo} />
              {openIndex === index ? <MdExpandLess size={24} color={"#0e8ba4"}/> : <MdExpandMore size={24} color={"#0e8ba4"}/>}
            </ListItem>
            <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
              <List>
                <MessageBubble position="left" anexos={ticket.anexos} author={ticket.person.name} text={ticket.descricao} date={new Date(ticket.createdAt)} />
                <List>
                  {ticket.respostas.map((resposta: any, index: number) => (
                    <MessageBubble key={index} position="right" anexos={resposta.anexos} author={resposta.person.name} text={resposta.texto} date={new Date(resposta.createdAt)} />
                  ))}
                </List>
              </List>
              {ticket.status !== "Fechado" && (
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setId(ticket.id);
                        setModalReplyOpen(true);
                      }}
                      sx={{
                        backgroundColor: "#0e8ba4",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#0d7e8b",
                        },
                      }}
                    >
                      Responder
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleEncerrarTicket(ticket);
                      }}
                      sx={{
                        backgroundColor: "#0e8ba4",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#0d7e8b",
                        },
                      }}
                    >
                      Encerrar Ticket
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Collapse>
          </React.Fragment>
        ))
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
          Não há tickets criados.
        </Typography>
      )}
    </List>
  );
};

export default TicketListContainer;
