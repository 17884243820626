import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumps } from "../../../components/Breadcrumbs";
import { AppContent } from "../../../components/AppContent";
import { FilterContent } from "../../../components/FilterContent";
import { Button, Box, Stack, Typography, CircularProgress, IconButton } from "@mui/material";
import { MdSearch } from "react-icons/md";
import { TbListSearch, TbReportMoney } from "react-icons/tb";
import { Autocomplete, Card, Grid, Pagination, TextField, Dialog } from "@mui/material";
import { useSnackbar } from "notistack";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import { LOCALE_DATEPICKER } from "../../../constants";
import * as apiInterpays from "../../../services/apiInterpays";
import * as handleRequestError from "../../../utils/handleRequestError";
import * as configSnackbar from "../../../utils/configDefaultSnackbar";
import { ItemExtract } from "../../../components/ItemExtract";
import { PacmanLoader } from "react-spinners";
import { colors } from "../../../styles/global";
import { Details } from "./Details";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

const optionsTypes = [
  { value: "Crédito", label: "Crédito" },
  { value: "Débito", label: "Débito" },
];

const optionsOrigin = [
  { value: "QrCode Pago", label: "QrCode Pago" },
  { value: "Pix Enviado", label: "Pix Enviado" },
  { value: "Pix Devolvido", label: "Pix Devolvido" },
];

type OptionType = {
  value: string;
  label: string;
};

interface Account {
  id: string;
  name: string;
  account: number;
}

interface AccountData {
  account: Account;
}

interface StatementParty {
  nome: string;
}

interface Statement {
  id: string;
  valor: number;
  origem: StatementParty[];
  destino: StatementParty[];
  tipo: string;
  data: string;
  endToEndId: string;
}

interface StatementsResponse {
  statements: Statement[];
  totalPage: number;
}

export function Extract() {
  const { enqueueSnackbar } = useSnackbar();

  const [showFilters, setShowFilters] = useState(false);
  const [searching, setSearching] = useState(false);
  const [countPagination, setCountPagination] = useState(1);
  const [currentPage, setCurrentPage] = useState(1); // Initialize to 1
  const [data, setData] = useState<Statement[]>([]);
  const [accountsOptions, setAccountsOptions] = useState<OptionType[]>([]);
  const [intervalFilter, setIntervalFilter] = useState<[Date, Date]>([new Date(), new Date()]);
  const [typeFilter, setTypeFilter] = useState<string | null>(null);
  const [accountFilter, setAccountFilter] = useState<string | null>(null);
  const [originFilter, setOriginFilter] = useState<string | null>(null);
  const [endToEndId, setEndToEndId] = useState<string>("");
  const [itemSelected, setItemSelected] = useState<Statement | null>(null);
  const [showModalDetails, setShowModalDetails] = useState(false);

  const handleToggleFilters = () => setShowFilters((prev) => !prev);

  const checkFilters = useCallback((): boolean => {
    if (!accountFilter) {
      enqueueSnackbar("Selecione uma conta", configSnackbar.info);
      return false;
    }
    if (!intervalFilter) {
      enqueueSnackbar("Selecione um período", configSnackbar.info);
      return false;
    }
    return true;
  }, [accountFilter, enqueueSnackbar, intervalFilter]);

  const handleSearchAccounts = useCallback(async () => {
    try {
      const response = await apiInterpays.API.get("/statements/accounts");
      const data: AccountData[] = response.data;

      const options = data.map((item) => ({
        value: item.account.id,
        label: `${item.account.name} - ${("000000" + item.account.account.toString()).slice(-7)}`,
      }));

      setAccountsOptions(options);
      if (data.length > 0) {
        setAccountFilter(data[0].account.id);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          handleRequestError.handleError(error.response, enqueueSnackbar);
        } else {
          enqueueSnackbar("Ocorreu um erro inesperado", configSnackbar.error);
        }
      } else {
        enqueueSnackbar("Ocorreu um erro inesperado", configSnackbar.error);
      }
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    handleSearchAccounts();
  }, [handleSearchAccounts]);

  const handleSearch = useCallback(
    async (resetPage = false, pageNumber = currentPage) => {
      if (!checkFilters()) return;

      setSearching(true);
      setData([]);

      const filters: string[] = [];

      filters.push(`startDate=${moment(intervalFilter[0]).format("YYYY-MM-DDT02:59:59.000")}Z`);
      filters.push(`endDate=${moment(intervalFilter[1]).add(1, "day").format("YYYY-MM-DDT03:00:00.000")}Z`);
      if (accountFilter) filters.push(`idAccount=${accountFilter}`);

      let page = pageNumber;

      if (resetPage) {
        page = 1;
        setCurrentPage(1);
      }

      filters.push(`currentPage=${page}`);

      if (typeFilter) filters.push(`type=${typeFilter}`);
      if (originFilter) filters.push(`origin=${originFilter}`);
      if (endToEndId.trim()) filters.push(`endToEndId=${endToEndId.trim()}`);

      try {
        const response = await apiInterpays.API.get(`/statements?${filters.join("&")}`);
        const data: StatementsResponse = response.data;

        setData(data.statements);
        setCountPagination(data.totalPage);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            handleRequestError.handleError(error.response, enqueueSnackbar);
          } else {
            enqueueSnackbar("Ocorreu um erro inesperado", configSnackbar.error);
          }
        } else {
          enqueueSnackbar("Ocorreu um erro inesperado", configSnackbar.error);
        }
      } finally {
        setSearching(false);
      }
    },
    [accountFilter, checkFilters, currentPage, endToEndId, enqueueSnackbar, intervalFilter, originFilter, typeFilter]
  );

  const handleShowDetails = (item: Statement) => {
    setItemSelected(item);
    setShowModalDetails(true);
  };

  const handleCloseModalDetails = () => {
    setShowModalDetails(false);
    setItemSelected(null);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    setCurrentPage(pageNumber);
    handleSearch(false, pageNumber);
  };

  const handleExport = useCallback(async () => {
    if (!checkFilters()) return;
    if (data.length === 0) {
      enqueueSnackbar("Nenhuma transação encontrada.", configSnackbar.warning);
      return;
    }
    const filters: string[] = [];
    filters.push(`startDate=${moment(intervalFilter[0]).format("YYYY-MM-DDT00:00:00.000")}Z`);
    filters.push(`endDate=${moment(intervalFilter[1]).format("YYYY-MM-DDT23:59:59.999")}Z`);
    if (accountFilter) filters.push(`idAccount=${accountFilter}`);
    if (typeFilter) filters.push(`type=${typeFilter}`);
    if (originFilter) filters.push(`origin=${originFilter}`);
    if (endToEndId.trim()) filters.push(`endToEndId=${endToEndId.trim()}`);

    try {
      const response = await apiInterpays.API.get(`/exportExtract?${filters.join("&")}`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "extract_exported_data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          handleRequestError.handleError(error.response, enqueueSnackbar);
        } else {
          enqueueSnackbar("Ocorreu um erro inesperado", configSnackbar.error);
        }
      } else {
        enqueueSnackbar("Ocorreu um erro inesperado", configSnackbar.error);
      }
    }
  }, [checkFilters, data, intervalFilter, accountFilter, typeFilter, originFilter, endToEndId, enqueueSnackbar]);


  return (
    <AppContent>
      <Breadcrumps
        Icon={TbReportMoney}
        title="Extrato"
        links={[{ name: "Extrato", href: "/app/extrato" }]}
        onClickButtonFilter={handleToggleFilters}
        onClickButtonExport={handleExport}
      />

      <FilterContent open={showFilters} onClose={handleToggleFilters}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 1 }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={handleToggleFilters} size="large">
            <CloseIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Box>
        <DateRangePicker
          defaultValue={intervalFilter}
          showOneCalendar
          size="lg"
          style={{ width: "100%" }}
          menuStyle={{ zIndex: 200 }}
          placement="bottomEnd"
          className="mt-4"
          locale={LOCALE_DATEPICKER}
          onChange={(value) => setIntervalFilter(value as [Date, Date])}
          placeholder="Período"
        />

        {/* Account Autocomplete with styles */}
        <Autocomplete
          disablePortal
          fullWidth
          options={accountsOptions}
          size="medium"
          sx={{ marginTop: 2 }}
          value={accountsOptions.find((option) => option.value === accountFilter) ?? null}
          onChange={(event, value) => setAccountFilter(value?.value ?? null)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Conta"
              InputLabelProps={{ style: { color: "#0e8ba4" } }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#0e8ba4",
                  },
                  "&:hover fieldset": {
                    borderColor: "#0e8ba4",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0e8ba4",
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#0e8ba4",
                },
              }}
            />
          )}
        />

        {/* Type Autocomplete with styles */}
        <Autocomplete
          disablePortal
          fullWidth
          options={optionsTypes}
          size="medium"
          sx={{ marginTop: 2 }}
          value={optionsTypes.find((option) => option.value === typeFilter) ?? null}
          onChange={(event, value) => setTypeFilter(value?.value ?? null)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Natureza"
              InputLabelProps={{ style: { color: "#0e8ba4" } }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#0e8ba4",
                  },
                  "&:hover fieldset": {
                    borderColor: "#0e8ba4",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0e8ba4",
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#0e8ba4",
                },
              }}
            />
          )}
        />

        {/* Origin Autocomplete with styles */}
        <Autocomplete
          disablePortal
          fullWidth
          options={optionsOrigin}
          size="medium"
          sx={{ marginTop: 2 }}
          value={optionsOrigin.find((option) => option.value === originFilter) ?? null}
          onChange={(event, value) => setOriginFilter(value?.value ?? null)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tipo"
              InputLabelProps={{ style: { color: "#0e8ba4" } }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#0e8ba4",
                  },
                  "&:hover fieldset": {
                    borderColor: "#0e8ba4",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0e8ba4",
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#0e8ba4",
                },
              }}
            />
          )}
        />

        {/* EndToEndId TextField with styles */}
        <TextField
          fullWidth
          label="endToEndId"
          value={endToEndId}
          onChange={(e) => setEndToEndId(e.target.value)}
          onBlur={(e) => setEndToEndId(e.target.value)}
          InputLabelProps={{ style: { color: "#0e8ba4" } }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#0e8ba4",
              },
              "&:hover fieldset": {
                borderColor: "#0e8ba4",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#0e8ba4",
              },
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#0e8ba4",
            },
            marginTop: 2,
          }}
        />

        {/* Styled Button */}
        <Box mt={1} width="100%">
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSearch(true)}
            fullWidth
            disabled={searching}
            startIcon={searching ? <CircularProgress size={20} color="inherit" /> : <MdSearch size={20} />}
            sx={{
              backgroundColor: "#0e8ba4",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#0d7e8b",
              },
              marginTop: 2,
            }}
          >
            Filtrar
          </Button>
        </Box>
      </FilterContent>

      <Grid container justifyContent="center" marginTop={-4} padding={1}>
        <Grid xs={12} sm={12} lg={12} item>
          <Card sx={{ p: 2, mt: 3, width: "100%" }}>
            <Stack>
              {searching && (
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{ margin: "25px 0" }}>
                  <Typography>Buscando informações</Typography>
                  <PacmanLoader size={9} speedMultiplier={1.2} color={colors.turquoise} />
                </Stack>
              )}

              {!searching && data.length === 0 ? (
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{ marginTop: 3, marginBottom: 3 }}>
                  <TbListSearch size={30} color={colors.colorShadow} />
                  <Typography>Nenhuma transação encontrada</Typography>
                </Stack>
              ) : (
                data.map((item) => (
                  <ItemExtract
                    key={item.id}
                    name={item.valor > 0 ? item.origem[0]?.nome : item.destino[0]?.nome}
                    type={item.tipo}
                    data={new Date(item.data)}
                    value={item.valor}
                    onClick={() => handleShowDetails(item)}
                  />
                ))
              )}
            </Stack>
            <Stack alignItems="flex-end" marginTop={2}>
              <Pagination
                count={countPagination}
                onChange={handlePageChange}
                page={currentPage}
                variant="outlined"
                color="primary"
              />
            </Stack>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={showModalDetails} maxWidth="xs" fullWidth>
        {itemSelected && <Details item={itemSelected} onClose={handleCloseModalDetails} />}
      </Dialog>
    </AppContent>
  );
}
