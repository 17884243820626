import styled from 'styled-components';

export const Container = styled.div`
@-webkit-keyframes swal2-animate-success-line-tip{
    0%{
        top:0em;
        left:.0625em;
        width:0
    }
    64%{
        top:.58em;
        left:.125em;
        width: .05em
    }
    70%{
        top:1em;
        left:.175em;
        width:.748em
    }
     84%{
        top:2em;
        left:.9em;
        width: 1em
    }
     /* 100%{
        top:1.9876em;
        left:.875em;
        width:1em
    }   */
}
@keyframes swal2-animate-success-line-tip{
    0%{
        top:0em;
        left:.0625em;
        width:0
    }
    64%{
        top:.58em;
        left:.125em;
        width: .05em
    }
    70%{
        top:1em;
        left:.175em;
        width:.748em
    }
     84%{
        top:2em;
        left:.9em;
        width: 1em
    }
     /* 100%{
        top:1.9876em;
        left:.875em;
        width:1em
    }   */
}
@-webkit-keyframes swal2-animate-success-line-long{
    0%{
        top:2.175em;
        right:1.9em;
        width:0
    }
    65%{
        top:2.185em;
        right:1.9em;
        width:0
    }
    75%{
        top:2.185em;
        right:1.9em;
        width:0
    }
    84%{
        top:1.65em;
        right: 0.46em;
        width:1.7em
    }
    /* 100%{
        top: 1.75em;
        right:.6em;
        width:1.425em
    } */
}
@keyframes swal2-animate-success-line-long{
    0%{
        top:2.175em;
        right:1.9em;
        width:0
    }
    65%{
        top:2.185em;
        right:1.9em;
        width:0
    }
    75%{
        top:2.185em;
        right:1.9em;
        width:0
    }
    84%{
        top:1.65em;
        right: 0.46em;
        width:1.7em
    }
    /* 100%{
        top: 1.75em;
        right:.6em;
        width:1.425em
    } */
}
@-webkit-keyframes swal2-rotate-success-circular-line{
    0%{
        -webkit-transform:rotate(-45deg);
        transform:rotate(-45deg)
    }
    5%{
        -webkit-transform:rotate(-45deg);
        transform:rotate(-45deg)
    }
    12%{
        -webkit-transform:rotate(-405deg);
        transform:rotate(-405deg)
    }
    100%{
        -webkit-transform:rotate(-405deg);
        transform:rotate(-405deg)
    }
}
@keyframes swal2-rotate-success-circular-line{
    0%{
        -webkit-transform:rotate(-45deg);
        transform:rotate(-45deg)
    }
    5%{
        -webkit-transform:rotate(-45deg);
        transform:rotate(-45deg)
    }
    12%{
        -webkit-transform:rotate(-405deg);
        transform:rotate(-405deg)
    }
    100%{
        -webkit-transform:rotate(-405deg);
        transform:rotate(-405deg)
    }
}
.swal2-icon{
    position:relative;
    justify-content:center;
    width:3.5em;
    height:3.5em;
    margin:1.25em auto 1.875em;
    border:.25em solid transparent;
    border-radius:50%;
    line-height:5em;
    cursor:default;
    box-sizing:content-box;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    zoom:normal
}

.swal2-icon.swal2-success{
    border-color: var(--success);
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line]{
    position:absolute;
    width:3.75em;
    height:7.5em;
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg);
    border-radius:50%
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left]{
    top:-.4375em;
    left:-2.0635em;
    -webkit-transform:rotate(-45deg);
    transform:rotate(-45deg);
    -webkit-transform-origin:3.75em 3.75em;
    transform-origin:3.75em 3.75em;
    border-radius:7.5em 0 0 7.5em
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right]{
    top:-.3875em;
    left:.875em;
    -webkit-transform:rotate(-45deg);
    transform:rotate(-45deg);
    -webkit-transform-origin:0 3.75em;
    transform-origin:0 3.75em;
    border-radius:0 7.5em 7.5em 0
}
.swal2-icon.swal2-success .swal2-success-ring{
    position:absolute;
    top:-.25em;
    left:-.25em;
    width:100%;
    height:100%;
    border:.25em solid rgba(165,220,134,.3);
    border-radius:50%;
    z-index:2;
    box-sizing:content-box
}
.swal2-icon.swal2-success .swal2-success-fix{
    position:absolute;
    top:.5em;
    left:1.625em;
    width:.4375em;
    height:5.625em;
    -webkit-transform:rotate(-45deg);
    transform:rotate(-45deg);
    z-index:1
}
.swal2-icon.swal2-success [class^=swal2-success-line]{
    display:block;
    position:absolute;
    height:.3125em;
    border-radius:.125em;
    background-color: var(--success);
    z-index:2
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip]{
    top:1.875em;
    left:.705em;
    width:1.2em;
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg)
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=long]{
    top:1.575em;
    right:.24em;
    width:1.9375em;
    -webkit-transform:rotate(-45deg);
    transform:rotate(-45deg)
}
.swal2-animate-success-icon .swal2-success-line-tip{
    -webkit-animation:swal2-animate-success-line-tip .75s;
    animation:swal2-animate-success-line-tip .75s
}
.swal2-animate-success-icon .swal2-success-line-long{
    -webkit-animation:swal2-animate-success-line-long .75s;
    animation:swal2-animate-success-line-long .75s
}
.swal2-animate-success-icon .swal2-success-circular-line-right{
    -webkit-animation:swal2-rotate-success-circular-line 3.75s ease-in;
    animation:swal2-rotate-success-circular-line 3.75s ease-in
}
`