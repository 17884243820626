import { useEffect, useState } from "react";
import { Breadcrumps } from "../../../../components/Breadcrumbs";
import { AppContent } from "../../../../components/AppContent";
import { Button } from "../../../../components/Button";
import { MdOutlineChecklist, MdOutlinePlaylistAdd } from "react-icons/md";
import { Autocomplete, Card, Grid, Stack, TextField, Dialog, InputAdornment, FormHelperText } from "@mui/material";
import { useSnackbar } from "notistack";
import { SyncLoader } from "react-spinners";
import { colors } from "../../../../styles/global";
import { RegisterItem } from "./RegisterItem";
import * as apiInterpays from '../../../../services/apiInterpays';
import * as handleRequestError from '../../../../utils/handleRequestError';
import { BsTrash } from "react-icons/bs";
import { IoCopyOutline } from 'react-icons/io5';
import { DatePicker } from 'rsuite';
import * as utils from '../../../../utils/utils';
import { LOCALE_DATEPICKER } from "../../../../constants";
import * as Yup from 'yup';
import { FormikProvider, Form, useFormik } from 'formik';
import * as configSnackbar from '../../../../utils/configDefaultSnackbar';
import Mask from 'vanilla-masker';
import axios from 'axios';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { CheckBoxThema } from "../../../../components/CheckboxThema";
import { PixIcon } from "../../../../components/Icon";
import validDoc from '../../../../utils/valida_cpf_cnpj';

const schemaForm = Yup.object().shape({
    name: Yup.string().required("informe uma descrição"),
    doc: Yup.string().required("informe um documento"),
    email: Yup.string().email().optional(),
    cep: Yup.string().optional(),
    state: Yup.string().optional(),
    city: Yup.string().optional(),
    address: Yup.string().optional(),
    district: Yup.string().optional(),
    phone: Yup.string().optional(),
    complement: Yup.string().optional(),
    number: Yup.string().optional(),
    dueDate: Yup.date().required('informe uma data de vencimento'),
    value: Yup.string().required("informe um valor"),
    idAccount: Yup.string().required("selecione uma conta recebedora")
});

export function CreateInvoices() {
    const { enqueueSnackbar } = useSnackbar();
    const [accounts, setAccounts] = useState<any[]>([]);
    const [searchingAccounts, setSearchingAccounts] = useState(false);
    const [showRegisterItem, setShowRegisterItem] = useState(false);
    const [searchingItems, setSearchingItems] = useState(false);
    const [items, setItems] = useState<{ value: string, label: string, amount: number }[]>([]);
    const [itemsSelecteds, setItemsSelecteds] = useState<{ value: string, label: string, amount: number }[]>([]);
    const [concluded, setConcluded] = useState(false);
    const [paymentOptions, setPaymentOptions] = useState<string[]>([]);
    const [linkInvoice, setLinkInvoice] = useState<string>("");
    const [errorDoc, setErrorDoc] = useState<string | null>(null);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            doc: '',
            email: '',
            cep: '',
            state: '',
            city: '',
            address: '',
            district: '',
            dueDate: '',
            value: '0,00',
            idAccount: '',
            phone: '',
            complement: '',
            number: '',
        },
        validationSchema: schemaForm,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                if (paymentOptions.length === 0) return;

                if (!validDoc.valida_cpf(values.doc)) {
                    setErrorDoc('Documento inválido');
                    return;
                }
                setErrorDoc(null);
                // eslint-disable-next-line
                const result = await handleSave(JSON.parse(JSON.stringify(values)));
                setConcluded(true);
                setLinkInvoice(`${window.location.origin}/payment/${result.id}`);

                resetForm();
                setPaymentOptions([]);
                enqueueSnackbar('Tranferência finalizada com sucesso!', configSnackbar.success);
            } catch (error: any) {
                const message = error.response?.data.message ?? 'Ocorreu um erro ao fazer requisição';
                enqueueSnackbar(message, configSnackbar.warning);
            }
        }
    });

    const searchItems = async () => {
        setSearchingItems(true);

        await apiInterpays.API.get('/invoices/item')
            .then(({ data }) => {
                const options: any[] = [];

                data.forEach((item: any) => {
                    options.push({
                        value: item.id,
                        label: item.description,
                        amount: item.value
                    });
                });

                setItems(options);
            }).catch(({ response }) => handleRequestError.handleError(response, enqueueSnackbar));
        setSearchingItems(false);
    }

    const searchAccounts = async () => {
        setSearchingAccounts(true);
        await apiInterpays.API.get('/statements/accounts')
            .then(async ({ data }) => {
                const options: any[] = [];
                await data.forEach((item: any) => {
                    options.push({
                        value: item.account.id,
                        label: `${item.account.name} - ${("000000" + item.account.account.toString()).slice(-7)}`
                    })
                });
                setAccounts(options);
            }).catch(({ response }) => handleRequestError.handleError(response, enqueueSnackbar));
        setSearchingAccounts(false);
    }

    const searchZip = async (zip: string) => {
        await axios.get(`https://viacep.com.br/ws/${zip.replace('-', '')}/json/`)
            .then(({ data }) => {
                if (data.erro) {
                    enqueueSnackbar('Erro ao buscar seu CEP', configSnackbar.warning)
                } else {
                    setFieldValue('state', data.uf);
                    setFieldValue('city', data.localidade);
                    if (data.logradouro.length > 0) setFieldValue('address', data.logradouro);
                    if (data.bairro.length > 0) setFieldValue('district', data.bairro);
                }
            }).catch(() => enqueueSnackbar('Ocorreu um erro ao buscar cep', configSnackbar.warning))
    }

    const handleAddItem = async (newItem: any) => {
        setItemsSelecteds([...itemsSelecteds, newItem]);
        setItems(await items.filter(item => item.value !== newItem.value));
        let newValue = itemsSelecteds.length > 0 ? parseFloat(values.value.replace(/\./g, '').replace(',', '.')) : 0;

        newValue += parseFloat(newItem.amount);
        setFieldValue('value', Mask.toMoney(newValue.toFixed(2)));
    }

    const handleRemoveItem = async (itemRemove: any) => {
        setItems([...items, itemRemove]);
        setItemsSelecteds(await itemsSelecteds.filter(item => item.value !== itemRemove.value));
        let newValue = itemsSelecteds.length > 0 ? parseFloat(values.value.replace(/\./g, '').replace(',', '.')) : 0;

        newValue -= parseFloat(itemRemove.amount);
        setFieldValue('value', Mask.toMoney(newValue.toFixed(2)));
    }

    const handleSave = async (values: any) => {
        values.items = [];

        await itemsSelecteds.filter(item => values.items.push({ itemInvoiceId: item.value }));

        values.items = JSON.stringify(values.items);
        values.value = parseFloat(values.value.replace(/\./g, '').replace(',', '.'));
        values.paymentOptions = JSON.stringify(paymentOptions);
        values.phone = values.phone.replace(/\D/g, '');
        values.doc = values.doc.replace(/\D/g, '');

        const options = {
            url: '/invoices',
            method: 'POST',
            data: values
        }

        const response = await apiInterpays.API.request(options);

        return response.data;
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(linkInvoice);
    }

    useEffect(() => {
        searchItems();
        searchAccounts();
        //eslint-disable-next-line
    }, []);

    const { errors, touched, getFieldProps, isSubmitting, setFieldValue, values } = formik;

    return (
        <AppContent>
            <Breadcrumps
                Icon={MdOutlineChecklist}
                title="Criar"
                links={[
                    { name: 'Criar', href: '/app/faturas/criar' }
                ]}
            />

            <Grid container justifyContent="center" marginTop={-2} padding={1}>
                <Grid xs={12} sm={8} lg={6} item>
                    <Card sx={{ p: 2, mt: 3, width: '100%' }}>
                        {
                            concluded ? (
                                <div className="display-flex flex-direction-column">
                                    <div className="display-flex justify-content-center align-items-center margin-bottom2 margin-top2"
                                        style={{ color: colors.success, fontSize: 17 }}>
                                        <AiOutlineCheckCircle size={20} /><span className="margin-left1">Cobrança criada com sucesso!</span>
                                    </div>
                                    <Stack direction={{ xs: 'column' }} alignItems="center" spacing={2}>
                                        <a href={linkInvoice} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }} >Acessar fatura</a>
                                        {/* <Button type="link" to={linkInvoice} target="_blank" color="transparent">Acessar fatura</Button> */}
                                        <Button type="button" onClick={() => handleCopyLink()} color="turquoise"><IoCopyOutline size={15} /><span className="margin-left1">Copiar link da fatura</span></Button>
                                        <Button type="button" onClick={() => setConcluded(false)} color="turquoise">Nova fatura</Button>
                                    </Stack>
                                </div>
                            ) : (
                                <FormikProvider value={formik}>
                                    <Form noValidate autoComplete="off">
                                        <Stack padding={2} spacing={2}>
                                            <h4>Criar uma fatura</h4>

                                            <Stack direction={{ xs: 'row' }} alignItems="center">
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="Nome"
                                                    {...getFieldProps("name")}
                                                    error={Boolean(errors.name && touched.name)}
                                                    helperText={touched.name && errors.name}
                                                />
                                            </Stack>

                                            <Stack direction={{ xs: 'column' }}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="CPF"
                                                    {...getFieldProps("doc")}
                                                    onChange={event => {
                                                        event.target.value = Mask.toPattern(event.target.value, '999.999.999-99')
                                                        setFieldValue('doc', event.target.value)
                                                    }}
                                                    error={Boolean(errors.doc && touched.doc)}
                                                    helperText={touched.doc && errors.doc}
                                                />
                                                {
                                                    errorDoc && <FormHelperText error>{errorDoc}</FormHelperText>
                                                }
                                            </Stack>

                                            <Stack direction={{ xs: 'row' }} alignItems="center">
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="E-mail"
                                                    {...getFieldProps("email")}
                                                    error={Boolean(errors.email && touched.email)}
                                                    helperText={touched.email && errors.email}
                                                />
                                            </Stack>

                                            <Stack direction={{ xs: 'row' }} alignItems="center">
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="CEP"
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={event => {
                                                        setFieldValue('cep', event.target.value);
                                                        if (event.target.value.length >= 8) searchZip(event.target.value);
                                                    }}
                                                    // {...getFieldProps("cep")}
                                                    error={Boolean(errors.cep && touched.cep)}
                                                    helperText={touched.cep && errors.cep}
                                                />
                                            </Stack>

                                            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="Estado"
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    {...getFieldProps("state")}
                                                    error={Boolean(errors.state && touched.state)}
                                                    helperText={touched.state && errors.state}
                                                />
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="Cidade"
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    {...getFieldProps("city")}
                                                    error={Boolean(errors.city && touched.city)}
                                                    helperText={touched.city && errors.city}
                                                />
                                            </Stack>

                                            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="Rua"
                                                    InputLabelProps={{ shrink: true }}
                                                    {...getFieldProps("address")}
                                                    error={Boolean(errors.address && touched.address)}
                                                    helperText={touched.address && errors.address}
                                                />
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="Bairro"
                                                    InputLabelProps={{ shrink: true }}
                                                    {...getFieldProps("district")}
                                                    error={Boolean(errors.district && touched.district)}
                                                    helperText={touched.district && errors.district}
                                                />
                                            </Stack>

                                            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="Complemento"
                                                    InputLabelProps={{ shrink: true }}
                                                    {...getFieldProps("complement")}
                                                    error={Boolean(errors.complement && touched.complement)}
                                                    helperText={touched.complement && errors.complement}
                                                />
                                            </Stack>

                                            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="Numero"
                                                    InputLabelProps={{ shrink: true }}
                                                    {...getFieldProps("number")}
                                                    error={Boolean(errors.number && touched.number)}
                                                    helperText={touched.number && errors.number}
                                                />
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="Telefone"
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={event => {
                                                        event.target.value = Mask.toPattern(event.target.value, '(99) 9 9999-9999');
                                                        setFieldValue('phone', event.target.value);
                                                    }}
                                                    // {...getFieldProps("phone")}
                                                    error={Boolean(errors.phone && touched.phone)}
                                                    helperText={touched.phone && errors.phone}
                                                />
                                            </Stack>

                                            <Stack direction={{ xs: 'column' }}>
                                                <div className="display-flex width-100">
                                                    <Autocomplete
                                                        disablePortal
                                                        fullWidth
                                                        options={accounts}
                                                        size="small"
                                                        disabled={searchingAccounts}
                                                        onChange={(event, value) => setFieldValue('idAccount', value.value)}
                                                        renderInput={(params) => <TextField
                                                            {...params}
                                                            label="Conta recebedora"
                                                        />}
                                                    />
                                                    {searchingAccounts && <SyncLoader size={6} speedMultiplier={0.7} color={colors.turquoise} />}
                                                </div>
                                                {
                                                    errors.idAccount && touched.idAccount && (<FormHelperText error>{errors.idAccount}</FormHelperText>)
                                                }
                                            </Stack>

                                            <Stack direction={{ xs: 'row', sm: 'row' }} alignItems="center">
                                                <Autocomplete
                                                    disablePortal
                                                    fullWidth
                                                    options={items}
                                                    size="small"
                                                    disabled={searchingAccounts}
                                                    onChange={(event, value) => {
                                                        if (value) handleAddItem(value);
                                                    }}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Selecionar item"
                                                    />}
                                                />
                                                {
                                                    searchingItems ?
                                                        <SyncLoader size={6} speedMultiplier={0.7} color={colors.turquoise} /> :
                                                        <Button onClick={() => setShowRegisterItem(true)}><MdOutlinePlaylistAdd size={20} /></Button>
                                                }
                                            </Stack>

                                            <Stack direction={{ xs: 'column', sm: 'column' }} alignItems="center" spacing={2}>
                                                <h5>Itens selecionados</h5>
                                                <Stack direction={{ xs: 'column' }} width="100%" alignItems="center" spacing={2}>
                                                    {
                                                        itemsSelecteds.map(item => (
                                                            <div key={utils.randomString()} className="display-flex justify-content-space-between width-100">
                                                                <div className="display-flex flex-direction-column">
                                                                    <strong>{item.label}</strong>
                                                                    <span>{utils.toMoney(item.amount)}</span>
                                                                </div>

                                                                <Button onClick={() => handleRemoveItem(item)}><BsTrash size={15} /></Button>
                                                            </div>
                                                        ))
                                                    }
                                                    {
                                                        itemsSelecteds.length === 0 && (
                                                            <span>Nenhum item selecionado</span>
                                                        )
                                                    }
                                                </Stack>
                                            </Stack>

                                            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" paddingTop={2} spacing={2}>
                                                <TextField
                                                    size="small"
                                                    label="Valor da cobrança"
                                                    fullWidth
                                                    value={values.value}
                                                    onChange={event => {
                                                        event.target.value = Mask.toMoney(event.target.value);
                                                        setFieldValue('value', event.target.value);
                                                    }}
                                                    error={Boolean(errors.value && touched.value)}
                                                    helperText={touched.value && errors.value}
                                                    InputProps={{
                                                        readOnly: itemsSelecteds.length > 0,
                                                        startAdornment: <InputAdornment position="start">R$</InputAdornment>
                                                    }}
                                                />
                                            </Stack>

                                            <Stack direction={{ xs: 'column', sm: 'column' }} alignItems="center">
                                                <div className="display-flex flex-direction-column width-100">
                                                    <span>Meios de pagamento</span>
                                                    <div className="display-flex width-100 margin-top2">
                                                        <CheckBoxThema
                                                            onChange={async (checked) => {
                                                                if (checked) setPaymentOptions([...paymentOptions, 'Pix'])
                                                                else setPaymentOptions(await paymentOptions.filter(item => item !== 'Pix'));
                                                            }}
                                                            label="Pix"
                                                            checked={paymentOptions.includes('Pix')}
                                                            icon={PixIcon as any}
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    paymentOptions.length === 0 && (
                                                        <FormHelperText error>Selecione os meios de pagamentos aceitos</FormHelperText>
                                                    )
                                                }
                                            </Stack>

                                            <Stack direction={{ xs: 'column', sm: 'column' }}>
                                                <DatePicker
                                                    placeholder="Vencimento"
                                                    format="dd/MM/yyyy HH:mm:ss"
                                                    locale={LOCALE_DATEPICKER}
                                                    calendarDefaultDate={new Date()}
                                                    style={{ width: '100%' }}
                                                    onChange={value => setFieldValue('dueDate', value)}
                                                    placement="top"
                                                    size="lg"
                                                />
                                                {
                                                    errors.dueDate && touched.dueDate && (<FormHelperText error>{errors.dueDate}</FormHelperText>)
                                                }
                                            </Stack>

                                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end" alignItems="center" spacing={2}>
                                                <Button type="submit" typeLoad="pacman" load={isSubmitting} color="turquoise">Salvar</Button>
                                            </Stack>
                                        </Stack>
                                    </Form>
                                </FormikProvider>
                            )
                        }
                    </Card>
                </Grid>
            </Grid>

            <Dialog open={showRegisterItem} maxWidth="xs" fullWidth>
                <RegisterItem
                    onClose={() => setShowRegisterItem(false)}
                    updateList={() => searchItems()}
                />
            </Dialog>
        </AppContent >
    )
}