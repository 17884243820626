import { useContext, useEffect, useState } from "react";
import { ContextMenu } from "../../../contexts/Contexts";
import { MenuItem } from "../MenuItem";
import { MenuItemCollapse } from "../MenuItemCollapse";
import { Container } from "./styles";
import { routesApp } from "../../../routes";
import { IconType } from "react-icons";

interface IProps {
    idUser: string | null;
}

export function MenuListItems(props: IProps) {
    const [menuCollapseSelected, setMenuCollapseSelected] = useState('');
    const menuContext = useContext(ContextMenu);

    useEffect(() => {
        if (menuContext.menuIsOpen)
            setMenuCollapseSelected('');
    }, [menuContext.menuIsOpen]);

    const handleChangeMenuCollapse = (nameMenu: string) => {
        setMenuCollapseSelected(nameMenu);
    }

    return (
        <Container>
            {
                routesApp.children?.filter(item => item.itemMenu).map(item => {

                    return item.children ?
                        (
                            <MenuItemCollapse
                                key={`${item}-${Math.random().toString(36).slice(2)}`}
                                title={item.title}
                                Icon={item.iconMenu as IconType}
                                menuSelected={menuCollapseSelected}
                                name={item.title as string}
                                path={item.path as string}
                                onChange={handleChangeMenuCollapse}
                                children={item.children.filter(item => item.itemMenu)}
                            />
                        )
                        :
                        (
                            <MenuItem
                                key={`${item}-${Math.random().toString(36).slice(2)}`}
                                title={item.title}
                                Icon={item.iconMenu}
                                path={item.path as string}
                            />
                        )
                })
            }

        </Container>
    )
}