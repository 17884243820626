import styled, { css } from 'styled-components';

const cssDefault = () => {
    return css`
        background: var(--dark-light);
        color: var(--text-light);
        transition: filter 0.2s;
        border: 1px solid var(--text-light);

        &:hover{
            filter: brightness(0.5);
        }
    `
}

const cssTurquoise = () => {
    return css`
        background-color: var( --turquoise);
        color: var(--white) ;
        transition: filter 0.2s;
        border: 0;
        &:hover{
            filter: brightness(0.8);
        }
    `
}

const cssTurquoiseLight = () => {
    return css`
        background-color: var( --turquoise-light);
        color: var(--white);
        transition: filter 0.2s;
        border: 0;

        &:hover{
            filter: brightness(0.8);
        }
    `
}

const cssPrimaryLight = () => {
    return css`
        background-color: var( --primary-light);
        color: var(--primary);
        transition: filter 0.2s;
        border: 0;

        &:hover{
            filter: brightness(0.8);
        }
    `
}

const cssBorderless = () => {
    return css`
        background: transparent;
        transition: filter 0.2s;
        border: 0;

        &:hover{
            filter: brightness(1.8);
        }
    `
}

const getCssTypeButton = (color?: string) => {
    switch (color) {
        case 'turquoise':
            return cssTurquoise();
        case 'default':
            return cssDefault();
        case 'turquoise-light':
            return cssTurquoiseLight();
        case 'primary-light':
            return cssPrimaryLight();
        case 'borderless':
            return cssBorderless();
        case 'transparent':
            return cssBorderless();
        default:
            return cssDefault()
    }
}

interface IProps {
    borderRounded?: boolean;
}

export const ContainerButton = styled.button.attrs((props: IProps) => { })`
    display: flex;
    justify-content: center;
    padding: ${props => props.borderRounded ? '12px 12px' : '8px 18px'}  ;
    font-weight: 500;
    cursor: pointer;
    border-radius: ${props => props.borderRounded ? '40px' : '6px'};


    ${props => getCssTypeButton(props.color)}
    
    &:disabled {
        filter: brightness(0.8);
    }
`

export const ContainerA = styled.div.attrs((props: IProps) => { })`
    display: flex;
    a{
        padding: ${props => props.borderRounded ? '12px 12px' : '8px 18px'};
        /* padding-left: 20px;
        padding-right: 20px;     */
        font-weight: 500;
        /* cursor: pointer; */
        border-radius: 6px;
        text-decoration: none;
    
        ${props => getCssTypeButton(props.color)}
    }
`