import React from 'react';
import { HelperTextError } from '../style';
import { Input, InputGroup, Checkbox, DatePicker, RadioGroup, Radio } from 'rsuite';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { useSnackbar } from 'notistack';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormikProvider, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import Mask from 'vanilla-masker';
import * as snackConfig from '../../../utils/configDefaultSnackbar';
import { Typography } from '@mui/material';
import utilDoc from '../../../utils/valida_cpf_cnpj';
import { LOCALE_DATEPICKER } from '../../../constants';
import * as api from '../../../services/apiInterpays';

const schemaForm = Yup.object().shape({
    doc: Yup.string().required('Informe seu CPF/CNPJ').test('validDoc', 'Informe um número de documento válido', value => Boolean(value && utilDoc.valida_cpf_cnpj(value?.replace(/\D/g, '')))),
    name: Yup.string().required('Informe seu nome'),
    birthdate: Yup.date().when('type', {
        is: 'PF',
        then: Yup.date().required('Informe uma data').nullable(),
        otherwise: Yup.date().nullable()
    }),
    phone: Yup.string().required('Informe seu telefone'),
    term: Yup.boolean().oneOf([true], 'Para continuar, você precisa aceitar os termos.'),
    email: Yup.string().email('Informe um email válido').required('Informe seu email'),
    companyName: Yup.string().when('type', {
        is: 'PJ',
        then: Yup.string().required('Informe o nome fantasia da empresa'),
        otherwise: Yup.string()
    }),
});

interface IRegister {
    id: string;
    type: string;
    doc: string;
    name: string;
    birthdate: Date | null;
    phone: string;
    term: boolean;
    companyName: string;
    email: string;
    step: number;
    fileFrontDOCPF: string;
    fileVersusDOCPF: string;
    fileCIPJ: string;
    fileCSPJ: string;
}

interface IProps {
    setOnData: (data: IRegister) => void;
}

export function FormRegister({ setOnData }: IProps) {
    const { enqueueSnackbar } = useSnackbar();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const formik = useFormik<IRegister>({
        enableReinitialize: true,
        initialValues: {
            id: '',
            type: 'PF',
            doc: '',
            name: '',
            birthdate: null,
            phone: '',
            term: false,
            companyName: '',
            email: '',
            fileFrontDOCPF: '',
            fileVersusDOCPF: '',
            fileCIPJ: '',
            fileCSPJ: '',
            step: 0
        },
        validationSchema: schemaForm,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                if (!executeRecaptcha) {
                    enqueueSnackbar('Ocorreu um erro no recaptcha!', snackConfig.warning);
                    return;
                }

                const data = await handleSave(values);
                if (data.error) {
                    enqueueSnackbar(data.error, snackConfig.warning);
                    return;
                }

                let nextStep = 1;
                if (data.validPhone && !data.validEmail) nextStep = 2;
                else if (data.validEmail && !data.docSend) nextStep = 3;
                else if (data.docSend) nextStep = 4;

                setOnData({
                    id: data.id,
                    type: values.type,
                    doc: values.doc,
                    name: values.name,
                    birthdate: values.birthdate,
                    phone: values.phone,
                    term: values.term,
                    companyName: values.companyName,
                    email: values.email,
                    fileFrontDOCPF: '',
                    fileVersusDOCPF: '',
                    fileCIPJ: '',
                    fileCSPJ: '',
                    step: nextStep,
                });
                enqueueSnackbar('Conta criada com sucesso!', snackConfig.success);
            } catch (error: any) {
                const message = error.response?.data.message ?? 'Ocorreu um erro ao avançar para a próxima etapa';
                enqueueSnackbar(message, snackConfig.warning);
            } finally {
                setSubmitting(false);
            }
        }
    });

    const handleSave = async (values: IRegister) => {
        const options = {
            method: 'POST',
            url: '/create-account',
            data: {
                type: values.type,
                doc: values.doc,
                email: values.email,
                companyName: values.companyName,
                name: values.name,
                phone: values.phone,
                ddi: '55',
                typeDoc: null,
                birthDate: values.birthdate,
            }
        };

        const response = await api.API.request(options);
        console.log(response)
        return response.data;
    };

    const { errors, touched, isSubmitting, setFieldValue, values } = formik;

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='h5' sx={{ fontSize: 28, color: 'black' }} marginTop={3}>Abra sua conta</Typography>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" className='form'>
                    <div>
                        <RadioGroup name="tipo" value={values.type} onChange={text => { setFieldValue('type', text); }}>
                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                <Radio value='PF'>Pessoa Fisica</Radio>
                                <Radio value='PJ'>Pessoa Jurídica</Radio>
                            </div>
                        </RadioGroup>
                        {errors.type && touched.type && (<HelperTextError>{errors.type}</HelperTextError>)}
                    </div>
                    <div>
                        <InputGroup>
                            <Input
                                placeholder={values.type === 'PF' ? "CPF" : "CNPJ"}
                                value={values.type === 'PF' ? Mask.toPattern(values.doc, '999.999.999-99') : Mask.toPattern(values.doc, '99.999.999/9999-99')}
                                size='lg'
                                inputMode="numeric"
                                onChange={text => { setFieldValue('doc', text); }}
                            />
                        </InputGroup>
                        {errors.doc && touched.doc && (<HelperTextError>{errors.doc}</HelperTextError>)}
                    </div>
                    <div>
                        <InputGroup>
                            <Input
                                placeholder={values.type === 'PF' ? "Nome" : "Razão Social"}
                                value={values.name}
                                size='lg'
                                onChange={text => { setFieldValue('name', text); }}
                            />
                        </InputGroup>
                        {errors.name && touched.name && (<HelperTextError>{errors.name}</HelperTextError>)}
                    </div>
                    {values.type === 'PJ' && (
                        <div>
                            <InputGroup>
                                <Input
                                    placeholder="Nome Fantasia"
                                    value={values.companyName}
                                    size='lg'
                                    onChange={text => { setFieldValue('companyName', text); }}
                                />
                            </InputGroup>
                            {errors.companyName && touched.companyName && (<HelperTextError>{errors.companyName}</HelperTextError>)}
                        </div>
                    )}
                    {values.type === 'PF' && (
                        <div>
                            <InputGroup>
                                <DatePicker
                                    placeholder="Data de Nascimento"
                                    locale={LOCALE_DATEPICKER}
                                    value={values.birthdate}
                                    size='lg'
                                    format="dd/MM/yyyy"
                                    style={{ width: "100%" }}
                                    onChange={text => { setFieldValue('birthdate', text); }}
                                />
                            </InputGroup>
                            {errors.birthdate && touched.birthdate && (<HelperTextError>{errors.birthdate}</HelperTextError>)}
                        </div>
                    )}
                    <div>
                        <InputGroup>
                            <Input
                                placeholder="Telefone"
                                value={Mask.toPattern(values.phone, '(99) 99999-9999')}
                                size='lg'
                                inputMode="numeric"
                                onChange={text => { setFieldValue('phone', text); }}
                            />
                        </InputGroup>
                        {errors.phone && touched.phone && (<HelperTextError>{errors.phone}</HelperTextError>)}
                    </div>
                    <div>
                        <InputGroup>
                            <Input
                                placeholder="Email"
                                value={values.email}
                                size='lg'
                                onChange={text => { setFieldValue('email', text); }}
                            />
                        </InputGroup>
                        {errors.email && touched.email && (<HelperTextError>{errors.email}</HelperTextError>)}
                    </div>
                    <div>
                        <Checkbox
                            checked={values.term}
                            onChange={() => { setFieldValue('term', !values.term); }}
                        >
                            Autorizo o {window.location.hostname.indexOf('interpays.com.br') > -1 ? 'Interpays Bank' : 'Anna Bank'} a tratar meus dados pessoais para envio de comunicações sobre seus produtos e serviços e também estou de acordo com a <a href="/privacy" target="_blank" rel=''>Política de privacidade</a>.
                        </Checkbox>
                        {errors.term && touched.term && (<HelperTextError>{errors.term}</HelperTextError>)}
                    </div>
                    <Button type='submit' color='turquoise' typeLoad='clock' load={isSubmitting} className='margin-top2' style={{ height: 36, alignItems: 'center', fontSize: '16px' }}>
                        Continuar
                    </Button>
                    <div className='flex-direction-column display-flex text-align-center margin-top2'>
                        <Link to="/login" style={{ fontSize: '14px', margin: '25px 0' }}>Iniciar Sessão</Link>
                    </div>
                </Form>
            </FormikProvider>
        </div>
    );
}
