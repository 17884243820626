import { Input } from "rsuite";
import { Button } from "../../../../components/Button";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from 'yup';
import handleDoc from '../../../../utils/valida_cpf_cnpj';
import Mask from 'vanilla-masker';

import * as snackConfig from '../../../../utils/configDefaultSnackbar';
import * as apiInterpays from '../../../../services/apiInterpays';
import { HelperTextError } from "../../style";
import { MdChevronLeft } from "react-icons/md";

const schemaForm = Yup.object().shape({
    doc: Yup.string()
        .required('informe seu CPF/CNPJ')
        .test('validDoc', 'documento inválido', (value, context) => {
            return typeof value !== 'undefined' && handleDoc.valida_cpf_cnpj(value);
        })
});

interface IProps {
    setHash: (hash: string, user: any) => void;
    onBack: () => void;

}

export function InitForgot(props: IProps) {
    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            doc: '',
        },
        validationSchema: schemaForm,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                const { hash, user } = await handleSubmit(values);
                props.setHash(hash, user);
                // closeModal();
                // enqueueSnackbar(message, snackConfig.success);

            } catch (error: any) {
                const message = error.response?.data.message ?? 'Ocorreu um erro ao fazer requisição';
                enqueueSnackbar(message, snackConfig.warning);
            }
        }
    });

    const handleSubmit = async ({ doc }: { doc: string }) => {
        const options = {
            method: 'POST',
            url: 'forgot',
            data: {
                doc: doc.replace(/\D/g, '')
            }
        }

        const response = await apiInterpays.API.request(options);

        return response.data;
    }

    const { errors, touched, setFieldValue, isSubmitting, values } = formik;

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" className="form">
                <Input
                    placeholder="CPF/CNPJ"
                    className='margin-top2'
                    size="lg"
                    value={values.doc.length <= 14 ? Mask.toPattern(values.doc, '999.999.999-99') : Mask.toPattern(values.doc, '99.999.999/9999-99')}
                    onChange={text => setFieldValue('doc', text)} />
                {errors.doc && touched.doc && (<HelperTextError>{errors.doc}</HelperTextError>)}

                <div className="display-flex justify-content-space-between align-items-center margin-top2">

                    <Button
                        type="button"
                        color='turquoise-light'
                        onClick={props.onBack}
                        style={{ flex: 1, marginRight: 10, minHeight: 36, height: 36, alignItems: 'center', fontSize: '16px' }}
                    >
                        Voltar
                    </Button>

                    <Button
                        color='turquoise'
                        load={isSubmitting}
                        typeLoad="clock"
                        type="submit"
                        style={{ flex: 1, marginRight: 10, minHeight: 36, height: 36, alignItems: 'center', fontSize: '16px' }}
                    >
                        Continuar
                    </Button>

                </div>
            </Form>
        </FormikProvider>
    )
}