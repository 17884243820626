import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s;
    padding: 17px 10px;
    border-radius: 5px;

    &:hover {
        background: var(--primary-light);
    }
`

export const Content = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    .content-detail {
        margin-left: 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .amount {
            font-size: 14px;
            margin-right: 10px;
            color: var(--text-light);
        }
        
        .details {
            div {
                color: var(--text-light);
                font-size: 13px;
            }

        }
    }
`