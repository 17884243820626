import React, { useCallback, useEffect, useState, useRef } from "react";
import { useSnackbar } from "notistack";
import { AppContent } from "../../../components/AppContent";
import { Breadcrumps } from "../../../components/Breadcrumbs";
import { FaListCheck } from "react-icons/fa6";
import * as apiInterpays from "../../../services/apiInterpays";
import { Card, Grid, Button, Box, TextField, Autocomplete, Typography, IconButton, CircularProgress } from "@mui/material";
import CreateTicketModal from "./CreateTicketModal";
import ResponderTicketModal from "./ResponderTicketModal";
import TicketTableContainer from "./TicketTableContainer";
import TicketListContainer from "./TicketListContainer";
import { handleValidationErrors } from "../../../utils/handleRequestError";
import { FilterContent } from "../../../components/FilterContent";
import CloseIcon from "@mui/icons-material/Close";
import { DateRangePicker } from "rsuite";
import { LOCALE_DATEPICKER } from "../../../constants";
import { MdSearch } from "react-icons/md";

export function Tickets() {
  const { enqueueSnackbar } = useSnackbar();
  const [showFilters, setShowFilters] = useState(false);
  const [searching, setSearching] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [intervalFilter, setIntervalFilter] = useState<[Date, Date]>([new Date(), new Date()]);
  const [tickets, setTickets] = useState<any[]>([]);
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalReplyOpen, setModalReplyOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalTickets, setTotalTickets] = useState(0);
  const [modalTicketForm, setModalTicketForm] = useState({
    produto: "",
    titulo: "",
    descricao: "",
    anexos: [] as File[],
  });

  const [modalTicketReplyForm, setModalTicketReplyForm] = useState({
    id: "",
    descricao: "",
    anexos: [] as File[],
  });

  const [cardHeight, setCardHeight] = useState(calculateCardHeight());
  const [cardWidth, setCardWidth] = useState(document.body.clientWidth || window.innerHeight || document.documentElement.clientHeight);

  const cardRef = useRef<HTMLDivElement | null>(null);

  const statusOptions = [
    { value: "Aberto", label: "Aberto" },
    { value: "Fechado", label: "Fechado" },
    { value: "Em Atendimento", label: "Em Atendimento" },
  ];

  function calculateCardHeight() {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    return windowHeight - 165;
  }
  const handleToggleFilters = () => setShowFilters((prev) => !prev);

  const calculateCardWidth = () => {
    if (cardRef.current) {
      const cardWidth = cardRef.current.getBoundingClientRect().width;
      return cardWidth;
    }
    return document.body.clientWidth || window.innerHeight || document.documentElement.clientHeight;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggle = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleTickets = useCallback(async () => {
    try {
      const response = await apiInterpays.API.get(`/v2/tickets?page=${page}&rowsPerPage=${rowsPerPage}`);
      setTotalTickets(response.data[0].ticketsCount);
      setTickets(response.data[0].ticketsData);
      setLoading(false);
    } catch (error: any) {
      if (error.response && error.response.data) {
        handleValidationErrors(error.response.data, enqueueSnackbar);
      } else {
        enqueueSnackbar("Erro ao criar o ticket.", { variant: "error" });
      }
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, page, rowsPerPage]);

  const handleResponderTicket = async () => {
    setTickets([]);
    const formData = new FormData();
    formData.append("descricao", modalTicketReplyForm.descricao);
    modalTicketReplyForm.anexos.map((anexo: any) => formData.append("anexos", anexo));
    try {
      await apiInterpays.API.put(`/v2/tickets/${id}`, formData);
      setModalTicketReplyForm({
        id: "",
        descricao: "",
        anexos: [] as File[],
      });
      handleTickets();
      setModalReplyOpen(false);
    } catch (error: any) {
      if (error.response && error.response.data) {
        handleValidationErrors(error.response.data, enqueueSnackbar);
      } else {
        enqueueSnackbar("Erro ao criar o ticket.", { variant: "error" });
      }
    }
  };

  const handleEncerrarTicket = async (ticket: any) => {
    try {
      setTickets([]);
      await apiInterpays.API.patch(`/v2/tickets/${ticket.id}`);
      handleTickets();
    } catch (error: any) {
      if (error.response && error.response.data) {
        handleValidationErrors(error.response.data, enqueueSnackbar);
      } else {
        enqueueSnackbar("Erro ao criar o ticket.", { variant: "error" });
      }
    }
  };

  const handleCreateTicket = async () => {
    const formData = new FormData();
    formData.append("titulo", modalTicketForm.titulo);
    formData.append("produto", modalTicketForm.produto);
    formData.append("descricao", modalTicketForm.descricao);
    modalTicketForm.anexos.map((anexo: any) => formData.append("anexos", anexo));

    try {
      await apiInterpays.API.post("/v2/tickets", formData);
      setModalTicketForm({
        produto: "",
        titulo: "",
        descricao: "",
        anexos: [] as File[],
      });
      handleTickets(); // Atualiza a lista de tickets após a criação
      setModalOpen(false);
    } catch (error: any) {
      if (error.response && error.response.data) {
        handleValidationErrors(error.response.data, enqueueSnackbar);
      } else {
        enqueueSnackbar("Erro ao criar o ticket.", { variant: "error" });
      }
    }
  };

  useEffect(() => {
    function handleResize() {
      setCardHeight(calculateCardHeight());
      setCardWidth(calculateCardWidth());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      handleTickets();
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, [handleTickets]);
  function handleSearch(arg0: boolean): void {
    throw new Error("Function not implemented.");
  }

  return (
    <AppContent>
      <Breadcrumps
        Icon={FaListCheck}
        title="Meus Tickets"
        links={[
          { name: "Suporte", href: "#" },
          { name: "Meus Tickets", href: "/app/suporte/meus-tickets" },
        ]}
        onClickButtonFilter={handleToggleFilters}
      />
      <FilterContent open={showFilters} onClose={handleToggleFilters}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 1 }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={handleToggleFilters} size="large">
            <CloseIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Box>
        <DateRangePicker
          defaultValue={intervalFilter}
          showOneCalendar
          size="lg"
          style={{ width: "100%" }}
          menuStyle={{ zIndex: 200 }}
          placement="bottomEnd"
          className="mt-4"
          locale={LOCALE_DATEPICKER}
          onChange={(value) => setIntervalFilter(value as [Date, Date])}
          placeholder="Período"
        />

        {/* Account Autocomplete with styles */}
        <Autocomplete
          disablePortal
          fullWidth
          options={statusOptions}
          size="medium"
          sx={{ marginTop: 2 }}
          value={statusOptions.find((option) => option.value === statusFilter) ?? null}
          onChange={(event, value) => setStatusFilter(value?.value ?? null)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Status"
              InputLabelProps={{ style: { color: "#0e8ba4" } }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#0e8ba4",
                  },
                  "&:hover fieldset": {
                    borderColor: "#0e8ba4",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0e8ba4",
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#0e8ba4",
                },
              }}
            />
          )}
        />

        {/* Styled Button */}
        <Box mt={1} width="100%">
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSearch(true)}
            fullWidth
            disabled={searching}
            startIcon={searching ? <CircularProgress size={20} color="inherit" /> : <MdSearch size={20} />}
            sx={{
              backgroundColor: "#0e8ba4",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#0d7e8b",
              },
              marginTop: 2,
            }}
          >
            Filtrar
          </Button>
        </Box>
      </FilterContent>
      <Grid container justifyContent="center" marginTop={-4} padding={1}>
        <Grid xs={12} sm={12} lg={12} item>
          <Card ref={cardRef} sx={{ p: 2, mt: 3, width: "100%", height: `${cardHeight}px`, overflowX: "auto", overflowY: "auto" }}>
            <Grid container justifyContent="flex-end" item>
              <Button
                variant="contained"
                onClick={() => setModalOpen(true)}
                sx={{
                  backgroundColor: "#0e8ba4",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#0d7e8b",
                  },
                }}
              >
                Criar Ticket
              </Button>
            </Grid>
            {cardWidth >= 1000 ? (
              <TicketTableContainer
                setId={setId}
                setModalReplyOpen={setModalReplyOpen}
                handleEncerrarTicket={handleEncerrarTicket}
                loading={loading}
                tickets={tickets}
                openIndex={openIndex}
                handleToggle={handleToggle}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                totalTickets={totalTickets}
              />
            ) : (
              <TicketListContainer setId={setId} setModalReplyOpen={setModalReplyOpen} handleEncerrarTicket={handleEncerrarTicket} loading={loading} tickets={tickets} openIndex={openIndex} handleToggle={handleToggle} />
            )}
          </Card>
        </Grid>
      </Grid>
      <ResponderTicketModal open={isModalReplyOpen} onClose={() => setModalReplyOpen(false)} onSubmit={handleResponderTicket} ticketReply={modalTicketReplyForm} setTicketReplyForm={setModalTicketReplyForm} />
      <CreateTicketModal open={isModalOpen} onClose={() => setModalOpen(false)} onSubmit={handleCreateTicket} ticketForm={modalTicketForm} setTicketForm={setModalTicketForm} />
    </AppContent>
  );
}
