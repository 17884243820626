import { Container, Content, BackDrop } from './styles';

interface IProps{
    open: boolean;
    children: JSX.Element | JSX.Element[];
    onClose: () => void;
}

export function FilterContent(props: IProps){
    return(
        <>
            <Container open={props.open}>
                <Content>
                    {props.children}
                </Content>
            </Container>
            <BackDrop open={props.open} onClick={props.onClose}/>
        </>
    )
}