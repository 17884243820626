import { Typography, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from 'yup';
import * as configsSnackbar from '../../../utils/configDefaultSnackbar';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import * as apiInterpays from '../../../services/apiInterpays';
import { Button } from "../../../components/Button";
import * as auth from '../../../auth';

const schemaForm = Yup.object().shape({
    code1: Yup.string().required('Código não informado'),
    code2: Yup.string().required('Código não informado'),
    code3: Yup.string().required('Código não informado'),
    code4: Yup.string().required('Código não informado'),
    code5: Yup.string().required('Código não informado'),
    code6: Yup.string().required('Código não informado')
});

interface IProps {
    tokenLogin: string;
    setTokenLogin: (token: string | null) => void;
}

function maxLength(object: any) {
    if (object.target.value.length > object.target.maxLength) {
        return (object.target.value = object.target.value.slice(0, object.target.maxLength));
    }
}

export function FormPin({ tokenLogin, setTokenLogin }: IProps) {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const refs: any = {
        code1: useRef(null),
        code2: useRef(null),
        code3: useRef(null),
        code4: useRef(null),
        code5: useRef(null),
        code6: useRef(null),
    }

    const formik = useFormik({
        initialValues: {
            code1: '',
            code2: '',
            code3: '',
            code4: '',
            code5: '',
            code6: ''
        },
        validationSchema: schemaForm,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                const result = await handleValidity(values);

                auth.cookies.set('auth', result.accessToken);
                apiInterpays.setInterceptorAuth();
                navigate('/app/dashboard');
            } catch (error: any) {
                const message = error.response?.data.message ?? 'Ocorreu um erro ao fazer requisição';
                enqueueSnackbar(message, configsSnackbar.warning);
            }
        }
    });

    const handleValidity = async (values: any) => {
        apiInterpays.removeInterceptorAuth();
        const options = {
            method: 'POST',
            url: '/pin',
            headers: {
                Authorization: `Bearer ${tokenLogin}`
            },
            data: {
                pin: `${values.code1}${values.code2}${values.code3}${values.code4}${values.code5}${values.code6}`
            }
        }

        const { data } = await apiInterpays.API.request(options);

        return data;
    }

    const { errors, touched, values, handleSubmit, handleChange, isSubmitting } = formik;

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='h5' sx={{ fontSize: 28, color: 'black' }} marginTop={3} >Iniciar sessão</Typography>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" className="form" onSubmit={handleSubmit}>

                    <Typography color="GrayText" align="center" marginTop={3}>Informe o código de validação enviado para os seus contatos cadastrados:</Typography>

                    <Stack direction="row" spacing={2} marginTop={4} marginBottom={4} justifyContent="center">
                        {Object.keys(values).map((key: string, indice: number) => {
                            const touchedObject = touched as any;
                            const errorsObject = errors as any;

                            return (
                                <TextField
                                    key={key}
                                    inputRef={refs[key]}
                                    onChange={event => {
                                        event.target.value = event.target.value.toUpperCase().replace(/\D/g, '');
                                        handleChange({ target: { name: key, value: event.target.value.replace(/\D/g, '') } });
                                        if ((indice + 1) < Object.keys(values).length) {
                                            if (refs[`code${(indice + 1)}`].current.value !== "")
                                                refs[`code${(indice + 2)}`].current.focus();
                                        }
                                    }}
                                    placeholder="-"
                                    size="medium"
                                    onInput={maxLength}
                                    inputMode="numeric"
                                    type="text"
                                    error={Boolean(touchedObject[key] && errorsObject[key])}
                                    inputProps={{
                                        maxLength: 1,
                                        inputMode: 'numeric',
                                        sx: {
                                            p: 0,
                                            textAlign: 'center',
                                            width: { xs: 40, sm: 40 },
                                            height: { xs: 40, sm: 40 }
                                        }
                                    }}
                                />
                            )
                        })}
                    </Stack>
                    <div className="display-flex justify-content-space-between padding-1 w-100">
                        <Button
                            type="button"
                            color='turquoise-light'
                            onClick={() => setTokenLogin(null)}
                            style={{ flex: 1, marginRight: 10, minHeight: 36, height: 36, alignItems: 'center', fontSize: '16px' }}
                        >
                            Voltar
                        </Button>

                        <Button type="submit" style={{ flex: 1, minHeight: 36, height: 36, alignItems: 'center', fontSize: '16px' }} color="turquoise" load={isSubmitting} typeLoad="clock">Confirmar</Button>
                    </div>
                </Form>
            </FormikProvider>
        </div>
    )
}