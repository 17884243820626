import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { AppContent } from "../../../../components/AppContent";
import { Breadcrumps } from "../../../../components/Breadcrumbs";
import { FaLessThanEqual, FaUserCog } from "react-icons/fa";
import * as apiInterpays from "../../../../services/apiInterpays";
import * as handleRequestError from "../../../../utils/handleRequestError";
import { Card, Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import UsersConfListContainer from "./UsersConfListContainer";
import UsersConfTableContainer from "./UsersConfTableContainer";
import UsersCreateModal from "./UsersCreateModal";

export function UsersConf() {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<any[]>([]);
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalContas, setTotalContas] = useState(0);
  const [cardHeight, setCardHeight] = useState(calculateCardHeight());
  const [cardWidth, setCardWidth] = useState(document.body.clientWidth || window.innerHeight || document.documentElement.clientHeight);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [buscando, setBuscando] = useState(false);

  const [modalUserForm, setModalUserForm] = useState({ documento: "", accountId: accountId, nome: "" });

  const cardRef = useRef<HTMLDivElement | null>(null);

  function calculateCardHeight() {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    return windowHeight - 165;
  }

  const calculateCardWidth = () => {
    if (cardRef.current) {
      const cardWidth = cardRef.current.getBoundingClientRect().width;
      return cardWidth;
    }
    return document.body.clientWidth || window.innerHeight || document.documentElement.clientHeight;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggle = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleWriteChange = (userId: string) => async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setData((prevData) =>
      prevData.map((account) => ({
        ...account,
        ManagerAccountsUser: account.ManagerAccountsUser.map((user: any) => (user.id === userId ? { ...user, write: checked } : user)),
      }))
    );
    await apiInterpays.API.put(`/v2/configuracoes/usuarios/${userId}/${checked}`);
    enqueueSnackbar("Usuário alterado com sucesso.", { variant: "success" });
  };

  const handleBlockChange = (userId: string) => async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setData((prevData) =>
      prevData.map((account) => ({
        ...account,
        ManagerAccountsUser: account.ManagerAccountsUser.map((user: any) => (user.id === userId ? { ...user, block: checked } : user)),
      }))
    );
    await apiInterpays.API.patch(`/v2/configuracoes/usuarios/${userId}/${checked}`);
    enqueueSnackbar("Usuário bloqueado com sucesso.", { variant: "success" });
  };

  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmCreateAccount = async () => {
    await apiInterpays.API.post(`/v2/configuracoes/contas`);
    handleData();
    enqueueSnackbar("Sua nova conta foi criada com sucesso.", { variant: "success" });
    setConfirmDialogOpen(false);
    handleCloseConfirmDialog();
  };

  const handleCancelCreateAccount = () => {
    handleCloseConfirmDialog();
  };

  const handleCreateUser = async () => {
    await apiInterpays.API.post(`/v2/configuracoes/usuarios/${accountId}`, modalUserForm);
    enqueueSnackbar("Usuário administrador criado com sucesso.", { variant: "success" });
    handleData();
  };

  const handleOnSearch = async () => {
    setBuscando(true);
    setModalUserForm({ documento: modalUserForm.documento, accountId: accountId, nome: "" });
    await apiInterpays.API.get(`/v2/configuracoes/contas/${accountId}`, {
      params: {
        documento: modalUserForm.documento,
      },
    })
      .then((response) => {
        if (response.data[0].nome) {
          setModalUserForm(response.data[0]);
        } else {
          enqueueSnackbar(response.data, { variant: "error" });
        }
        setBuscando(false);
      })
      .catch((error: any) => {
        enqueueSnackbar(error.message, { variant: "error" });
        setBuscando(false);
      });
  };

  const handleData = useCallback(async () => {
    try {
      const response = await apiInterpays.API.get(`/v2/configuracoes/usuarios`);
      setData(response.data);
      setTotalContas(response.data.length);
      setLoading(false);
    } catch (error: any) {
      const responseError = error.response;
      if (responseError && responseError.data && responseError.data.message) {
        handleRequestError.handleError(responseError, enqueueSnackbar);
      } else {
        console.error("Erro ao processar a resposta da API:", error);
        enqueueSnackbar("Ocorreu um erro ao processar a resposta da API", { variant: "error" });
      }
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    function handleResize() {
      setCardHeight(calculateCardHeight());
      setCardWidth(calculateCardWidth());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      handleData();
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, [handleData]);

  return (
    <AppContent>
      <Breadcrumps
        Icon={FaUserCog}
        title="Configurações de contas"
        links={[
          { name: "Configurações", href: "#" },
          { name: "Contas", href: "/app/configuracoes/contas" },
        ]}
      />
      <Grid container justifyContent="center" marginTop={-4} padding={1}>
        <Grid xs={12} sm={12} lg={12} item>
          <Card ref={cardRef} sx={{ p: 2, mt: 3, width: "100%", height: `${cardHeight}px`, overflowX: "auto", overflowY: "auto" }}>
            <Grid container justifyContent="flex-end" item>
              <Button
                variant="contained"
                onClick={handleOpenConfirmDialog}
                sx={{
                  backgroundColor: "#0e8ba4",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#0d7e8b",
                  },
                }}
              >
                Criar Conta
              </Button>
            </Grid>
            {cardWidth >= 700 ? (
              <UsersConfTableContainer
                loading={loading}
                data={data}
                openIndex={openIndex}
                handleToggle={handleToggle}
                handleWriteChange={handleWriteChange}
                handleBlockChange={handleBlockChange}
                page={page}
                rowsPerPage={rowsPerPage}
                totalContas={totalContas}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                setModalOpen={setModalOpen}
                isModalOpen={isModalOpen}
                accountId={accountId}
                setAccountId={setAccountId}
              />
            ) : (
              <UsersConfListContainer loading={loading} data={data} openIndex={openIndex} handleToggle={handleToggle} handleWriteChange={handleWriteChange} handleBlockChange={handleBlockChange} setModalOpen={setModalOpen} isModalOpen={isModalOpen} accountId={accountId} setAccountId={setAccountId} />
            )}
          </Card>
        </Grid>
      </Grid>
      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirmação</DialogTitle>
        <DialogContent>
          <DialogContentText>Ao clicar em Confirmar, será criada uma nova conta que estará vinculada ao seu documento CPF/CNPJ.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelCreateAccount}
            sx={{
              backgroundColor: "#ffffff",
              color: "#0e8ba4",
              border: "1px solid",
              borderColor: "##0e8ba4",
              "&:hover": {
                backgroundColor: "#d8eef1",
              },
            }}
          >
            cancelar
          </Button>
          <Button
            onClick={handleConfirmCreateAccount}
            sx={{
              backgroundColor: "#0e8ba4",
              color: "white",
              "&:hover": {
                backgroundColor: "#0d7e8b",
              },
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <UsersCreateModal
        open={isModalOpen}
        onClose={() => {
          setAccountId("");
          setModalUserForm({ documento: "", accountId: "", nome: "" });
          setModalOpen(false);
        }}
        buscando={buscando}
        setBuscando={setBuscando}
        onSubmit={handleCreateUser}
        onSearch={handleOnSearch}
        modalUserForm={modalUserForm}
        setModalUserForm={setModalUserForm}
      />
    </AppContent>
  );
}
