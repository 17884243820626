import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Button } from '../Button';
import { Carousel } from 'rsuite';
import { Container } from './style';
import { colors } from '../../styles/global';
import { PulseLoader } from 'react-spinners';
import * as utils from '../../utils/utils';
import { SlRefresh } from 'react-icons/sl';


interface IProps {
    load?: boolean;
    onRefresh?: () => void;
    balances: Array<{
        amount: number;
        name: string;
        agency: number;
        account: number;
        onAdd: () => void;
    }>
}

export function CardBalance2(props: IProps) {
    const [showAmount, setShowAmount] = useState(true);

    return (
        <Carousel
            style={{
                height: 182,
                background: colors.turquoise,
                borderRadius: '10px'
            }}
            placement="top"
            shape='bar'
            autoplay={!props.load}
        >
            {
                props.load && (
                    <Container>
                        <div className="display-flex justify-content-center" style={{ marginTop: 65 }}>
                            <PulseLoader size={12} color={colors.white} speedMultiplier={0.7} />
                        </div>
                    </Container>
                )
            }

            {
                !props.load && props.balances.map(item => (
                    <Container key={utils.randomString()}>
                        <div id="amount">
                            <div className="display-flex justify-content-space-between align-items-center">
                                <div className="display-flex flex-direction-column margin-top1" style={{ fontSize: 12 }}>
                                    <span>Saldo {item.name}</span>
                                    <span>Ag: {item.agency} | Conta: {("00000000" + item.account).slice(-8)}</span>
                                </div>
                                {
                                    props.onRefresh && (
                                        <Button type='button' onClick={() => props.onRefresh!()} color='borderless' load={props.load}>
                                            <SlRefresh size={18} color={colors.white} />
                                        </Button>
                                    )
                                }
                            </div>

                            <div className='display-flex align-items-center margin-top1'>
                                <h3 >{showAmount ? utils.toMoney(item.amount) : '******'}</h3>
                                <Button
                                    color='primary-light'
                                    className='margin-left2'
                                    style={{ padding: 5 }}
                                    borderRounded
                                    onClick={() => setShowAmount(!showAmount)}>
                                    {
                                        showAmount ? <FaEyeSlash /> : <FaEye />
                                    }
                                </Button>
                            </div>
                        </div>
                        <button id="add-value" onClick={() => item.onAdd()}>Adicionar saldo</button>
                    </Container>
                ))
            }
        </Carousel>
    )
}