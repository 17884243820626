// import { NotificationItem } from "./NotificationItem";
import { Container, Content } from "./styles";

export function Notifications() {
    return (
        <Container>
            <header>
                <h5>Notificações</h5>
                <span>Nenhum notificação até o momento</span>
            </header>
            <Content>
                {/* <strong className="subcategory">Novas</strong>
                {
                    [1, 2].map(item => (
                        <NotificationItem key={item} newNotification={true} />
                    ))
                }

                <strong className="subcategory">Lidas</strong>
                {
                    [1, 2, 3].map(item => (
                        <NotificationItem key={item} />
                    ))
                } */}

            </Content>
            <a href='#!' onClick={() => console.log('carregando')}>Ver mais</a>
        </Container>
    )
}