import React, { useEffect, useState } from "react";
import { Modal, Button, Box, TextField, Divider, MenuItem, FormControl, InputLabel, Typography, CircularProgress } from "@mui/material";

interface UserForm {
  documento: string;
  accountId: string;
  nome: string;
}

interface UsersCreateModalProps {
  open: boolean;
  onClose: () => void;
  onSearch: (data: UserForm) => void;
  onSubmit: (formData: UserForm) => void;
  setModalUserForm: React.Dispatch<React.SetStateAction<UserForm>>;
  modalUserForm: UserForm;
  setBuscando: React.Dispatch<React.SetStateAction<boolean>>;
  buscando: boolean;
}

const UsersCreateModal: React.FC<UsersCreateModalProps> = ({ open, onClose, onSubmit, onSearch, setModalUserForm, modalUserForm, setBuscando, buscando }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleInputChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    setModalUserForm((prevForm) => ({
      ...prevForm,
      [name as string]: value,
    }));
    if (modalUserForm.nome !== "") {
      setModalUserForm((prevForm) => ({
        ...prevForm,
        nome : "",
      }));
    }
  };

  const handleSubmit = () => {
    onSubmit(modalUserForm);
    onClose();
  };

  const handleSearch = () => {
    onSearch(modalUserForm);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: windowWidth < 600 ? "100vw" : 600,
          borderRadius: "8px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          overflowX: "auto",
          overflowY: "auto",
          textAlign: "center",
        }}
      >
        <Box flexGrow={1} mb={2} textAlign="center">
          <Typography variant="h5" mb={2}>
            <strong>Adicionar novo usuário</strong>
          </Typography>
        </Box>
        <Divider />

        <Box flexGrow={1} mb={2}>
          <Box
            flexGrow={1}
            mb={2}
            textAlign="center"
            sx={{
              p: 2,
              borderRadius: "8px",
              marginTop: "16px",
            }}
          >
            {buscando ? (
              <CircularProgress size={24} />
            ) : modalUserForm.nome !== "" ? (
              <>
                <TextField fullWidth label="Documento" id="documento" name="documento" value={modalUserForm.documento} placeholder="Informe o CPF ou CNPJ do novo usuário." onChange={handleInputChange} sx={{ mb: 2, textAlign: "center" }} />
                <Typography variant="body1" mb={2}>
                  Confirma a inclusão da pessoa abaixo como usuário administrador da conta?
                </Typography>
                <Typography variant="body1" mb={2}>
                  <strong>Nome:</strong> {modalUserForm.nome}
                </Typography>
                <Typography variant="body1" mb={2}>
                  <strong>Documento:</strong> {modalUserForm.documento}
                </Typography>
              </>
            ) : (
              <TextField fullWidth label="Documento" id="documento" name="documento" value={modalUserForm.documento} placeholder="Informe o CPF ou CNPJ do novo usuário." onChange={handleInputChange} sx={{ mb: 2, textAlign: "center" }} />
            )}
          </Box>
          <Divider />
        </Box>

        <Box display="flex" justifyContent="space-between" flexGrow={1} mb={2} width="100%">
          <Button
            variant="contained"
            onClick={onClose}
            sx={{
              color: "#0e8ba4",
              backgroundColor: "white",
              border: "1px solid #0e8ba4",
              "&:hover": {
                backgroundColor: "white",
              },
              width: "48%",
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={modalUserForm.nome !== "" ? handleSubmit : handleSearch}
            sx={{
              backgroundColor: "#0e8ba4",
              color: "white",
              "&:hover": {
                backgroundColor: "#0d7e8b",
              },
              width: "48%",
            }}
          >
            {modalUserForm.nome !== "" ? "Confirmar" : "Continuar"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UsersCreateModal;
