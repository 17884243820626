// Add these imports at the top
import React from "react";
import {
  Box,
  Stack,
  Typography,
  Divider,
  IconButton,
  Button,    // Import Button
  StackProps
} from "@mui/material";
import { MdClose } from "react-icons/md";
import moment from "moment";
import * as utils from "../../../../utils/utils";

interface StatementParty {
  keyRecebedor: string;
  nomeISPBBancoRecebedor: string;
  cpfCnpjRecebedor: string;
  nomeCompletoRecebedor: string;
}

interface Statement {
  id: string;
  e2EId: string | null;
  valor: number;
  status: string;
  linkComprovante: string | null;
  motivoRejeicao: string | null;
  infoPagador: string | null;
  horario: string | null;
  createdAt: string;
  recebedor: StatementParty;
}

interface DetailsProps {
  item: Statement;
  onClose: () => void;
}

interface LabelValueProps extends StackProps {
  label: string;
  value: React.ReactNode;
}

function LabelValue({ label, value, ...rest }: LabelValueProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2} {...rest}>
      <Typography variant="subtitle2" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body2" fontWeight="bold">
        {value}
      </Typography>
    </Stack>
  );
}

export function Details({ item, onClose }: DetailsProps) {
  const dataCorrigida = item.status === 'COMPLETA' ? new Date(item.horario!) : new Date(item.createdAt);

  const formatDocument = (party: StatementParty): string => {
    return party.cpfCnpjRecebedor;
  };

  return (
    <Box padding={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{item.status}</Typography>
        <IconButton onClick={onClose}>
          <MdClose />
        </IconButton>
      </Stack>
      <Typography variant="h6" color="textSecondary" mt={1} align="center" fontWeight="bold">
        {moment(dataCorrigida).format("DD MMM YYYY HH:mm:ss")}
      </Typography>

      {item.id && <LabelValue label="CashOut ID" value={item.id} />}

      <LabelValue label="EndToEndID" value={item.e2EId} />

      <LabelValue label="Valor" value={utils.toMoney(item.valor * -1)} />

      <LabelValue label="Status" value={item.status} />

      {item.status === 'COMPLETA' && item.linkComprovante && (
        <LabelValue
          label="Comprovante"
          value={
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.open(item.linkComprovante!, '_blank')}
              sx={{
                backgroundColor: "#0e8ba4",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#0d7e8b",
                },
              }}
            >
              Ver Comprovante
            </Button>
          }
        />
      )}

      {item.status === 'RECUSADA' && (
        <LabelValue label="Motivo" value={item.motivoRejeicao} />
      )}
      
      <Divider sx={{ mt: 3, mb: 2 }}>
        <Typography variant="subtitle1">Recebedor</Typography>
      </Divider>

      {item.recebedor && (
        <>
          <LabelValue label="Nome" value={item.recebedor.nomeCompletoRecebedor} />

          <LabelValue label="Documento" value={formatDocument(item.recebedor)} mb={2} />

          <LabelValue label="Chave Pix" value={item.recebedor.keyRecebedor} mb={2} />

          <LabelValue label="Banco" value={item.recebedor.nomeISPBBancoRecebedor} mb={2} />
        </>
      )}
    </Box>
  );
}
