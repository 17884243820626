import React, { useContext, useState } from "react";
import { Container, Content } from "./styles";
import { Menu } from "@mui/material";
import { MdSegment, MdNotifications } from 'react-icons/md';
import { colors } from '../../styles/global';
import { ContextMenu } from '../../contexts/Contexts';
import { Notifications } from "./Notifications";

interface IProps {
    name: string;
    levelAccess: string;
    urlAvatar: string | null;
}

export function Header(props: IProps) {
    const menuContext = useContext(ContextMenu);
    const [anchorNotifications, setAnchorNotifications] = useState<null | HTMLElement>(null);

    const handleToggleNotifications = (event: React.MouseEvent<HTMLElement> | null) => {
        if (event)
            setAnchorNotifications(event.currentTarget);
        else
            setAnchorNotifications(null);
    }

    return (
        <Container>
            <Content>
                <button type="button" onClick={() => menuContext.handleToggleMenu?.()}>
                    <MdSegment color={colors.white} size={24} />
                </button>
            </Content>

            <Content>
                <button
                    onClick={handleToggleNotifications}
                    type="button">
                    {/* <Badge badgeContent={4} color="warning"> */}
                    <MdNotifications color={colors.white} size={24} />
                    {/* </Badge> */}
                </button>

                <Menu
                    anchorEl={anchorNotifications}
                    id="notifications-menu"
                    open={Boolean(anchorNotifications)}
                    // open={true}
                    onClose={() => handleToggleNotifications(null)}
                    // onClick={handleToggleMenuAccount}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <Notifications />
                </Menu>
            </Content>
        </Container>
    )
}