import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    align-items: center;
    padding: 15px 0;

    #content {
        padding-left: 25px;
        display: flex;
        flex-direction: column;

        span {
            color: var(--shadow);
            font-size: 13px;
        }
    }
`