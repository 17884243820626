export const handle = (message: string) => {
    let enqueueNotifications: any = localStorage.getItem('enqueueNotifications');
    enqueueNotifications = enqueueNotifications ? JSON.parse(enqueueNotifications) : [];
    enqueueNotifications = enqueueNotifications.filter((item: any) => !item.notify);

    enqueueNotifications.push({
        message,
        notify: false
    });

    localStorage.setItem('enqueueNotifications', JSON.stringify(enqueueNotifications));
}