import { Link } from 'react-router-dom';
import cloudSvg from '../../assets/cloud.svg';
import { Container } from './style';

export function Page404() {
    return (
        <Container>
            <img src={cloudSvg} alt="cloud" />
            <div>
                <h2>A página que você procura não existe</h2>
                <span>
                    Volte para a página anterior ou use o menu para encontrar o que precisa.
                </span>
                <Link to="/">Voltar</Link>
            </div>
        </Container>
    )
}