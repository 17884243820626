import { Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { IHead, ThemeTableHead } from "./ThemeTableHead";
import { HashLoader } from 'react-spinners';
import * as utils from '../../utils/utils';
import { colors } from "../../styles/global";

function descendingComparator(a: any, b: any, orderBy: string) {
    let valueA = a[orderBy];
    let valueB = b[orderBy];

    valueA = valueA && typeof valueA === 'string' && valueA.indexOf('R$') > -1 ? parseFloat(valueA.replace('R$', '').replace(/\s/g, '').replace(/\./g, '').replace(',', '.')) : valueA;
    valueB = valueB && typeof valueB === 'string' && valueB.indexOf('R$') > -1 ? parseFloat(valueB.replace('R$', '').replace(/\s/g, '').replace(/\./g, '').replace(',', '.')) : valueB;

    if (valueB < valueA) {
        return -1;
    }
    if (valueB > valueA) {
        return 1;
    }
    return 0;
}

function getComparator(order: string, orderBy: string) {
    return order === 'desc'
        ? (a: any, b: any) => descendingComparator(a, b, orderBy) : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array: any[], comparator: any) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }

        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface IProps {
    data: Array<any>;
    initOrderBy: string;
    headeCells: Array<IHead>;
    loading?: boolean;
    countPagination: number;
    currentPage?: number;
    onChangeTextSearch?: (text: string) => void;
    changePage?: (page: number) => void;
}

export function ThemeTable(props: IProps) {
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState(props.initOrderBy);

    const handleRequestSort = (event: string, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <Box sx={{ width: '100%' }}>
            {
                props.onChangeTextSearch && (
                    <div className="display-flex justify-content-end padding-1">
                        <TextField
                            size="small"
                            label="Procurar"
                            onChange={(event) => props.onChangeTextSearch?.(event.target.value)}
                        />
                    </div>
                )
            }

            <Paper>
                <TableContainer sx={{ width: '100%' }}>
                    <Table>
                        <ThemeTableHead
                            order={order}
                            orderBy={orderBy}
                            headCells={props.headeCells}
                            rowCount={0}
                            numSelected={0}
                            onRequestSort={handleRequestSort}
                        />

                        <TableBody>
                            {props.loading ? (
                                <TableRow>
                                    <TableCell colSpan={props.headeCells.length} align="center">
                                        <div className="display-flex justify-content-center">
                                            <HashLoader size={18} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                                :
                                stableSort(props.data, getComparator(order, orderBy))
                                    .map((item, indice) => (
                                        <TableRow style={{ backgroundColor: indice % 2 === 0 ? colors.white : colors.white }} key={utils.randomString()} hover>
                                            {
                                                props.headeCells.map((itemHead, indice) => (
                                                    <TableCell sx={{ background: itemHead.rowBgColor ?? '' }} key={utils.randomString()} padding={itemHead.padding ?? 'normal'} width={itemHead.width ?? 'auto'} align={indice === 0 ? "left" : "center"}>
                                                        {item[itemHead.name]}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    ))
                            }

                            {
                                props.data.length === 0 && !props.loading && (
                                    <TableRow>
                                        <TableCell colSpan={props.headeCells.length} align="center">
                                            <div className="display-flex justify-content-center">
                                                Nenhuma registro encontrado
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>

                </TableContainer>
                <Stack spacing={2} paddingTop={2} paddingBottom={1} justifyContent="end">
                    <div className="display-flex justify-content-end">
                        <Stack>
                            {
                                props.countPagination > 0 && (
                                    <Pagination
                                        count={props.countPagination}
                                        onChange={(event, pageNumber) => props.changePage?.(pageNumber)}
                                        page={props.currentPage}
                                        variant="outlined"
                                        color="primary" />
                                )
                            }
                        </Stack>
                    </div>
                </Stack>
            </Paper>
        </Box>
    )
}