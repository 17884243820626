import { Container } from "./style"

export function SuccessIcon() {
    return (
        <Container>
            <div className="swal2-icon swal2-success swal2-animate-success-icon" style={{ display: "flex" }}>
                <div className="swal2-success-circular-line-left" style={{ backgroundColor: "#f0f0f0" }}></div>
                <span className="swal2-success-line-tip"></span>
                <span className="swal2-success-line-long"></span>
                <div className="swal2-success-ring"></div>
                <div className="swal2-success-fix" style={{ backgroundColor: "#f0f0f0" }}></div>
                <div className="swal2-success-circular-line-right" style={{ backgroundColor: "#f0f0f0" }}></div>
            </div>
        </Container>
    )
}