import React from "react";
import { Box, Stack, Typography, Divider, IconButton, StackProps } from "@mui/material";
import { MdClose } from "react-icons/md";
import moment from "moment";
import * as utils from "../../../../utils/utils";
import { addHours, subHours } from "date-fns";

interface StatementParty {
  name: string;
  cpf: string | null;
  cnpj: string | null;
}

interface Statement {
  id: string;
  txid: string;
  endToEndId: string | null;
  qrCodeImage: string;
  textoImagemQRcode: string;
  value: number;
  infoPayer: string | null;
  status: string;
  createdAt: string;
  updatedAt: string | null;
  expiresAt: string | null;
  Debtor: StatementParty[];
  Payer: StatementParty[];
}

interface DetailsProps {
  item: Statement;
  onClose: () => void;
}

interface LabelValueProps extends StackProps {
  label: string;
  value: React.ReactNode;
}

function LabelValue({ label, value, ...rest }: LabelValueProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2} {...rest}>
      <Typography variant="subtitle2" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body2" fontWeight="bold">
        {value}
      </Typography>
    </Stack>
  );
}

export function Details({ item, onClose }: DetailsProps) {
  //const dataCorrigida = subHours(new Date(item.createdAt), 3);
  const dataCorrigida = new Date(item.createdAt);
  const formatDocument = (party: StatementParty): string => {
    if (party.cpf) {
      return party.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (party.cnpj) {
      return party.cnpj.replace(/\D/g, "").replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    } else {
      return "";
    }
  };

  return (
    <Box padding={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{item.status}</Typography>
        <IconButton onClick={onClose}>
          <MdClose />
        </IconButton>
      </Stack>
      <Typography variant="h6" color="textSecondary" mt={1} align="center" fontWeight="bold">
        {moment(dataCorrigida).format("DD MMM YYYY HH:mm:ss")}
      </Typography>

      {item.id && <LabelValue label="ID Cobrança" value={item.id} />}

      <LabelValue label="EndToEndID" value={item.endToEndId} />

      <LabelValue label="Valor" value={utils.toMoney(item.value)} />

      <LabelValue label="Status" value={item.status} />

      <Divider sx={{ mt: 3, mb: 2 }}>
        <Typography variant="subtitle1">Devedor</Typography>
      </Divider>

      {item.Debtor && item.Debtor[0] && (
        <>
          <LabelValue label="Nome" value={item.Debtor[0].name} />

          <LabelValue label="Documento" value={formatDocument(item.Debtor[0])} />
        </>
      )}

      <Divider sx={{ mt: 3, mb: 2 }}>
        <Typography variant="subtitle1">Pagador</Typography>
      </Divider>

      {item.Payer && item.Payer[0] && (
        <>
          <LabelValue label="Nome" value={item.Payer[0].name} />

          <LabelValue label="Documento" value={formatDocument(item.Payer[0])} mb={5} />
        </>
      )}
    </Box>
  );
}
