import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { ItemAccount } from "../../../../../components/ItemAccount";
import * as apiInterpays from '../../../../../services/apiInterpays';
import * as handleRequestError from '../../../../../utils/handleRequestError';
import { useSnackbar } from 'notistack';
import * as utils from '../../../../../utils/utils';
import { PulseLoader } from "react-spinners";
import { colors } from "../../../../../styles/global";

interface IProps {
    setAccountSelected: (account: any) => void;
}

export function AccountList(props: IProps) {
    const { enqueueSnackbar } = useSnackbar();
    const [searching, setSearching] = useState(true);
    const [accounts, setAccounts] = useState<any[]>([]);

    const handleSearchAccounts = () => {
        apiInterpays.API.get('/statements/accounts')
            .then(async ({ data }) => {
                if (data.length === 1)
                    props.setAccountSelected(data[0].account.id);
                else
                    setAccounts(data);
            }).catch(({ response }) => handleRequestError.handleError(response, enqueueSnackbar))
            .finally(() => {
                setSearching(false);
            });
    }

    useEffect(() => {
        handleSearchAccounts();
        //eslint-disable-next-line
    }, []);


    if (searching) {
        return (
            <Stack padding={2} sx={{ minHeight: 250 }} alignItems="center" justifyContent="center">
                <PulseLoader size={15} color={colors.turquoise} speedMultiplier={0.7} />
            </Stack>
        )
    } else {
        return (
            <Stack padding={2} spacing={3}>
                <h5>Escolha uma conta</h5>

                <Stack>
                    {
                        accounts.map(item => (
                            <ItemAccount
                                key={utils.randomString()}
                                name={item.account.name}
                                onClick={() => props.setAccountSelected(item.account)}
                                subtitle={`Agência: ${item.account.branch} | Conta: ${("000000000" + item.account.account.toString()).slice(-9)}`}
                            />
                        ))
                    }
                </Stack>

            </Stack>
        )
    }

}