import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { colors } from '../../../styles/global';

export interface IHead {
  name: string;
  label: string;
  align?: "left" | "right" | "inherit" | "center" | "justify";
  bgColor?: string;
  rowBgColor?: string;
  padding?: "none" | "normal" | "checkbox";
  width?: number;
  fontWeight?: 'bold' | 'normal';
}

interface IProps {
  numSelected: number,
  onRequestSort: (property: any, event: any) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  rowCount: number;
  headCells: Array<IHead>;
}

export function ThemeTableHead(props: IProps) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, indice) => (
          <TableCell
            key={`enhance${headCell.name}`}
            component="th"
            align={indice === 0 ? 'left' : 'center'}
            padding="normal"
            sx={{ minWidth: headCell.width ?? 0, background: headCell.bgColor, color: colors.textLight }}
            sortDirection={orderBy === headCell.name ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.name}
              direction={orderBy === headCell.name ? order : 'asc'}
              onClick={createSortHandler(headCell.name)}
            >
              <strong style={{ color: 'var(--text-color-light)' }}>
                {headCell.label}
              </strong>
              {orderBy === headCell.name ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}