import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    /* padding-top: 4rem; */
    justify-content: center;
    text-align: center;
    width: 100%;
    color: var(--text-light);
    height: 70vh;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span{
            font-size: 16px;
        }
    
        h2 {
            color: var(--black);
        }
        
        a{
            margin-top: 25px;
            text-decoration: none;
            padding: 10px 15px;
            font-weight: 500;
            cursor: pointer;
            border-radius: 6px;
            color: var(--primary);
            transition: background 0.2s;
            border: 0;

            &:hover{
                background: var(--primary-light);
            }
        }
    }

    img {
        width: 300px;
    }
`