import Cookie from 'universal-cookie';

export const cookies = new Cookie();

export const isAuthenticated = () => {
    const auth = cookies.get('auth');
    if (!auth) {
        return false;
    }
    return true;
};

export const getToken = () => {
    const auth = cookies.get('auth');
    return auth;
}

export const clearCookie = () => {
    cookies.remove('auth');
    return;
}