import { AppContent } from "../../../components/AppContent";
import { ContentPage } from "../../../components/ContentPage";
import { colors } from "../../../styles/global";
import { RiPagesLine } from 'react-icons/ri';

export function InternalPage404() {
    return (
        <AppContent>
            <ContentPage>
                <div style={{ maxWidth: 1350, width: '100%', justifyContent: 'center', display: 'flex', marginTop: 135 }}>
                    <RiPagesLine size={85} color={colors.colorShadow} />
                    <div className="display-flex flex-direction-column">
                        <strong style={{ fontSize: 45 }}>Ops!</strong>
                        <span style={{ fontSize: 18 }}>Página não encontrada</span>
                    </div>
                </div>
            </ContentPage>
        </AppContent>
    )
}