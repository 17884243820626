import React, { useEffect, useState } from "react";
import { Divider, Modal, Button, Box, TextField, Select, MenuItem, FormControl, InputLabel, Typography, IconButton, Avatar, SelectChangeEvent } from "@mui/material";
import { Close as CloseIcon, InsertDriveFile as FileIcon, BackupSharp as BackupSharpIcon } from "@mui/icons-material";

interface TicketForm {
  produto: string;
  titulo: string;
  descricao: string;
  anexos: File[];
}

interface CreateTicketModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: TicketForm) => void;
  ticketForm: TicketForm;
  setTicketForm: React.Dispatch<React.SetStateAction<TicketForm>>;
}

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
const MAX_FILES = 5;
const MAX_TITLE_LENGTH = 125;
const MAX_DESCRIPTION_LENGTH = 500;
const ALLOWED_FILE_TYPES = ["image/jpeg","image/jpg", "image/png", "application/pdf"];

const CreateTicketModal: React.FC<CreateTicketModalProps> = ({ open, onClose, onSubmit, ticketForm, setTicketForm }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [errors, setErrors] = useState({ produto: "", titulo: "", descricao: "", anexos: "" });
  const [previews, setPreviews] = useState<{ id: string; url: string }[]>([]);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!ticketForm.produto) {
      setTicketForm(prevForm => ({ ...prevForm, produto: "Gerenciador Web" }));
    }
  }, [ticketForm.produto, setTicketForm]);

  const validateForm = () => {
    let valid = true;
    let newErrors = { produto: "", titulo: "", descricao: "", anexos: "" };

    if (!ticketForm.produto) {
      newErrors.produto = "Produto é obrigatório.";
      valid = false;
    }
    if (!ticketForm.titulo || ticketForm.titulo.length < 5 || ticketForm.titulo.length > 125) {
      newErrors.titulo = `Título deve ter entre 5 e ${MAX_TITLE_LENGTH} caracteres.`;
      valid = false;
    }
    if (!ticketForm.descricao || ticketForm.descricao.length < 125 || ticketForm.descricao.length > 500) {
      newErrors.descricao = `Descrição deve ter entre 125 e ${MAX_DESCRIPTION_LENGTH} caracteres.`;
      valid = false;
    }
    if (ticketForm.anexos.some(file => file.size > MAX_FILE_SIZE)) {
      newErrors.anexos = "Arquivos devem ter no máximo 5 MB.";
      valid = false;
    }
    if (ticketForm.anexos.some(file => !ALLOWED_FILE_TYPES.includes(file.type))) {
      newErrors.anexos = "Apenas arquivos JPG, JPEG, PNG ou PDF são permitidos.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files).filter(file => ALLOWED_FILE_TYPES.includes(file.type) && file.size <= MAX_FILE_SIZE);
      if (ticketForm.anexos.length + newFiles.length > MAX_FILES) {
        setErrors(prevErrors => ({ ...prevErrors, anexos: "Você pode adicionar no máximo 5 arquivos." }));
        return;
      }

      setTicketForm(prevForm => ({
        ...prevForm,
        anexos: [...prevForm.anexos, ...newFiles],
      }));

      setPreviews(prevPreviews => [
        ...prevPreviews,
        ...newFiles.map(file => ({ id: file.name + file.size, url: URL.createObjectURL(file) })),
      ]);

      setErrors({ produto: "", titulo: "", descricao: "", anexos: "" });
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = ticketForm.anexos.filter((_, i) => i !== index);
    const removedFileId = ticketForm.anexos[index].name + ticketForm.anexos[index].size;

    setTicketForm(prevForm => ({ ...prevForm, anexos: newFiles }));
    setPreviews(prevPreviews => prevPreviews.filter(preview => preview.id !== removedFileId));
  };

  const handleInputChange = (event: React.ChangeEvent<{ name?: string; value: unknown }> | SelectChangeEvent<string>) => {
    const { name, value } = event.target as { name: string; value: string };
    setTicketForm(prevForm => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit(ticketForm);
      onClose();
    }
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    return () => {
      previews.forEach(preview => URL.revokeObjectURL(preview.url));
    };
  }, [previews]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: windowWidth <= 720 ? 0 : "50%",
          left: "50%",
          transform: windowWidth <= 720 ? "translateX(-50%)" : "translate(-50%, -50%)",
          width: windowWidth <= 720 ? "100vw" : 600,
          height: windowWidth <= 720 ? "100vh" : "auto",
          borderRadius: windowWidth <= 720 ? 0 : "8px",
          bgcolor: "background.paper",
          boxShadow: 24,
          overflowY: "auto",
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }} component="header">
          <Typography variant="h6">Criar Ticket</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box component="main" sx={{ mt: 2 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel shrink id="produto-label">Produto</InputLabel>
            <Select
              labelId="produto-label"
              id="produto"
              name="produto"
              value={ticketForm.produto}
              label="Produto"
              onChange={handleInputChange}
            >
              <MenuItem value="Gerenciador Web">Gerenciador Web</MenuItem>
              <MenuItem value="Api">Api</MenuItem>
            </Select>
            {errors.produto && <Typography color="error">{errors.produto}</Typography>}
          </FormControl>
          <TextField
            fullWidth
            label="Título"
            id="titulo"
            name="titulo"
            value={ticketForm.titulo}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
            error={!!errors.titulo}
            helperText={errors.titulo ? `${errors.titulo} ${ticketForm.titulo.length}/${MAX_TITLE_LENGTH}` : `${ticketForm.titulo.length}/${MAX_TITLE_LENGTH}`}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Descrição"
            id="descricao"
            name="descricao"
            value={ticketForm.descricao}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
            error={!!errors.descricao}
            helperText={errors.descricao ? `${errors.descricao} ${ticketForm.descricao.length}/${MAX_DESCRIPTION_LENGTH}` : `${ticketForm.descricao.length}/${MAX_DESCRIPTION_LENGTH}`}
            InputLabelProps={{ shrink: true }}
          />
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            Permitido jpg, jpeg, png e pdf. Máximo de 5 anexos com tamanho máximo de 5MB cada anexo.
          </Typography>

          <input ref={inputRef} accept={ALLOWED_FILE_TYPES.join(",")} style={{ display: "none" }} id="anexos" name="anexos" type="file" onChange={handleFileChange} multiple />
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", alignItems: "center", mb: 2 }}>
            {ticketForm.anexos.length < MAX_FILES && (
              <Box
                onClick={() => inputRef.current?.click()}
                sx={{
                  width: "80px",
                  height: "80px",
                  border: "1px dashed #ccc",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  borderRadius: "4px",
                }}
              >
                <BackupSharpIcon fontSize="large" />
              </Box>
            )}
            {previews.map((preview, index) => (
              <Box key={preview.id} sx={{ width: "80px", height: "80px", position: "relative", borderRadius: "4px", overflow: "hidden" }}>
                {ticketForm.anexos[index].type.startsWith("image") ? (
                  <Avatar src={preview.url} variant="square" sx={{ width: "100%", height: "100%" }} />
                ) : (
                  <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f0f0f0" }}>
                    <FileIcon fontSize="large" />
                  </Box>
                )}
                <IconButton onClick={() => handleRemoveFile(index)} sx={{ position: "absolute", top: 0, right: 0, bgcolor: "white" }}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Box>
          {errors.anexos && <Typography variant="subtitle2" color="error" sx={{ mb: 2 }}>{errors.anexos}</Typography>}
        </Box>
        <Divider />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }} component="footer">
          <Button variant="contained" onClick={handleSubmit} sx={{ backgroundColor: "#0e8ba4", color: "white", "&:hover": { backgroundColor: "#0d7e8b" } }}>
            Criar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateTicketModal;
