import * as auth from '../../auth';
import { useEffect } from 'react';
import * as apiInterpays from '../../services/apiInterpays';

export function Logout() {
    useEffect(() => {
        handleCleanDataAccess();
    }, []);

    const handleCleanDataAccess = async () => {
        await auth.clearCookie();
        await apiInterpays.removeInterceptorAuth();
        window.location.href = '/login';
    }

    return <></>;
}