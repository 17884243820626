import { useState } from "react";
import { Typography } from "@mui/material";
import { InitForgot } from "./InitiForgot";
import { FormPin } from "./FormPin";

import { Password } from "./Password";

interface IProps {
    onBack: () => void;
}

export function Forgot({ onBack }: IProps) {
    const [hash, setHash] = useState<string | null>(null);
    const [user, setUser] = useState<any | null>(null);
    const [pinValidated, setPinValidated] = useState(false);

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {
                !pinValidated && <Typography variant='h5' sx={{ fontSize: 28, color: 'black' }} marginTop={3} >Redefinir senha</Typography>
            }

            {
                !hash && <InitForgot onBack={onBack} setHash={(hash: string, user: { name: string }) => {
                    setHash(hash);
                    setUser(user);
                }} />
            }

            {
                hash && !pinValidated && <FormPin
                    hash={hash}
                    onValid={() => setPinValidated(true)}
                    onBack={() => setHash(null)} />
            }

            {
                hash && pinValidated && <Password onBack={onBack} hash={hash} user={user} />
            }


        </div>
    )
}