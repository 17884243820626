import { Container } from './styles';
import { IconType } from 'react-icons';
import { colors } from '../../styles/global';

interface IProps {
    label?: string;
    name?: string;
    value?: string;
    onChange?: (checked: boolean) => void;
    onClick?: () => void;
    checked?: boolean;
    icon?: IconType
}

export function CheckBoxThema(props: IProps) {
    // const [checked, setChecked] = useState(props.checked);
    const Icon = props.icon;

    return (
        <Container checked={props.checked} onClick={() => {
            props.onChange?.(!props.checked);
            // setChecked(!props.checked);
        }}>
            {Icon && <Icon size={15} height={15} width={15} color={props.checked ? colors.white : colors.textCleanLight} style={{ marginRight: 5 }} />}
            <label>{props.label}</label>
        </Container>
    )
}