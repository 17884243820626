import React from "react";
import { List, ListItem, ListItemText, Collapse, Button, CircularProgress, Typography, Switch, Grid } from "@mui/material";
import { MdExpandLess, MdExpandMore, MdAccountBalance } from "react-icons/md";

interface UsersConfListContainerProps {
  loading: boolean;
  data: any[];
  openIndex: number | null;
  handleToggle: (index: number) => void;
  handleWriteChange: (userId: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleBlockChange: (userId: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  setAccountId: React.Dispatch<React.SetStateAction<string>>;
  accountId: string;
}


const feeTypes = ["Fixo", "Fixo", "Volume", "Fixo"];

  const UsersConfListContainer: React.FC<UsersConfListContainerProps> = ({ loading, data, openIndex, handleToggle, handleWriteChange, handleBlockChange, setModalOpen, isModalOpen, accountId, setAccountId }) => {
  return (
    <List>
      {loading ? (
        <CircularProgress sx={{ display: "block", margin: "auto" }} />
      ) : data.length > 0 ? (
        data.map((account, index) => (
          <React.Fragment key={index}>
            <ListItem onClick={() => handleToggle(index)} sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <MdAccountBalance size={24}  color={"#0e8ba4"}/>
                </Grid>
                <Grid item xs={4}>
                  <ListItemText primary={account.name} />
                </Grid>
                <Grid item xs={3}>
                  <ListItemText primary={feeTypes[account.feeType]} />
                </Grid>
                <Grid item xs={3}>
                  <ListItemText primary={account.feeType === 0 ? "R$ " + account.feeValue.toFixed(2).replace(".", ",") : (account.feeValue * 100).toFixed(2) + "%"} />
                </Grid>
                <Grid item xs={1}>
                  {openIndex === index ? <MdExpandLess size={24}  color={"#0e8ba4"}/> : <MdExpandMore size={24}  color={"#0e8ba4"}/>}
                </Grid>
              </Grid>
            </ListItem>
            <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
              {account.ManagerAccountsUser.map((user: any) => (
                <div key={user.id} style={{ display: "flex", flexDirection: "column", borderBottom: "1px solid #ccc", padding: "8px 0" }}>
                  <Typography variant="body2">
                    <strong>Nome:</strong> {user.user.person.name}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Documento:</strong> {user.user.person.doc}
                  </Typography>
                  <Typography variant="body2">
                    <strong>E-mail:</strong> {user.user.person.Emails.length > 0 && user.user.person.Emails[0].address}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Telefone:</strong> {user.user.person.Phones.length > 0 && user.user.person.Phones[0].ddi}
                    {user.user.person.Phones.length > 0 && user.user.person.Phones[0].phone}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Transacionar:</strong>
                    <Switch checked={user.write} onChange={handleWriteChange(user.id)} color="primary" inputProps={{ "aria-label": "transacionar" }} />
                  </Typography>
                  <Typography variant="body2">
                    <strong>Bloquear:</strong>
                    <Switch checked={user.block} onChange={handleBlockChange(user.id)} color="primary" inputProps={{ "aria-label": "bloquear" }} />
                  </Typography>
                </div>
              ))}
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setAccountId(account.id);
                      setModalOpen(true);
                    }}
                    sx={{
                      marginTop: "16px",
                      backgroundColor: "#0e8ba4",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#0d7e8b",
                      },
                    }}
                  >
                    Adicionar usuário
                  </Button>
                </Grid>
              </Grid>
            </Collapse>
          </React.Fragment>
        ))
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
          Não há contas a exibir.
        </Typography>
      )}
    </List>
  );
};

export default UsersConfListContainer;
