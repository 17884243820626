import { Container } from "./style";
import video from "../../assets/videos/video.mp4";
import Landing from "../Landing";

export function Home() {
  return (
    <Container>
      <Landing />
    </Container>
  );
}
