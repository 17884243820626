import React from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import { styled } from "@mui/system";
import Logo from "../../../assets/logos/logoNavbar.png";

// Estilização personalizada para o AppBar usando MUI's styled API
const CustomAppBar = styled(AppBar)({
  backgroundColor: "white", // Fundo branco
  borderBottom: "0.1rem solid #00579f", // Borda inferior azul
  height: "70px",
  zIndex: 9999
});

const Navbar = () => {
  return (
    <CustomAppBar position="static">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "100%" }}>
        <Box component="img" src={Logo} alt="Logo" sx={{ height: "28px" }} />
        <Button
          variant="outlined"
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
            height: "35px",
            width: "75px",
            borderColor: "#00579f",
            color: "#00579f",
            backgroundColor: "white",
            '&:hover': {
              backgroundColor: "#00579f",
              color: "white",
            },
          }}
          href="/login"
        >
          Entrar
        </Button>
      </Toolbar>
    </CustomAppBar>
  );
};

export default Navbar;
