import React, { useEffect, useState } from "react";
import { Modal, Button, Box, TextField, Divider, InputAdornment, FormHelperText, CircularProgress, InputLabel, Typography, Stack } from "@mui/material";
import Mask from "vanilla-masker";
import { useJwt } from "react-jwt";
import { useSnackbar } from "notistack";
import * as auth from "../../../../auth";
import * as configSnack from "../../../../utils/configDefaultSnackbar";
import * as apiInterpays from "../../../../services/apiInterpays";
import * as handleRequestError from "../../../../utils/handleRequestError";
import profileImg from "../../../../assets/profile.png";
import { SuccessIcon } from "../../../../components/Icon/Success";
import LoadingGear from "../../../../components/LoadGear/LoadGear";

interface CreateDepositModalProps {
  open: boolean;
  onClose: () => void;
  accountDeposit: any;
  setShowModalDeposit: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeIDTimeout: (IDTimeoout: any) => void;
}

interface IJWT {
  decodedToken: any;
}

const CreateDepositModal: React.FC<CreateDepositModalProps> = ({ open, onClose, accountDeposit, onChangeIDTimeout, setShowModalDeposit }) => {
  const { decodedToken }: IJWT = useJwt(auth.getToken());
  const { enqueueSnackbar } = useSnackbar();
  const [qrcode, setQRCode] = useState(null);
  const [textQRCode, setTextQRCode] = useState("");
  const [concluded, setConcluded] = useState(false);
  const [showAlertCopy, setShowAlertCopy] = useState(false);
  const [creating, setCreating] = useState(false);
  const [value, setValue] = useState(0);
  const [errorValue, setErrorValue] = useState("");
  const [errorCob, setErrorCob] = useState<string | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [urlAvatar, setUrlAvatar] = useState(null);
  const [name, setName] = useState<string>("");
  const [payerDocument, setPayerDocument] = useState<string>("");
  const [infoPagador, setInfoPagador] = useState<string>("");

  const handleME = () => {
    if (decodedToken) {
      setName(decodedToken?.data.person.name);
      setPayerDocument(decodedToken?.data.doc);
      setUrlAvatar(decodedToken.data.avatar ? decodedToken.data.avatar : profileImg);
    }
  };

  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(textQRCode);
      enqueueSnackbar("Copia e Cola copiado com sucesso!", configSnack.success);
    } catch (error) {
      document.execCommand("copy", true, textQRCode);
      enqueueSnackbar("Copia e Cola copiado com sucesso!", configSnack.success);
    }

    setTimeout(() => {
      setShowAlertCopy(false);
    }, 4000);
  };

  const createQRCode = async () => {
    if (!value || value <= 0) {
      setErrorValue("Informe o valor do depósito.");
      return;
    }

    setErrorValue("");

    const options = {
      url: "/dashboard/deposit",
      method: "POST",
      data: {
        idAccount: accountDeposit.id,
        value,
        infoPagador,
      },
    };

    setCreating(true);

    await apiInterpays.API.request(options)
      .then(({ data }) => {
        setQRCode(data.qrCodeImage);
        setTextQRCode(data.textoImagemQRcode);
        const IDTimeout = setTimeout(() => {
          checkStatusQrCodeCob(data.id);
        }, 7000);
        onChangeIDTimeout(IDTimeout);
      })
      .catch(({ response }) => handleRequestError.handleError(response, enqueueSnackbar));
    setCreating(false);
  };

  const checkStatusQrCodeCob = async (id: string) => {
    const options = {
      method: "GET",
      url: `/dashboard/deposit/${id}`,
    };

    apiInterpays.API.request(options)
      .then(({ data }) => {
        switch (data.status) {
          case "COMPLETA":
            setConcluded(true);
            break;
          case "EXPIRADO":
            setErrorCob("QRCode expirado!");
            break;
          case "DEVOLVIDO":
            setErrorCob("Pix devolvido!");
            break;
          case "ATIVA":
            const IDTimeout = setTimeout(() => {
              checkStatusQrCodeCob(id);
            }, 7000);
            onChangeIDTimeout(IDTimeout);
            break;
        }
      })
      .catch(({ response }) => {
        enqueueSnackbar(response.data.message ?? "Erro ao checar seu deposito!", configSnack.warning);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleME();
  }, [decodedToken]);

  return (
    <Modal open={open} onClose={onClose}>
      {creating ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: window.innerWidth,
            height : window.innerHeight,
            borderRadius: "8px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            overflowX: "auto",
            overflowY: "auto",
            textAlign: "center",
          }}
        >
          <LoadingGear />
        </Box>
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: windowWidth < 600 ? "100vw" : 400,
            borderRadius: "8px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <Box flexGrow={1} mb={2} textAlign="center">
            <Typography variant="h5" mb={2}>
              <strong>Adicionar Saldo</strong>
            </Typography>
          </Box>
          <Divider />

          <Box flexGrow={1} mb={2}>
            <Box
              flexGrow={1}
              mb={2}
              textAlign="center"
              sx={{
                backgroundColor: "#f0f0f0",
                p: 2,
                borderRadius: "8px",
                marginTop: "16px",
              }}
            >
              {concluded ? (
                <Stack spacing={2} padding={2} sx={{ overflowY: "hidden" }} direction={{ xs: "row" }} justifyContent="center" alignItems="center">
                  <SuccessIcon /> <Typography variant="h6">Depósito concluído com sucesso!</Typography>
                </Stack>
              ) : qrcode === null ? (
                <>
                  <Typography variant="body1" mb={2}>
                    Informe o valor
                  </Typography>
                  <TextField
                    variant="standard"
                    id="amount"
                    name="amount"
                    placeholder="0,00"
                    onChange={(event) => {
                      setErrorValue("");
                      event.target.value = Mask.toMoney(event.target.value.replace(/\D/g, ""));
                      const newValue = parseFloat(event.target.value.replace(/\./, "").replace(",", "."));
                      setValue(newValue);
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                      disableUnderline: true,
                      sx: { fontSize: "32px", fontWeight: "bold", textAlign: "center" },
                    }}
                    sx={{ marginLeft: "100px", marginBottom: "8px" }}
                  />
                  <FormHelperText sx={{ textAlign: "center" }} error>
                    {errorValue && errorValue}
                  </FormHelperText>
                  <Typography variant="body2" mb={2}>
                    Saldo atual da conta <strong>R$ {accountDeposit.balance && Mask.toMoney(accountDeposit.balance.toString().replace(/\D/g, ""))}</strong>
                  </Typography>
                </>
              ) : (
                <img
                  src={`data:image/png;base64,${qrcode}`}
                  alt="qrcode"
                  style={{
                    width: 190,
                    height: 190,
                  }}
                />
              )}
            </Box>
            <Box flexGrow={1} mb={2}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2">
                  <strong>Beneficiário</strong>
                </Typography>
                <Divider style={{ flexGrow: 1, marginLeft: "8px" }} />
              </div>
              <Typography variant="body2">
                <strong>Nome:</strong> {accountDeposit.ownerName}
              </Typography>
              <Typography variant="body2">
                <strong>Documento:</strong> {!isNaN(accountDeposit.balance) ? (accountDeposit.ownerDoc.length <= 11 ? accountDeposit.ownerDoc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : accountDeposit.ownerDoc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")) : ""}
              </Typography>
              <Typography variant="body2">
                <strong>Conta:</strong> {accountDeposit.name}
              </Typography>
            </Box>
            <Box flexGrow={1} mb={2}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2">
                  <strong>Pagador</strong>
                </Typography>
                <Divider style={{ flexGrow: 1, marginLeft: "8px" }} />
              </div>
              <Typography variant="body2">
                <strong>Nome:</strong> {name}
              </Typography>
              <Typography variant="body2">
                <strong>Documento:</strong> {!isNaN(accountDeposit.balance) ? (payerDocument.length <= 11 ? payerDocument.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : payerDocument.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")) : ""}
              </Typography>
              <Typography variant="body2" style={{ marginTop: "8px" }}>
                <sup>*</sup>A conta pagadora deve ser da mesma titularidade do pagador ou o crédito será devolvido.
              </Typography>
            </Box>
            <Box flexGrow={1} mb={2}>
              <Typography variant="body1" mb={2}>
                <strong>Descrição (opcional)</strong>
              </Typography>
              {qrcode === null ? (
                <TextField
                  fullWidth
                  onChange={(event) => {
                    setInfoPagador(event.target.value);
                  }}
                  id="info"
                  name="info"
                  sx={{ mb: 2 }}
                />
              ) : (
                <Typography variant="body2" mb={2}>
                  {infoPagador}
                </Typography>
              )}
            </Box>
            <Divider />
          </Box>
          {qrcode === null ? (
            <Box display="flex" justifyContent="space-between" flexGrow={1} mb={2} width="100%">
              <Button
                variant="contained"
                onClick={() => {
                  setQRCode(null);
                  setConcluded(false);
                  setTextQRCode("");
                  setShowModalDeposit(false);
                }}
                sx={{
                  color: "#0e8ba4",
                  backgroundColor: "white",
                  border: "1px solid #0e8ba4",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                  width: "48%",
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={createQRCode}
                sx={{
                  backgroundColor: "#0e8ba4",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#0d7e8b",
                  },
                  width: "48%",
                }}
              >
                Continuar
              </Button>
            </Box>
          ) : !concluded ? (
            <Box display="flex" width="100%" sx={{ padding: 0 }}>
              <Button
                fullWidth
                onClick={() => handleCopyCode()}
                sx={{
                  backgroundColor: "#0e8ba4",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#0d7e8b",
                  },
                }}
              >
                Copiar QrCode
              </Button>
            </Box>
          ) : (
            <Box display="flex" width="100%" sx={{ padding: 0 }}>
              <Button
                fullWidth
                onClick={() => {
                  setQRCode(null);
                  setConcluded(false);
                  setTextQRCode("");
                  setShowModalDeposit(false);
                }}
                sx={{
                  backgroundColor: "#0e8ba4",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#0d7e8b",
                  },
                }}
              >
                Fechar
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Modal>
  );
};

export default CreateDepositModal;
