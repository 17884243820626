import styled from 'styled-components';

interface IProps {
    open: boolean;
}

export const Container = styled.div.attrs((props: IProps) => { })`
    position: fixed;
    top: 0;
    right: 0;
    width: ${props => props.open ? '295px' : '0'};
    height: 100%;
    z-index: 20;
    margin-top: 65px;
    transition: all 0.200s;
`;

export const Content = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    background: var(--white);
    height: 100%;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-top-left-radius: 0.25rem;
    width: 295px;
`

export const BackDrop = styled.div.attrs((props: IProps) => { })`
    display: ${props => props.open ? 'block' : 'none'};
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 19;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`