import { colors } from '../../styles/global';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    /* height: 100vh; */
`

export const Content = styled.div`
       margin-top: 68px; 
       min-height: calc(100vh - 82px - 50px - 68px);
       display: flex;
       flex-direction: column;
       justify-content: center;
       /* align-items: center; */
`

export const Card = styled.div`
        display: flex;
        flex-direction: column;
        border-radius: 35px;
        box-shadow: 0px 0px 40px -2px rgba(0,0,0, 0.3);
        padding: 1.25rem;
        width: 640px;
        min-height: 610px;
        align-items: center;
        margin-bottom: 100px;

        img {
            margin-top: 1.25rem;
            width: 30px;
            height: auto;
        }

        .form {
            margin-top: 1.5rem;
            /* margin-bottom: 3rem; */
            display: flex;
            gap:5px;
            flex-direction: column;
            /* min-width: 328px; */
            width: 328px;
            
        
            .button-next {
                padding: 5px;
                background-color: #fbfbfd;
                border-radius: 20px;
                color: ${colors.textLight};
                
                &:disabled {
                    border: 1px solid ${colors.textclean};
                    color: ${colors.textCleanLight};
                    cursor: text;
                }
            }
        }

        @media(max-width: 754px) {
            box-shadow: none;
            width: 100%;
        }

`

export const Footer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background: #f5f5f7;
    padding: 30px;
    min-height: 82px;

    .content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1035px;
        a { 
            /* margin-top: 10px; */
            color: ${colors.textLight};
            font-size: 11px;
            padding-rigth: 12px;
        }
    
        span {
            color: ${colors.textLight};
            font-size: 11px;
        }

        .separator {
            margin: 0 10px;
        }
    }

    @media(max-width: 754px) {
        .content{
            flex-direction:  column;
            /* align-items: center; */
            justify-content: center;
            max-width: 345px;
        }
    }
`

export const Header = styled.div`
    display: flex;
    top: 0;
    background: rgba(255,255,255, 0.98);
    position: sticky;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;
    padding: 10px 0 10px 15px;

    img {
        width: auto;
        height: 37px;
    }

    @media(max-width: 754px) {
        background: #f5f5f7;
    }
`

export const HelperTextError = styled.span`
        color: red;
        font-size: 12px;
        margin: 5px;
`