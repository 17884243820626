import { Accordion, AccordionDetails, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { MdArrowForwardIos } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { ContextMenu } from '../../../contexts/Contexts';
import { Container } from './styles';
import { IconType } from 'react-icons';

interface IChildren {
    iconMenu?: IconType;
    title?: string;
    path?: string;
}

interface IProps {
    menuSelected: string;
    name: string;
    onChange: (name: string) => void;
    title?: string;
    Icon: IconType;
    children?: Array<IChildren>;
    path: string;
}

const getStylePoppover = (x?: number, y?: number) => {
    const style: React.CSSProperties = {
        top: y,
        position: 'fixed',
        marginLeft: 25,
        padding: '0.8rem',
        background: 'var(--white)',
        boxShadow: '3px 3px 3px 0 rgba(0, 0, 0, 0.2)',
        visibility: 'visible',
        maxWidth: 350,
        minWidth: 230,
        marginTop: -20,
    };

    return style;
}

export function MenuItemCollapse(props: IProps) {
    const location = useLocation();
    const anyMenuItemActive = location.pathname.indexOf(`/${props.path}/`) > -1;
    const menuContext = useContext(ContextMenu);
    const expanded = (menuContext.menuIsOpen && props.name === props.menuSelected) as boolean;
    const [showPoppoverItemsMenu, setShowPoppoverItemMenu] = useState(false);
    const [stylePoppover, setStylePoppover] = useState<React.CSSProperties | any>(null);

    const handleExpanded = () => {
        const menuCollapseSelected = props.menuSelected;
        /**se menu já tiver aberto setar string vazia com menu selecionado */
        props.onChange(menuCollapseSelected === props.name ? '' : props.name);
    }

    const handleMouseHover = (mouseIn: boolean, posititionX?: number, posititionY?: number) => {
        if (!menuContext.menuIsOpen && mouseIn) {
            setStylePoppover(getStylePoppover(posititionX, posititionY));
            setShowPoppoverItemMenu(true);
        } else {
            setStylePoppover(null);
            setShowPoppoverItemMenu(false);
        }
    }

    return (
        <Container menuIsOpen={menuContext.menuIsOpen} onMouseEnter={(event: any) => { handleMouseHover(true, event.clientX, event.clientY) }} onMouseLeave={() => handleMouseHover(false)}>
            <Accordion expanded={expanded} sx={{ boxShadow: 'none' }}>
                <Link to="#" onClick={handleExpanded} className={anyMenuItemActive && !menuContext.menuIsOpen ? "active-item-menu" : ""}>
                    <div>
                        <props.Icon size={24} />
                        <span className={anyMenuItemActive ? 'text-bold' : ''}>{props.title}</span>
                    </div>
                    <div className={expanded ? 'rotate-90' : ''}>
                        <span>
                            <MdArrowForwardIos size={14} />
                        </span>
                    </div>
                </Link>

                <AccordionDetails>
                    <div style={stylePoppover} onMouseLeave={() => handleMouseHover(false)}>
                        {showPoppoverItemsMenu && (<Typography sx={{ p: 1 }}>{props.title}</Typography>)}
                        {
                            props.children?.map(item => {
                                const itemActive = location.pathname.indexOf(`${props.path}/${item.path}`) > -1;

                                return (
                                    <Link
                                        className={itemActive ? 'active-item-menu' : ''}
                                        key={`${item.title}-${Math.random().toString(36).slice(2)}`}
                                        to={`${props.path}/${item.path}`}>
                                        <div>
                                            {item?.iconMenu && (<item.iconMenu size={24} />)}
                                            <span className='title-submenu' style={{ marginLeft: 6 }}>{item.title}</span>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
        </Container>
    )
}