import { useLayoutEffect, useState } from 'react';
import * as auth from '../../auth';
import { useJwt } from 'react-jwt';
import * as enqueueNotification from '../../utils/handleEnqueueNotifications';
import * as apiInterpays from '../../services/apiInterpays';

interface IProps {
    children: JSX.Element | JSX.Element[]
}

interface IJWT {
    decodedToken: any;
}

export function Authenticate({ children }: IProps) {
    const { decodedToken }: IJWT = useJwt(auth.getToken());
    const [loadChildren, setLoadChildren] = useState(false);

    useLayoutEffect(() => {
        checkAccess();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    const checkAccess = () => {
        const expired = decodedToken ? decodedToken.exp < new Date().getTime() : false;

        if (!auth.isAuthenticated() || expired) {
            if (expired)
                enqueueNotification.handle('Sessão expírada, faça o login novamente');

            apiInterpays.setInterceptorAuth();
            window.location.href = '/logout';
        } else {
            setLoadChildren(true);
        }
    }

    return loadChildren ? <>{children}</> : <></>
}