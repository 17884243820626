import React from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, CircularProgress, Typography, Collapse, Grid, Button, Switch } from "@mui/material";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

interface UsersConfTableContainerProps {
  loading: boolean;
  data: any[];
  openIndex: number | null;
  handleToggle: (index: number) => void;
  handleWriteChange: (userId: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleBlockChange: (userId: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  page: number;
  rowsPerPage: number;
  totalContas: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const feeTypes = ["Fixo Valor", "Fixo Porcentagem", "Volume"];

const ApiConfTableContainer: React.FC<UsersConfTableContainerProps> = ({ loading, data, openIndex, handleToggle, handleWriteChange, handleBlockChange, page, rowsPerPage, totalContas, handleChangePage, handleChangeRowsPerPage }) => {
  return (
    <TableContainer sx={{ overflowX: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ whiteSpace: "nowrap" }}>Id</TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>Nome</TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>Tipo Tarifa</TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>Valor Tarifa</TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <CircularProgress sx={{ display: "block", margin: "auto" }} />
          ) : data.length > 0 ? (
            data.map((account, index) => (
              <React.Fragment key={index}>
                <TableRow onClick={() => handleToggle(index)} sx={{ cursor: "pointer" }}>
                  <TableCell style={{ whiteSpace: "nowrap" }}>{account.id}</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>{account.name}</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>{feeTypes[account.feeType]}</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>{account.feeValue}</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>{openIndex === index ? <MdExpandLess size={24} /> : <MdExpandMore size={24} />}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5}>
                    <Collapse in={openIndex === index} timeout="auto" unmountOnExit style={{ width: "100%" }}>
                      {account.ManagerAccountsUser.map((user: any) => (
                        <div key={user.id} style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #ccc", padding: "8px 0" }}>
                          <div>
                            <Typography variant="body2">
                              <strong>Nome:</strong> {user.user.person.name}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Documento:</strong> {user.user.person.doc}
                            </Typography>
                            <Typography variant="body2">
                              <strong>E-mail:</strong> {user.user.person.Emails.length > 0 && user.user.person.Emails[0].address}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Telefone:</strong> {user.user.person.Phones.length > 0 && user.user.person.Phones[0].ddi}
                              {user.user.person.Phones.length > 0 && user.user.person.Phones[0].phone}
                            </Typography>
                          </div>
                          <div>
                            <Typography variant="body2">
                              <strong>Transacionar:</strong>
                              <Switch checked={user.write} onChange={handleWriteChange(user.id)} color="primary" inputProps={{ "aria-label": "transacionar" }} />
                            </Typography>
                            <Typography variant="body2">
                              <strong>Bloquear:</strong>
                              <Switch checked={user.block} onChange={handleBlockChange(user.id)} color="primary" inputProps={{ "aria-label": "bloquear" }} />
                            </Typography>
                          </div>
                        </div>
                      ))}
                      <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                          <Button
                            variant="contained"
                            onClick={() => {
                              //setId(ticket.id);
                              //setModalReplyOpen(true);
                            }}
                            sx={{
                              marginTop: "16px",
                              backgroundColor: "#0e8ba4",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "#0d7e8b", // Cor de fundo no hover
                              },
                            }}
                          >
                            Adicionar usuário
                          </Button>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))
          ) : (
            <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
              Não há contas a exibir.
            </Typography>
          )}
        </TableBody>
      </Table>
      <TablePagination labelRowsPerPage="Linhas por página" labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`} rowsPerPageOptions={[5, 10, 25]} component="div" count={totalContas} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
    </TableContainer>
  );
};

export default ApiConfTableContainer;
