import { Container } from './style';
import video from '../../assets/videos/video.mp4';

export function CookiesPolicy() {
    return (
        <Container>
            <div style={{display:"flex", flexDirection:"column"}}>
                <div style={{display:"flex", flexDirection:"column"}}>
                    <video autoPlay muted loop id="video-home">
                        <source src={video} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                    <span className='privacy-title'>Termos e Condições</span>
                </div>
                <div className="containt-privacy">
                    <div style={{fontSize:"16px", color:"#666"}}>
                        <p>Esta plataforma, cujo nome é <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, é de propriedade e mantida por <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS SOLUÇÕES DE PAGAMENTOS LTDA' : 'ANNA BANK SOLUÇÕES DE PAGAMENTOS LTDA'}</strong> , pessoa jurídica de direito privado, inscrita no CNPJ sob o nº {window.location.hostname.indexOf('interpays.com.br') > -1 ? '47.766.168/0001-76' : '49.372.130/0001-44'}, com endereço na {window.location.hostname.indexOf('interpays.com.br') > -1 ? 'Rua Arthur Maia, 44, Maravista, Niteroi/RJ - CEP 24.342-076' : 'Avenida C255, 270, Nova Suiça, Goiânia/GO - CEP 74.280-010'}.</p>
                        <p>Esta política tem o objetivo de estabelecer e informar as regras de uso de cookies pelo <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>. Estas regras aplicam-se a todas as pessoas que acessam a plataforma do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, que utiliza cookies para seu funcionamento, sendo responsabilidade de todos ter ciência e aderir a esta política.</p>
                        <p>Nossa política de cookies está alinhada às boas práticas e legislações de proteção de dados – dentre elas o Marco Civil da Internet e a Lei Geral de Proteção de Dados Pessoais – baseando-se na preservação da intimidade, da vida privada, da honra e da imagem das partes direta ou indiretamente envolvidas.</p>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>1. Data de disponibilização do texto</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>O presente documento foi redigido e disponibilizado em 13/04/2023.</p>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>2. O que são Cookies</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>De acordo com o "Guia Orientativo: cookies e proteção de dados pessoais" da Autoridade Nacional de Proteção de Dados (ANPD), “cookies são arquivos instalados no dispositivo de um Usuário que permitem a coleta de determinadas informações, inclusive de dados pessoais em algumas situações, visando ao atendimento de finalidades diversas”.</li>
                                <li>A maioria dos navegadores da internet aceita cookies. Entretanto, os Usuários podem configurar seus navegadores para recusar certos tipos de cookies ou cookies específicos. Além disso, os Usuários podem apagar determinados cookies a qualquer momento.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>3. Entidade Responsável pela Gestão dos Cookies</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>Existem cookies próprios ou primários e cookies de terceiros. No caso da plataforma do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> são usados os cookies a seguir:</p>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li><strong>Cookies próprios ou primários:</strong> são os cookies definidos diretamente pelo <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>. Esses tipos de cookies incluem informações como credenciais de login, carrinho de compras ou idioma preferido.</li>
                                <li><strong>Cookies de terceiros:</strong> são cookies criados por um domínio diferente do  <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>. Decorrem de funcionalidades de outras empresas que são incorporadas na plataforma, a exemplo da exibição de anúncios.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>4. Categoria de Cookies</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>Há diversas categorias de cookies, contudo a plataforma do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> se restringirá às seguintes categorias: </p>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li><strong>Cookies analíticos ou de desempenho:</strong> possibilitam coletar dados e informações sobre como os Usuários utilizam a plataforma, quais páginas visitam com mais frequência, a ocorrência de erros ou informações sobre o próprio desempenho da plataforma ou da aplicação.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>5. Cookies Utilizados na Plataforma</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>Cookies analíticos ou de desempenho</p>
                            <div style={{marginTop:"5px"}}>
                                <table>
                                    <thead style={{lineHeight:"30px",border:"1px solid #cacaca"}}>
                                        <th><strong>Nome do cookie – Empresa terceira responsável</strong></th>
                                        <th style={{borderLeft:"1px solid #cacaca"}}><strong>Finalidade</strong></th>
                                        <th style={{borderLeft:"1px solid #cacaca"}}><strong>Duração</strong></th>
                                    </thead>
                                    <tbody>
                                        <tr style={{lineHeight:"30px",border:"1px solid #cacaca"}}>
                                            <td style={{padding:"10px"}}>reCAPTCHA v3 - Google</td>
                                            <td style={{borderLeft:"1px solid #cacaca", padding:"15px 35px"}}>
                                                É um cookie utilizado como ferramenta de segurança do Google que ajuda a proteger sites contra atividades fraudulentas ou spam, verificando se o usuário é um humano ou um robô. Esses tipos de cookies permitem que o site acompanhe como os usuários interagem com o conteúdo, o que pode ajudar a melhorar o desempenho do site e a experiência do usuário.
                                            </td>
                                            <td style={{borderLeft:"1px solid #cacaca", padding:"15px 35px"}}>
                                                01 (um) dia.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>6. Como os Cookies São Utilizados</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>Com exceção dos cookies necessários, todas as modalidades de cookies requerem o consentimento e aceite expresso do Usuário no ato da navegação da plataforma do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>. </li>
                                <li>O Usuário pode rejeitar ou aceitar o tratamento desses cookies realizado.  Ainda, são fornecidas informações sobre as finalidades específicas dos dados coletados. Sendo assim, o tratamento de cookies realizado nos navegadores dos Usuários ao acessarem a plataforma do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> nunca é realizado de forma equivocada, sem permissão e/ou ciência do Usuário.</li>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> usa cookies para fins de segurança, processando as informações que nos ajudam a proteger nossas páginas, bem como para detectar fraudes e abusos.</li>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> também utiliza cookies com fins de autenticação. Quando você entra na plataforma usando sua conta pessoal, armazenamos um número de ID exclusivo e a hora que você entrou, em um cookie criptografado em seu dispositivo. Esse cookie permite a você navegar pelas páginas da plataforma sem precisar se conectar novamente a cada página. Você também pode salvar as suas informações de entrada para que não precise conectar toda vez que retornar à plataforma.</li>
                                <li>Ainda, usamos cookies para reunir dados de desempenho e de uso. Por exemplo, para contar o número de visitantes exclusivos de uma página Web ou de um serviço e para desenvolver outras estatísticas sobre as operações de nossos produtos.</li>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> também usa cookies para entender e aprimorar o desempenho dos serviços. Por exemplo, usamos cookies para coletar dados que nos ajudem com o balanceamento de carga, o que nos permite manter a plataforma em funcionamento.</li>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> utiliza cookies para aprender como o Usuário interage com o seu conteúdo e para melhorar a experiência do Usuário ao visitar a plataforma. Por exemplo, alguns cookies lembram o idioma ou preferências para que o Usuário não tenha que efetuar essas escolhas repetidamente sempre que visitar a plataforma.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>7. Rejeições e Exclusão de Cookies</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>O Usuário pode gerenciar ou desabilitar os cookies nas configurações do seu navegador. No entanto, lembramos que, como alguns deles são necessários para possibilitar a utilização da nossa plataforma, ao desabilitar todos os cookies pode ser que o Usuário não consiga acessar integralmente a nossa plataforma.</li>
                                <li>O Usuário pode escolher rejeitar ou bloquear tipos específicos de cookies configurados por meio de sua visita a plataforma do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> ao clicar nas preferências de cookies.</li>
                                <li>É importante observar que a maioria dos navegadores aceita cookies automaticamente. Portanto, se o Usuário não quiser que cookies sejam utilizados, talvez tenha que apagar ou bloquear cookies ativamente. Se o Usuário rejeitar a utilização de cookies, poderá visitar a plataforma do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, mas algumas das funções poderão não funcionar corretamente.</li>
                                <li>Os cookies utilizados para manter os registros de acesso a aplicações de internet, ou seja, o conjunto de informações referentes à data e hora de uso de uma determinada aplicação de internet a partir de um determinado endereço IP, não poderão ser excluídos pelo prazo de 6 (seis) meses a partir da navegação, pois a sua manutenção trata-se de uma obrigação legal, imposta pelo artigo 15 do Marco Civil da Internet (MCI) e pelo artigo 16, inciso I da Lei Geral de Proteção de Dados Pessoais (LGPD).</li>
                                <li>Após o período em que os dados pessoais precisam permanecer armazenados, esses serão excluídos de nossas bases de dados ou anonimizados, respeitando as hipóteses legalmente previstas no art. 16 da LGPD. Isso é, informações pessoais que sejam necessárias para o cumprimento de determinações legais, judiciais e administrativas e/ou para o exercício do direito de defesa em processos judiciais e administrativos serão mantidas, apesar da exclusão dos demais dados.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>8. Compartilhamento de Dados</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>A qualquer momento, o Usuário poderá revogar seu consentimento quanto aos cookies, exceto os cookies necessários, apagando os cookies da plataforma do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> por meio das configurações de seu navegador de preferência. Para mais informações sobre como proceder em relação à gestão dos cookies, verifiquem as regras dos navegadores: </p>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li><a href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Internet Explorer </a></li>
                                <li><a href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#:~:text=o%20navegador%20Edge.-,Excluir%20todos%20os%20cookies,Limpar%20dados%20de%20navega%C3%A7%C3%A3o%20agora">Microsoft Edge </a></li>
                                <li><a href="https://support.mozilla.org/pt-BR/kb/protecao-aprimorada-contra-rastreamento-firefox-desktop?redirectslug=ative-e-desative-os-cookies-que-os-sites-usam&redirectlocale=pt-BR">Mozilla Firefox</a></li>
                                <li><a href="https://support.google.com/chrome/answer/95647?hl=pt-BR&co=GENIE.Platform%3DDesktop">Google Chrome</a></li>
                                <li><a href="https://support.apple.com/pt-br/HT201265">Safari</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>9. Transferência Internacional de Dados </h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>Atualmente, o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> possui servidores localizados no Brasil e nos Estados Unidos. Alguns de seus dados pessoais, ou todos eles, poderão ser transferidos para o exterior, por exemplo quando são armazenados em servidores de computação em nuvem localizados fora do Brasil. Para isso, o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> observa todos os requerimentos estabelecidos pela legislação vigente e adota as melhores práticas de segurança e privacidade para garantir a integridade e confidencialidade dos seus dados pessoais.</p>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>10. Canal de Comunicação Sobre Privacidade</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> disponibiliza o seguinte canal para comunicação sobre qualquer assunto envolvendo dados pessoais: contato@<strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'interpays.com.br' : 'annabank.com.br'}</strong>.</p>
                        </div>
                    </div>
                    <div className='box-privacy' id="privacy-alert">
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>11. Legislação e Foro</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>Esta Política será regida, interpretada e executada de acordo com as Leis da República Federativa do Brasil, especialmente a Lei nº 13.709/2018 e a Lei nº 12.965/2014, independentemente das Leis de outros estados ou Países, sendo competente o foro de Goiânia, Estado do Goiás, Brasil, para dirimir qualquer dúvida decorrente deste documento.</p>                     
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}