import { Container } from './style';
import video from '../../assets/videos/video.mp4';

export function Notice() {
    return (
        <Container>
            <div style={{display:"flex", flexDirection:"column"}}>
                <div style={{display:"flex", flexDirection:"column"}}>
                    <video autoPlay muted loop id="video-home">
                        <source src={video} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                    <span className='privacy-title'>Alerta de privacidade</span>
                </div>
                <div className="containt-privacy">
                    <div style={{fontSize:"16px", color:"#666"}}>
                        <p>Antes de acessar a plataforma <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> é importante que você leia, entenda e aceite de forma livre, inequívoca e informada este Aviso de Privacidade.</p>
                        <p>Esta plataforma, cujo nome é <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, é de propriedade e mantida por <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS SOLUÇÕES DE PAGAMENTOS LTDA' : 'ANNA BANK SOLUÇÕES DE PAGAMENTOS LTDA'}</strong> , pessoa jurídica de direito privado, inscrita no CNPJ sob o nº {window.location.hostname.indexOf('interpays.com.br') > -1 ? '47.766.168/0001-76' : '49.372.130/0001-44'}, com endereço na {window.location.hostname.indexOf('interpays.com.br') > -1 ? 'Rua Arthur Maia, 44, Maravista, Niteroi/RJ - CEP 24.342-076' : 'Avenida C255, 270, Nova Suiça, Goiânia/GO - CEP 74.280-010'}.</p>
                        <p>O presente documento visa prestar informações sobre a coleta, utilização e armazenamento ("tratamento") dos dados fornecidos pelos Usuários e está em conformidade com a Lei n. 12.965/2014 (Marco Civil da Internet) e com a Lei n. 13.709/18 (Lei Geral de Proteção de Dados).</p>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>1. Data de disponibilização do texto</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>O presente documento foi redigido e disponibilizado em 13/04/2023.</p>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>2. Explicação dos termos técnicos ou em língua estrangeira</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>Abaixo estão dispostos os significados das nomenclaturas técnicas e termos na língua inglesa</p>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>Controlador: pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais</li>
                                <li><i>Cookies</i>: pequenos arquivos de texto que ficam gravados no computador do internauta e podem ser recuperados pelo site que o enviou durante a navegação. São utilizados principalmente para identificar e armazenar informações sobre os Usuários.</li>
                                <li>Criptografia: conjunto de princípios e técnicas para cifrar a escrita, torná-la ininteligível para os que não tenham acesso às convenções combinadas.</li>
                                <li>Dado pessoal: informação relacionada a pessoa natural identificada ou identificável.</li>
                                <li>Dado pessoal sensível: dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.</li>
                                <li>Encarregado: pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).</li>
                                <li>IP (ou Internet Protocol): Identificação única para cada computador conectado a uma rede.</li>
                                <li>Operador: pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador.</li>
                                <li>Tratamento de dados: toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</li>
                            </ul>
                            <p>São os tipos de Usuários da plataforma:</p>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>Usuários: Pessoas físicas ou jurídicas que se cadastram na plataforma para utilização dos serviços disponíveis.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>3. Agentes de Tratamento</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> é controlador do tratamento dos dados pessoais referentes aos Usuários, ou seja, uma vez que o Usuário entra em nossa plataforma ou preenche formulários, coletaremos algumas informações e tomaremos decisões, a fim de prestar o nosso serviço da melhor e mais segura forma possível.</li>
                                <li>
                                    Além disso, a Nome e DADOS DA IF ou IP, é também uma controladora singular dos dados, uma vez que realiza o tratamento das informações do Usuário, tomando decisões para suas finalidades específicas de prestar serviços financeiros.
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>A partir do momento que essa Instituição obtém dados dos Usuários para realizar operações financeiras, se tornará responsável pelas finalidades dadas às informações, sua segurança, tratamento e compartilhamento adequado, não podendo fazer o tratamento em desconformidade com a legislação vigente, sob pena de responder por todas as punições, em especial as de natureza cível, criminal e as aplicadas pela Autoridade Nacional de Proteção de Dados.</li>
                                    </ul>
                                </li>
                                <li>Somos operadores do tratamento dos dados pessoais de terceiros inseridos em nossa solução pelos Usuários, ou seja, realizamos o tratamento de dados com base nas decisões de nossos clientes, controladores.</li>
                                <li>
                                    Alguns Suboperadores são necessários para que a plataforma esteja disponível, como servidores, plataformas de hospedagem, ferramentas de marketing entre outros. Com isso, essas empresas passam a ter acesso aos seus dados. Contudo, nossas contratações sempre prezam pelo tratamento seguro das suas informações.
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>Nossas contratações sempre prezam pelo tratamento seguro das informações do Usuário. Por isso, a partir do momento que essas empresas tiverem acesso a esses dados, se tornarão responsáveis pela segurança, tratamento e compartilhamento adequado dessas informações, não podendo divulgá-las para outras finalidades, em desconformidade com a legislação vigente ou com este Aviso de Privacidade, sob pena de responderem por todas as punições, em especial as de natureza cível, criminal e as aplicadas pela Autoridade Nacional de Proteção de Dados. </li>
                                    </ul>
                                </li>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> recomenda que o Usuário leia os termos de uso e avisos de privacidade de todas as empresas terceiras aqui listadas, uma vez que este documento trata exclusivamente das operações de tratamento de dados realizadas pelo <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>.</li>
                                <li>Transferência internacional: os servidores utilizados pelo <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, que são munidos de mecanismos aptos a assegurar a segurança de seus dados, estão localizados fora do Brasil, mais precisamente, nos Estados Unidos e são utilizados para que o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> possa executar o seu serviço de forma adequada, conforme determina o artigo 33, IX, da Lei n. 13.709/18.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>4. Segurança da Informação</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> se preocupa muito com a segurança de seus dados pessoais. Por isso, implementa todas as medidas sugeridas pela Autoridade Nacional de Proteção de Dados (ANPD) em seu Guia Orientativo para Agentes de Tratamento de Pequeno Porte, o que compreende uma variedade de tecnologias e procedimentos de segurança para ajudar a proteger as suas informações.</li>
                                <li>
                                    <ul>
                                        <li>Além disso, possuímos medidas e controles eficazes para evitar ou reduzir riscos de Segurança da Informação, com uma abordagem voltada para os princípios da matéria, a fim de prevenir, de detectar, de responder e de se recuperar rapidamente de uma ameaça para proteger a confidencialidade, integridade e disponibilidade dos ativos tecnológicos e informações.</li>
                                    </ul>
                                </li>
                                <li>Todos os registros de acesso, conjunto de informações referentes à data e hora de uso de uma determinada aplicação de internet a partir de um determinado endereço IP, serão mantidos pelo <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, sob sigilo, em ambiente controlado e de segurança, pelo prazo mínimo de 06 (seis) meses, nos termos da Lei n. 12.965/2014, e artigo 7º, II, da Lei n. 13.709/18.</li>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> tem o compromisso de preservar a estabilidade, segurança e funcionalidade da plataforma, por meio de medidas técnicas compatíveis com os padrões internacionais e pelo estímulo ao uso de boas práticas. Todavia, nenhum serviço disponível na internet possui total garantia contra invasões ilegais. Em casos em que terceiros não autorizados invadam o sistema de forma ilícita, o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> diligenciará da melhor forma para encontrar o responsável pela atuação ilícita, mas não se responsabiliza pelos danos por eles causados.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>5. Coleta de Dados</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>Para o cadastro e utilização dos serviços do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> serão requeridas inicialmente as seguintes informações: Nome ou Razão Social, CPF ou CNPJ, E-mail, Telefone e Endereço. Nos casos de pessoas jurídicas, serão solicitadas cópias do contrato social e dos documentos pessoais dos sócios. Ainda serão solicitadas informações das cobranças geradas pelos clientes.</li>
                                <li><span style={{textDecoration:"underline"}}>Histórico de contato:</span> O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> armazena informações a respeito de todas as interações já realizadas entre os Usuários em seus canais de comunicação, pois isso irá melhorar o seu atendimento, torná-lo mais eficiente e trazer segurança para as partes.</li>
                                <li><span style={{textDecoration:"underline"}}>Informações que coletamos de forma indireta:</span>além dos registros de acesso, podemos também coletar algumas informações de forma indireta, conforme as nossas Políticas de Cookies.</li>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> tem o compromisso de preservar a estabilidade, segurança e funcionalidade da plataforma, por meio de medidas técnicas compatíveis com os padrões internacionais e pelo estímulo ao uso de boas práticas. Todavia, nenhum serviço disponível na internet possui total garantia contra invasões ilegais. Em casos em que terceiros não autorizados invadam o sistema de forma ilícita, o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> diligenciará da melhor forma para encontrar o responsável pela atuação ilícita, mas não se responsabiliza pelos danos por eles causados.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>6. Tratamento de Dados Pessoais</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>Ao aceitar o presente Aviso de Privacidade, o Usuário compreende que a coleta e tratamento dos dados pessoais abaixo são necessários para a execução do contrato com o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, conforme informações apresentadas a seguir.</p>
                            <div style={{marginTop:"5px"}}>
                                <table>
                                    <thead style={{lineHeight:"30px",border:"1px solid #cacaca"}}>
                                        <th><strong>Tipo de Dados Pessoal</strong></th>
                                        <th style={{borderLeft:"1px solid #cacaca"}}><strong>Base Legal</strong></th>
                                        <th style={{borderLeft:"1px solid #cacaca"}}><strong>Finalidade</strong></th>
                                    </thead>
                                    <tbody>
                                        <tr style={{lineHeight:"30px",border:"1px solid #cacaca"}}>
                                            <td style={{padding:"10px"}}><strong>Nome / Razão Social</strong></td>
                                            <td style={{borderLeft:"1px solid #cacaca", padding:"15px 35px"}}>
                                                <ul>
                                                    <li>Necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados (Art. 7º, V, Lei nº 13.709/2018).</li>
                                                    <li>Cumprimento de obrigação legal ou regulatória pelo controlador (Art. 7º, II, Lei nº 13.709/2018).</li>
                                                    <li>Necessário para o cumprimento do legítimo interesse do controlador. (Art. 7, IX, Lei nº 13.709/2018).</li>
                                                </ul>
                                            </td>
                                            <td style={{borderLeft:"1px solid #cacaca", padding:"15px 35px"}}>
                                                <ul>
                                                    <li>Utilizado para identificação e verificação de identidade do Usuário. Trata-se de dado pessoal essencial para que seja possível o Usuário abrir a conta digital, realizar as transações, bem como entrar em contato com o Usuário para atender às suas solicitações e fornecer respostas direcionadas.</li>
                                                    <li>Usado para emissão de notas fiscais de serviços pagos do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>.</li>
                                                    <li>Usado para evitar fraudes.</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr style={{lineHeight:"30px",border:"1px solid #cacaca"}}>
                                            <td style={{padding:"10px"}}><strong>CPF / CNPJ</strong></td>
                                            <td style={{borderLeft:"1px solid #cacaca",  padding:"15px 35px"}}>
                                                <ul>
                                                    <li>Necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados (Art. 7º, V, Lei nº 13.709/2018).</li>
                                                    <li>Cumprimento de obrigação legal ou regulatória pelo controlador (Art. 7º, II, Lei nº 13.709/2018).</li>
                                                    <li>Necessário para o cumprimento do legítimo interesse do controlador. (Art. 7, IX, Lei nº 13.709/2018).</li>
                                                </ul>
                                            </td>
                                            <td style={{borderLeft:"1px solid #cacaca",  padding:"15px 35px"}}>
                                                <ul>
                                                    <li>Utilizado para identificação e verificação de identidade do Usuário. Trata-se de dado pessoal essencial para que seja possível o Usuário abrir a conta digital e fazer operações financeiras.</li>
                                                    <li>Usado para emissão de notas fiscais de serviços pagos do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>.</li>
                                                    <li>Usado para evitar fraudes.</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr style={{lineHeight:"30px",border:"1px solid #cacaca"}}>
                                            <td style={{padding:"10px"}}><strong>E-mail e Telefone</strong></td>
                                            <td style={{borderLeft:"1px solid #cacaca",  padding:"15px 35px"}}>
                                                <ul>
                                                    <li>Necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados (Art. 7º, V, Lei nº 13.709/2018).</li>
                                                </ul>
                                            </td>
                                            <td style={{borderLeft:"1px solid #cacaca",  padding:"15px 35px"}}>
                                                <ul>
                                                    <li>No caso do e-mail é utilizado para login e validação de identidade na plataforma, bem como entrar em contato com o Usuário para atender às suas solicitações e fornecer respostas direcionadas (e-mail e telefone).</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr style={{lineHeight:"30px",border:"1px solid #cacaca"}}>
                                            <td style={{padding:"10px"}}><strong>Endereço</strong></td>
                                            <td style={{borderLeft:"1px solid #cacaca",  padding:"15px 35px"}}>
                                                <ul>
                                                    <li>Necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados (Art. 7º, V, Lei nº 13.709/2018).</li>
                                                    <li>Cumprimento de obrigação legal ou regulatória pelo controlador (Art. 7º, II, Lei nº 13.709/2018).</li>
                                                </ul>
                                            </td>
                                            <td style={{borderLeft:"1px solid #cacaca",  padding:"15px 35px"}}>
                                                <ul>
                                                    <li>Utilizado para identificação e verificação de identidade do Usuário.</li>
                                                    <li>Usado para emissão de notas fiscais de serviços pagos do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>.</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr style={{lineHeight:"30px",border:"1px solid #cacaca"}}>
                                            <td style={{padding:"10px"}}><strong>Cópias do Contrato Social e dos Documentos Pessoais dos Sócios (no caso de Usuários Pessoas Jurídicas)</strong></td>
                                            <td style={{borderLeft:"1px solid #cacaca",  padding:"15px 35px"}}>
                                                <ul>
                                                    <li>Necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados (Art. 7º, V, Lei nº 13.709/2018).</li>
                                                    <li>Necessário para o cumprimento do legítimo interesse do controlador. (Art. 7, IX, Lei nº 13.709/2018).</li>
                                                </ul>
                                            </td>
                                            <td style={{borderLeft:"1px solid #cacaca",  padding:"15px 35px"}}>
                                                <ul>
                                                    <li>Utilizado para identificação e verificação de identidade do Usuário Pessoa Jurídica, assim como da regularidade dos seus representantes legais. Esses documentos poderão ser solicitados pela Instituição Parceira para as operações financeiras.</li>
                                                    <li>Usado para evitar fraudes.</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr style={{lineHeight:"30px",border:"1px solid #cacaca"}}>
                                            <td style={{padding:"10px"}}><strong>IP (Internet Protocol), data, hora de acesso.</strong></td>
                                            <td style={{borderLeft:"1px solid #cacaca",  padding:"15px 35px"}}>
                                                <ul>
                                                    <li>Cumprimento de obrigação legal ou regulatória pelo controlador (Art. 7º, II, Lei nº 13.709/2018).</li>
                                                </ul>
                                            </td>
                                            <td style={{borderLeft:"1px solid #cacaca",  padding:"15px 35px"}}>
                                                <ul>
                                                    <li>Obediência ao artigo 15 da Lei n. 12.965/2014, que impõe o dever do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> de manter os respectivos registros de acesso a aplicações de internet, sob sigilo, em ambiente controlado e de segurança, pelo prazo de 6 (seis) meses.</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>7. Cancelamento da Plataforma, de Contas de Acesso e Exclusão de Dados</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>Cancelamento de contas de acesso pelo <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>: o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> poderá, a seu exclusivo critério, bloquear, restringir, desabilitar ou impedir o acesso de qualquer Usuário na plataforma sempre que for detectada uma conduta inadequada.</li>
                                <li>Cancelamento de contas de acesso pelo Usuário: para cancelar os serviços e requisitar a exclusão da conta de acesso no <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, o Usuário deverá acessar a funcionalidade <strong>Meus Dados | Encerrar Conta</strong></li>
                                <li><strong style={{textDecoration:"underline"}}>Quando finda a finalidade de tratamento de dados ou diante de solicitação pelo e-mail contato@{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'interpays.com.br' : 'annabank.com.br'}, o Usuário terá todos os seus dados deletados imediatamente e permanentemente, exceto os dados cuja manutenção seja obrigatória por lei ou regulação, os dados necessários para o exercício regular de direitos em processo judicial, administrativo ou arbitral, como os registros de acesso, que serão mantidos, sob sigilo, em ambiente controlado e de segurança, nos termos da Lei n. 12.965/2014 e com a base legal do art. 7, II, da Lei n. 13.709/18.</strong></li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>8. Direito do Titular dos Dados Pessoais</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>O titular de dados pessoais tem direito a obter do controlador, em relação aos dados por ele tratados, a qualquer momento e mediante requisição:</p>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>Confirmação da existência de tratamento de dados.</li>
                                <li>Acesso aos dados.</li>
                                <li>Correção de dados incompletos, inexatos ou desatualizados.</li>
                                <li>Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei n. 13.709/2018.</li>
                                <li>Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial. </li>
                                <li>Eliminação dos dados tratados com o consentimento do titular, exceto nas hipóteses previstas na Lei n. 13.709/2018. </li>
                                <li>Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados.</li>
                                <li>Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa.</li>
                                <li>Revogação do consentimento.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>9. Mudança do Aviso de Privacidade</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> poderá unilateralmente adicionar e/ou modificar qualquer cláusula contida neste Aviso de Privacidade. A versão atualizada valerá para o uso da plataforma realizada a partir de sua publicação. A continuidade de acesso ou utilização da plataforma, depois da divulgação, confirmará a vigência do nosso Aviso de Privacidade pelos Usuários. </li>
                                <li>Caso a mudança efetuada necessite de consentimento do Usuário, será apresentada a opção de aceitar de forma livre, inequívoca e informada o novo texto ou recusá-lo.</li>
                                <li>Caso o Usuário não esteja de acordo com a alteração, poderá não fornecer consentimento para atos específicos ou poderá rescindir totalmente seu vínculo com  o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>. Essa rescisão não eximirá, no entanto, o Usuário de cumprir com todas as obrigações assumidas sob as versões precedentes do Aviso de Privacidade.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>10. Canal de Comunicação Sobre Privacidade e Disposições Gerais</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> informa que, por ser agente de tratamento de pequeno porte, está dispensado da nomeação de um encarregado pelo tratamento de dados pessoais, nos termos da Resolução CD/ANPD nº 2 de 27 de janeiro de 2022.</li>
                                <li>Todavia, ressaltamos que a sua privacidade continua sendo nossa prioridade, por isso disponibilizamos o seguinte canal para comunicação sobre qualquer assunto envolvendo dados pessoais: contato@{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'interpays.com.br' : 'annabank.com.br'}.</li>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> dispõe de um texto específico para regular a licença de uso, os direitos, deveres, garantias e disposições gerais: os Termos de Uso. Todos esses documentos integram inseparavelmente este Aviso de Privacidade.</li>
                            </ul>                      
                        </div>
                    </div>
                    <div className='box-privacy' id="privacy-alert">
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>11. Canal de Comunicação Sobre Privacidade e Disposições Gerais</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>O Anna Bank disponibiliza o seguinte canal para receber todas as comunicações que os Usuários desejarem fazer: por meio do e-mail contato@{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'interpays.com.br' : 'annabank.com.br'}.</li>
                            </ul>                      
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}