import { useEffect, useState } from 'react';
import { Menu } from '../../components/Menu';
import { Container, Content, DesingContainerBlue } from './styles';
import { Header } from '../../components/Header';
import { ContextMenu } from '../../contexts/Contexts';
import { Outlet } from 'react-router-dom';
import { useJwt } from 'react-jwt';
import * as auth from '../../auth';
import profileImg from '../../assets/profile.png';

interface IJWT {
    decodedToken: any;
}

export function AppLayout() {
    const { decodedToken }: IJWT = useJwt(auth.getToken());
    const [menuIsOpen, setMenuIsOpen] = useState(window.innerWidth > 950);
    const [name, setName] = useState<string>('');
    const [levelAccess, setLevelAccess] = useState<string>('');
    const [urlAvatar, setUrlAvatar] = useState(null);
    const [id, setID] = useState(null);

    const handleME = () => {
        if (decodedToken) {
            setName(decodedToken?.data.person.name);
            setLevelAccess(decodedToken.data.userType);
            setID(decodedToken.data.id);
            setUrlAvatar(decodedToken.data.avatar ? decodedToken.data.avatar : profileImg);
        }
    }

    const handleToggleMenu = () => {
        setMenuIsOpen(!menuIsOpen);
    }

    useEffect(() => {
        handleME();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [decodedToken])

    return (
        <Container>
            <ContextMenu.Provider value={{ menuIsOpen, setMenuIsOpen, handleToggleMenu }}>
                <Menu idUser={id} name={name} urlAvatar={urlAvatar} levelAccess={levelAccess} />

                <Content menuIsOpen={menuIsOpen}>
                    <Header name={"Adm Teste"} urlAvatar={null} levelAccess={'Admin'} />
                    <DesingContainerBlue />
                    <div className='content-outlet'>
                        <Outlet />
                    </div>
                </Content>
            </ContextMenu.Provider>
        </Container>
    )
}