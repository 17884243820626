import { useRef, useState } from 'react';
// material
import { Menu, IconButton } from '@mui/material';
import { MdMoreVert } from 'react-icons/md';

interface IProps {
    options?: Array<any>
    iconSize?: number
}

export function MoreOptions({ options, iconSize }: IProps) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    if (options) {
        return (
            <>
                <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                    <MdMoreVert size={iconSize ?? 18} />
                </IconButton>

                <Menu
                    open={isOpen}
                    anchorEl={ref.current}
                    onClose={() => setIsOpen(false)}
                    onClick={() => setIsOpen(false)}
                    PaperProps={{
                        sx: { minWidth: 200, maxWidth: '100%' }
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    {options}
                </Menu>
            </>
        );
    } else {
        return <></>
    }
}
