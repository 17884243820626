import React, { useState, useEffect } from 'react';
import { GlobalStyle } from "./styles/global";
import { BrowserRouter } from 'react-router-dom';
import { Routes } from "./routes";
import "rsuite/dist/rsuite.min.css";
import LoadingGear from './components/LoadGear/LoadGear';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadInitialData = async () => {
      await new Promise(resolve => setTimeout(resolve, 2000));
      setIsLoading(false);
    };

    loadInitialData();
  }, []);

  if (isLoading) {
    return <LoadingGear />;
  }

  return (
    <div>
      <GlobalStyle />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </div>
  );
}

export default App;
