import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 1.5rem;
`;

export const ListLinks = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  a,
  span {
    color: var(--white);
    margin: 0 1px;
    font-size: 0.8rem !important;
    text-decoration: none;
  }
`;

export const ContentLeft = styled.div`
  color: var(--white);

  div {
    display: flex;
    align-items: center;

    h3 {
      margin-left: 8px;
    }
  }
`;

export const ContentRight = styled.div`
  display: flex;
  flex-direction: row; /* Define os itens na horizontal */
  gap: 8px; /* Espaçamento entre os botões */
  align-items: center; /* Alinha os itens verticalmente no centro */
`;
