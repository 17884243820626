import { useEffect, useState } from "react";
import { Breadcrumps } from "../../../../components/Breadcrumbs";
import { AppContent } from "../../../../components/AppContent";
import { FilterContent } from "../../../../components/FilterContent";
import { Button } from "../../../../components/Button";
import { MdClose, MdSearch } from "react-icons/md";
import { Autocomplete, Card, Grid, MenuItem, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { RiFileList3Line } from "react-icons/ri";
import { IoCopyOutline } from 'react-icons/io5';
import { ThemeTable } from "../../../../components/ThemeTable";
import * as apiInterpays from '../../../../services/apiInterpays';
import * as handleRequestError from '../../../../utils/handleRequestError';
import moment from 'moment';
import * as utils from '../../../../utils/utils';
import * as configSnack from '../../../../utils/configDefaultSnackbar';
import { DateRangePicker } from "rsuite";
import { LOCALE_DATEPICKER } from "../../../../constants";
import { MoreOptions } from "../../../../components/MoreOptions";
import Mask from 'vanilla-masker';

const TABLE_HEAD = [
    { name: 'name', label: 'Nome' },
    { name: 'doc', label: 'CPF', alignRight: false },
    { name: 'email', label: 'E-mail', alignRight: false },
    { name: 'phone', label: 'Telefone', alignRight: false },
    { name: 'quantity', label: 'Quant. itens', alignRight: false },
    { name: 'items', label: 'Items', alignRight: false, width: 250 },
    { name: 'dueDate', label: 'Vencimento', alignRight: false },
    { name: 'value', label: 'Valor', alignRight: false },
    { name: 'status', label: 'Status', alignRight: false },
    { name: 'options', label: '', alignRight: false },
];

export function ListInvoices() {
    const { enqueueSnackbar } = useSnackbar();
    const [showFilters, setShowFilters] = useState(false);
    const [countPagination, setCountPagination] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searching, setSearching] = useState(false);
    const [data, setData] = useState<any[]>([]);

    const [accountFilter, setAccountFilter] = useState("");
    const [intervalFilter, setIntervalFilter] = useState<[Date, Date]>([new Date(), new Date()]);
    const [accountsOptions, setAccountsOptions] = useState<any[]>([]);

    const handleToggleFilters = () => setShowFilters(!showFilters);

    const handleDataFromTable = async (data: any[]) => {
        const newDate: any[] = []

        await data.forEach(async item => {
            const options = [
                <MenuItem key={utils.randomString()} onClick={() => {
                    navigator.clipboard.writeText(`${window.location.origin}/payment/${item.id}`);
                }}>
                    <IoCopyOutline size={18} /> <span className="margin-left-1">Copiar link</span>
                </MenuItem>
            ];

            let items = <div className="display-flex flex-direction-column">
                {
                    item.itemOnInvoice.map((itemInvoice: any) => (
                        <span key={utils.randomString()}>{itemInvoice.itemInvoice.description}</span>
                    ))

                }
            </div>;

            newDate.push({
                name: item.name,
                doc: item.doc,
                email: item.email,
                phone: Mask.toPattern(item.phone ?? '', '(99) 9 9999-9999'),
                quantity: item.itemOnInvoice.length,
                items: items,
                dueDate: moment(item.expiresAt).format('DD/MM/YYYY HH:mm:ss'),
                value: utils.toMoney(item.value),
                status: item.dynamicCob.status,
                options: <MoreOptions options={options} />
            })
        });
        setData(newDate);
        setSearching(false);
    }

    const handleSearchAccounts = async () => {
        await apiInterpays.API.get('/statements/accounts')
            .then(async ({ data }) => {
                const options: any[] = [];
                await data.forEach((item: any) => {
                    options.push({
                        value: item.account.id,
                        label: `${item.account.name} - ${("000000" + item.account.account.toString()).slice(-7)}`
                    })
                });
                setAccountsOptions(options);
            }).catch(({ response }) => handleRequestError.handleError(response, enqueueSnackbar));
    }

    const searchInvoices = async () => {
        if (!accountFilter) {
            enqueueSnackbar("Selecione um conta para a busca", configSnack.warning);
            return;
        }

        setSearching(true);

        const filters: string[] = [];

        filters.push(`idAccount=${accountFilter}`);
        filters.push(`currentPage=${(currentPage - 1)}`);
        filters.push(`startDate=${moment(intervalFilter[0]).format('YYYY-MM-DDT00:00:00.000')}Z`);
        filters.push(`endDate=${moment(intervalFilter[1]).format('YYYY-MM-DDT23:59:59.000')}Z`);


        apiInterpays.API.get(`/invoices?${filters.join('&')}`)
            .then(({ data }) => {
                handleDataFromTable(data.invoices);
                setCountPagination(data.totalPage)
            }).catch(({ response }) => handleRequestError.handleError(response, enqueueSnackbar))
    }

    useEffect(() => {
        // searchInvoices();
        handleSearchAccounts();

        //eslint-disable-next-line
    }, []);

    return (
        <AppContent>
            <Breadcrumps
                Icon={RiFileList3Line}
                title="Listar"
                links={[
                    { name: 'Listar', href: '/app/faturas/listar' }
                ]}
                onClickButtonFilter={handleToggleFilters}
            />

            <FilterContent open={showFilters} onClose={handleToggleFilters} >
                <div>
                    <Button color="borderless" onClick={handleToggleFilters}>
                        <MdClose size={20} />
                    </Button>
                </div>
                <DateRangePicker
                    defaultValue={intervalFilter}
                    showOneCalendar
                    size='lg'
                    style={{ width: '100%' }}
                    menuStyle={{ zIndex: 200 }}
                    placement='bottomEnd'
                    className='mt-4'
                    locale={LOCALE_DATEPICKER}
                    onChange={value => setIntervalFilter(value as [Date, Date])}
                    placeholder="Periodo" />

                <Autocomplete
                    disablePortal
                    fullWidth
                    options={accountsOptions}
                    size="small"
                    sx={{ marginTop: 2 }}
                    onChange={(event, value) => setAccountFilter(value?.value ?? null)}
                    renderInput={(params) => <TextField
                        {...params}
                        label="Conta"
                    />}
                />

                <div className="display-flex justify-content-end w-100 margin-top1">
                    <Button type="button" color="turquoise" load={searching} typeLoad="pacman" onClick={searchInvoices}>
                        <MdSearch size={20} style={{ marginLeft: 2 }} />
                    </Button>
                </div>
            </FilterContent>

            <Grid container justifyContent="center" marginTop={-2} padding={1}>
                <Grid xs={12} sm={12} lg={12} item>
                    <Card sx={{ p: 2, mt: 3, width: '100%' }}>
                        <ThemeTable
                            data={data}
                            countPagination={countPagination}
                            loading={searching}
                            headeCells={TABLE_HEAD}
                            initOrderBy="name"
                            changePage={setCurrentPage}
                        // onChangeTextSearch={text => setTextSearch(text)}
                        />
                    </Card>
                </Grid>
            </Grid>
        </AppContent >
    )
}