import React from "react";
import { ListItem, ListItemText, Typography, Paper, Box, Link } from "@mui/material";
import { PiFileJpgLight, PiFilePdfLight, PiFilePngLight } from "react-icons/pi";

type MessageBubbleProps = {
  position: "left" | "right";
  author: string;
  text: string;
  date: Date;
  anexos: string[];
};

const MessageBubble: React.FC<MessageBubbleProps> = ({ anexos, author, position, text, date }) => {
  return (
    <ListItem>
      <Box
        component={Paper}
        sx={{
          padding: 2,
          borderRadius: 4,
          maxWidth : "90%",
          marginLeft: position === "right" ? "auto" : 0,
          marginRight: position === "left" ? "auto" : 0,
          backgroundColor: position === "right" ? "#6ED6A0" : "#E1E2F5",
        }}
      >
        <ListItemText
          primary={
            <>
              <Typography variant="body2" sx={{ marginBottom: "5px", color: position === "right" ? "#FFFFFF" : "#000000" }}>
                <strong>{author}:</strong>
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px", cfontWeight: 600, color: position === "right" ? "#FFFFFF" : "#000000" }}>
                {text}
              </Typography>
              {anexos.length > 0 && (
                <Typography variant="body2" sx={{ marginBottom: "5px", cfontWeight: 600, color: position === "right" ? "#FFFFFF" : "#000000" }}>
                {anexos.length > 0 && <strong>{"Anexos : "}:</strong>}
                {anexos.map((anexo: string, index: number) => (
                  <React.Fragment key={anexo}>
                    {index > 0 && " "} {/* Adiciona um espaço entre os links */}
                    <Link href={anexo} target="_blank" rel="noopener noreferrer">
                      {anexo.endsWith(".pdf") ? <PiFilePdfLight size={24} /> : anexo.endsWith(".png") ? <PiFilePngLight size={24} /> : <PiFileJpgLight size={24} />}
                    </Link>
                  </React.Fragment>
                ))}
              </Typography>
              )}
            </>
          }
          secondary={
            <Typography variant="body2" sx={{ fontStyle: "italic", color: position === "right" ? "#FFFFFF" : "#000000" }}>
              {date.toLocaleString()}
            </Typography>
          }
        />
      </Box>
    </ListItem>
  );
};

export default MessageBubble;
