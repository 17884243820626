import { HTMLAttributes } from "react";
import { Container } from "./style";

interface IProps extends HTMLAttributes<HTMLDivElement> {
    children?: JSX.Element | JSX.Element[];
    display?: 'flex' | 'block' | string;
    flexDirection?: 'row' | 'column';
    justifyContent?: 'center' | 'space-between' | 'end' | 'start' | string;
    alignItems?: 'center' | 'end' | 'start' | string;
    padding?: string;
    paddingTop?: string;
    paddingBottom?: string;
    paddingLeft?: string;
    paddingRight?: string;
    margin?: string;
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
}

export function AppContent(props: IProps) {
    return (
        <Container {...props}>
            {props.children}
        </Container>
    )
}