import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { AppContent } from "../../../../components/AppContent";
import { Breadcrumps } from "../../../../components/Breadcrumbs";
import { FaUserCog } from "react-icons/fa";
import * as apiInterpays from "../../../../services/apiInterpays";
import * as handleRequestError from "../../../../utils/handleRequestError";
import { Card, Grid, Button } from "@mui/material";
import ApiConfTableContainer from "./ApiConfTableContainer";

export function ApiConf() {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<any[]>([]);
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalContas, setTotalContas] = useState(4);
  const [cardHeight, setCardHeight] = useState(calculateCardHeight());
  const [cardWidth, setCardWidth] = useState(document.body.clientWidth || window.innerHeight || document.documentElement.clientHeight);

  const cardRef = useRef<HTMLDivElement | null>(null);

  function calculateCardHeight() {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    return windowHeight - 165;
  }

  const calculateCardWidth = () => {
    if (cardRef.current) {
      const cardWidth = cardRef.current.getBoundingClientRect().width;
      return cardWidth;
    }
    return document.body.clientWidth || window.innerHeight || document.documentElement.clientHeight;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggle = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleWriteChange = (userId: string) => async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setData((prevData) =>
      prevData.map((account) => ({
        ...account,
        ManagerAccountsUser: account.ManagerAccountsUser.map((user: any) => (user.id === userId ? { ...user, write: checked } : user)),
      }))
    );
    await apiInterpays.API.put(`/v2/configuracoes/usuarios/${userId}/${checked}`);
  };

  const handleBlockChange = (userId: string) => async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setData((prevData) =>
      prevData.map((account) => ({
        ...account,
        ManagerAccountsUser: account.ManagerAccountsUser.map((user: any) => (user.id === userId ? { ...user, block: checked } : user)),
      }))
    );
    await apiInterpays.API.patch(`/v2/configuracoes/usuarios/${userId}/${checked}`);
  };

  const handleData = useCallback(async () => {
    try {
      const response = await apiInterpays.API.get(`/v2/configuracoes/usuarios`);
      setData(response.data);
      setLoading(false);
    } catch (error: any) {
      const responseError = error.response;
      if (responseError && responseError.data && responseError.data.message) {
        handleRequestError.handleError(responseError, enqueueSnackbar);
      } else {
        console.error("Erro ao processar a resposta da API:", error);
        enqueueSnackbar("Ocorreu um erro ao processar a resposta da API", { variant: "error" });
      }
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    function handleResize() {
      setCardHeight(calculateCardHeight());
      setCardWidth(calculateCardWidth());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      handleData();
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, [handleData]);

  return (
    <AppContent>
      <Breadcrumps
        Icon={FaUserCog}
        title="Configurações de contas"
        links={[
          { name: "Configurações", href: "#" },
          { name: "Contas", href: "/app/configuracoes/contas" },
        ]}
      />
      <Grid container justifyContent="center" marginTop={-4} padding={1}>
        <Grid xs={12} sm={12} lg={12} item>
          <Card ref={cardRef} sx={{ p: 2, mt: 3, width: "100%", height: `${cardHeight}px`, overflowX: "auto", overflowY: "auto" }}>
            <Grid container justifyContent="flex-end" item>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#0e8ba4",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#0d7e8b",
                  },
                }}
              >
                Criar Conta
              </Button>
            </Grid>
            <ApiConfTableContainer loading={loading} data={data} openIndex={openIndex} handleToggle={handleToggle} handleWriteChange={handleWriteChange} handleBlockChange={handleBlockChange} page={page} rowsPerPage={rowsPerPage} totalContas={totalContas} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
          </Card>
        </Grid>
      </Grid>
    </AppContent>
  );
}
