import { Button } from '../Button';
import { Container } from './style';
import { Movimentation } from './Movimentation';
import { PacmanLoader } from 'react-spinners';
import { colors } from '../../styles/global';
import * as utils from '../../utils/utils';
import { SlRefresh } from 'react-icons/sl';

interface IProps {
    load?: boolean;
    onRefresh: () => void;
    data: Array<{
        name: string;
        value: number;
        account: string;
        data: Date;
    }>
}

export function LastTransactions(props: IProps) {
    return (
        <Container>
            <div className="display-flex justify-content-space-between align-items-center">
                <div>
                    <h5>Atividade recente</h5>
                    <span className="subtitle">Controle todas as transações, consulte seu saldo e extrato quando quiser</span>
                </div>
                <Button type="button" onClick={() => props.onRefresh()} color='borderless' load={props.load}>
                    <SlRefresh size={18} color={colors.turquoise} />
                </Button>
            </div>
            <div id="transactions">
                {
                    props.load && (
                        <div className="display-flex justify-content-center align-items-center margin-top3 margin-bottom2">
                            <span className='margin-right1'>Carregando </span> <PacmanLoader size={10} color={colors.turquoise} speedMultiplier={1} />
                        </div>
                    )
                }

                {
                    !props.load && props.data.map(item => (
                        <Movimentation
                            key={utils.randomString()}
                            name={item.name}
                            value={item.value}
                            account={item.account}
                            data={item.data}
                        />
                    ))
                }

                {
                    !props.load && props.data.length === 0 && (
                        <div className="display-flex justify-content-center margin-top3 margin-bottom2">
                            <span className='subtitle'>Nenhum registro encontrado</span>
                        </div>
                    )
                }
            </div>
            <div className="display-flex justify-content-end">
                <Button color='transparent' type='link' to='/app/extrato' ><strong>Ver extrato</strong></Button>
            </div>
        </Container>
    )
}