import React, { useEffect, useState } from "react";
import { AppContent } from "../../../components/AppContent";
import { Breadcrumps } from "../../../components/Breadcrumbs";
import { FaLaptopCode } from "react-icons/fa6";
import { Card, Grid, Typography, Button } from "@mui/material";


export function Redirect() {
  const [cardHeight, setCardHeight] = useState(calculateCardHeight());

  function calculateCardHeight() {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    return windowHeight - 165;
  }

  useEffect(() => {
    function handleResize() {
      setCardHeight(calculateCardHeight());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <AppContent>
      <Breadcrumps Icon={FaLaptopCode} title="Devlab < />" links={[{ name: "Devlab < />", href: "/app/devlab" }]} />
      <Grid container justifyContent="center" marginTop={-4} padding={1}>
        <Grid xs={12} sm={12} lg={12} item>
          <Card sx={{ p: 2, mt: 3, textAlign: "center", height: `${cardHeight}px` }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }}>
              <Typography variant="h6">Para acessar nossa documentação</Typography>
              <Typography variant="body1" mt={2}>
                Clique no botão abaixo
              </Typography>
              <Button
                sx={{
                  marginTop: "16px",
                  backgroundColor: "#0e8ba4",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#0d7e8b", // Cor de fundo no hover
                  },
                }}
                variant="contained"
                onClick={() => window.open("https://developers.annabank.com.br/", "_blank")}
              >
                Acesse nossa documentação
              </Button>
            </div>
          </Card>
        </Grid>
      </Grid>
    </AppContent>
  );
}
