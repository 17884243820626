import { Container } from "./style";
import { GiReceiveMoney, GiPayMoney } from "react-icons/gi";
import { colors } from "../../../styles/global";
import * as utils from '../../../utils/utils';
import { formatDistanceToNow, subHours } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

interface IProps {
    name: string;
    value: number;
    data: Date;
    account: string;
}

export function Movimentation(props: IProps) {
    const dataReduzida = new Date(props.data); //subHours(new Date(props.data), 2);

    return (
        <Container>
            {props.value >= 0 ? (<GiReceiveMoney size={22} color={colors.success} />) : (<GiPayMoney size={22} color={colors.red} />)}
            <div id="content">
                <strong>{props.name.toUpperCase()}</strong>
                <span style={{ fontSize: 12 }}>{props.account}</span>
                <span>
                    <strong>{utils.toMoney(props.value)}</strong>
                    <span className="margin-left2">{formatDistanceToNow(dataReduzida, { addSuffix: true, includeSeconds: true, locale: ptBR })}</span>
                </span>
            </div>
        </Container>
    )
}
