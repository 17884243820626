import { Stack, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import * as configSnack from '../../../utils/configDefaultSnackbar';
import { Button } from '../../../components/Button';
import { Link } from 'react-router-dom';
import { Input, InputGroup } from 'rsuite';
import { useState } from 'react';
import { HelperTextError } from '../style';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
// import { AxiosRequestConfig } from 'axios';
import * as api from '../../../services/apiInterpays';

const schemaForm = Yup.object({
    password: Yup.string()
        .test('validatePassword', 'Senha precisa ter pela menos uma letra maiuscula, um números e um caracter especial.', (value: any) => {
            let validate = true;

            const regexText = /[A-Za-z]/;
            const regextNumber = /[0-9]/;
            const regexCharacteres = /\W|_/;

            if (!regexText.test(value) || !regextNumber.test(value) || !regexCharacteres.test(value))
                validate = false;

            return validate;
        })
        .min(8, 'Senha deve conter pelo menos 8 caracteres')
        .required('informe uma senha'),
    confirmPassword: Yup.string()
        .test('checkPassword', 'Senhas não conferem.', (value: any, context) => {
            const password = context.parent.password;

            return value === password;
        })
        .min(8, 'Senha deve conter pelo menos 8 caracteres')
        .required('informe a senha e confirmação'),
})

interface IRegister {
    id: string;
    type: string,
    doc: string,
    name: string,
    birthdate: Date | null,
    phone: string,
    term: boolean,
    companyName: string,
    email: string
    step: number,
    fileFrontDOCPF: string,
    fileVersusDOCPF: string,
    fileCIPJ: string,
    fileCSPJ: string,
}

interface IProps {
    data: IRegister;
    setOnData: (data: IRegister) => void;
}

export function FormPassword({ data, setOnData }: IProps) {
    const { enqueueSnackbar } = useSnackbar();
    const [showPassword, setShowPassword] = useState(false);

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: schemaForm,
        onSubmit: async (values) => {
            try {
                await handleSave(values);
                setOnData({
                    ...data,
                    step: 5
                });
            } catch (error: any) {
                const message = error.data?.response.message ?? 'Ocorreu um erro ao fazer sua requisição';
                enqueueSnackbar(message, configSnack.warning);
            }
        }
    });

    const handleSave = async (values: any) => {
        const options = {
            method: 'PUT',
            url: '/account-password',
            data: {
                idUser: data.id,
                password: values.password
            }
        }

        const response = await api.API.request(options);

        return response.data;
    }

    const { values, touched, errors, isSubmitting, setFieldValue } = formik;

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='h5' sx={{ fontSize: 28, color: 'black' }} marginTop={3} >Última etapa</Typography>
            <Typography variant='body2' width={450} align='center' marginTop={2}>Defina sua senha para ter acesso ao nosso sistema.</Typography>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" className="form">
                    <Stack spacing={2}>
                        <div>
                            <InputGroup >
                                <Input
                                    placeholder="Senha"
                                    value={values.password}
                                    size='lg'
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={text => {
                                        setFieldValue('password', text);
                                    }}

                                />

                                <InputGroup.Button onClick={() => setShowPassword(!showPassword)}>
                                    {
                                        showPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />
                                    }
                                </InputGroup.Button>
                            </InputGroup>
                            {errors.password && touched.password && (<HelperTextError>{errors.password}</HelperTextError>)}
                        </div>

                        <div>
                            <InputGroup >
                                <Input
                                    placeholder="Digite a senha novamente"
                                    value={values.confirmPassword}
                                    size='lg'
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={text => {
                                        setFieldValue('confirmPassword', text);
                                    }}
                                />
                                <InputGroup.Button onClick={() => setShowPassword(!showPassword)}>
                                    {
                                        showPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />
                                    }
                                </InputGroup.Button>
                            </InputGroup>
                            {errors.confirmPassword && touched.confirmPassword && (<HelperTextError>{errors.confirmPassword}</HelperTextError>)}
                        </div>

                    </Stack>

                    <Button type='submit' color='turquoise' typeLoad='clock' load={isSubmitting} className='margin-top2' style={{ height: 36, alignItems: 'center', fontSize: '16px' }}>
                        Continuar
                    </Button>
                </Form>
            </FormikProvider>
            <div className='flex-direction-column display-flex text-align-center margin-top2 margin-bottom2'>
                <Link to="/login" style={{ fontSize: '14px', margin: '25px 0' }}>Iniciar Sessão</Link>
                {/* <Link to="#" style={{ marginTop: 10 }}>Criar uma conta</Link> */}
            </div>
        </div>
    )
}