// TicketTableContainer.tsx
import React from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, CircularProgress, Typography, Collapse, List, Grid, Button } from "@mui/material";
import { MdExpandLess, MdExpandMore, MdOutlineSupportAgent } from "react-icons/md";
import MessageBubble from "./MessageBubble";
import { v4 as uuidv4 } from "uuid";

interface TicketTableContainerProps {
  loading: boolean;
  tickets: any[];
  openIndex: number | null;
  handleToggle: (index: number) => void;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEncerrarTicket: (ticket: any) => void;
  setId: React.Dispatch<React.SetStateAction<string>>;
  setModalReplyOpen: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  rowsPerPage: number;
  totalTickets: number;
}

const TicketTableContainer: React.FC<TicketTableContainerProps> = ({ loading, tickets, openIndex, handleToggle, handleChangePage, handleChangeRowsPerPage, handleEncerrarTicket, setId, setModalReplyOpen, page, rowsPerPage, totalTickets }) => {
  return (
    <TableContainer sx={{ overflowX: "auto", overflowY: "auto" }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell style={{ whiteSpace: "nowrap" }}>
              <strong>Produto</strong>
            </TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
              <strong>Título</strong>
            </TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
              <strong>Status</strong>
            </TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
              <strong>Criado em</strong>
            </TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
              <strong>Criado por</strong>
            </TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
              <strong>Atualizado em</strong>
            </TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
              <strong>Atualizado por</strong>
            </TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <React.Fragment key={uuidv4()}>
              <TableRow>
                <TableCell colSpan={7} style={{ whiteSpace: "nowrap", verticalAlign: "middle", textAlign: "center" }}>
                  <CircularProgress sx={{ display: "block", margin: "auto" }} />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ) : tickets.length > 0 ? (
            tickets.map((ticket, index) => (
              <React.Fragment key={index}>
                <TableRow onClick={() => handleToggle(index)} sx={{ cursor: "pointer" }} hover>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    <MdOutlineSupportAgent color={"#0e8ba4"} size={24} style={{ marginRight: "10px" }} />
                    {ticket.produto}
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>{ticket.titulo.length > 30 ? ticket.titulo.substring(0, 30) + "..." : ticket.titulo}</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>{ticket.status}</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>{new Date(ticket.createdAt).toLocaleString()}</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    {ticket.person.name.split(" ")[0]} {ticket.person.name.split(" ")[1]}
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>{ticket.updatedAt ? new Date(ticket.updatedAt).toLocaleString() : "-"}</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>{ticket.respostas.length > 0 && ticket.respostas[ticket.respostas.length - 1].person.name.split(" ")[0] + " " + ticket.respostas[ticket.respostas.length - 1].person.name.split(" ")[1]}</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>{openIndex === index ? <MdExpandLess size={24} color={"#0e8ba4"} /> : <MdExpandMore size={24} color={"#0e8ba4"} />}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={8}>
                    <Collapse in={openIndex === index} timeout="auto" unmountOnExit style={{ width: "100%" }}>
                      <List>
                        <MessageBubble position="left" anexos={ticket.anexos} author={ticket.person.name} text={ticket.descricao} date={new Date(ticket.createdAt)} />
                        <List>
                          {ticket.respostas.map((resposta: any, index: number) => (
                            <MessageBubble key={index} position="right" anexos={resposta.anexos} author={resposta.person.name} text={resposta.texto} date={new Date(resposta.createdAt)} />
                          ))}
                        </List>
                      </List>
                      {ticket.status !== "Fechado" && (
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item>
                            <Button
                              variant="contained"
                              onClick={() => {
                                setId(ticket.id);
                                setModalReplyOpen(true);
                              }}
                              sx={{
                                backgroundColor: "#0e8ba4",
                                color: "white",
                                "&:hover": {
                                  backgroundColor: "#0d7e8b", // Cor de fundo no hover
                                },
                              }}
                            >
                              Responder
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              onClick={() => {
                                handleEncerrarTicket(ticket);
                              }}
                              sx={{
                                backgroundColor: "#0e8ba4",
                                color: "white",
                                "&:hover": {
                                  backgroundColor: "#0d7e8b", // Cor de fundo no hover
                                },
                              }}
                            >
                              Encerrar Ticket
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))
          ) : (
            <React.Fragment key={uuidv4()}>
              <TableRow>
                <TableCell colSpan={7} style={{ whiteSpace: "nowrap", verticalAlign: "middle", textAlign: "center" }}>
                  <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
                    Sem chamados abertos.
                  </Typography>
                </TableCell>
              </TableRow>
            </React.Fragment>
          )}
        </TableBody>
      </Table>
      <TablePagination labelRowsPerPage="Linhas por página" labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`} rowsPerPageOptions={[5, 10, 25]} component="div" count={totalTickets} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
    </TableContainer>
  );
};

export default TicketTableContainer;
