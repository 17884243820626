import { Container } from "./style";
import video from "../../assets/videos/video.mp4";

export function Privacy() {
  return (
    <Container>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <video autoPlay muted loop id="video-home">
            <source src={video} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <span className="privacy-title">Política de privacidade</span>
        </div>
        <div className="containt-privacy">
          <h5 style={{ color: "#4b4e5c" }}>O QUE FAZEMOS COM AS SUAS INFORMAÇÕES</h5>
          <div style={{ fontSize: "16px", color: "#666" }}>
            <p>
              Fizemos o máximo para explicar de forma clara e simples quais dados pessoais precisaremos de você e o que vamos fazer com cada um deles. Por isso, separamos abaixo os pontos mais importantes, que também podem ser lidos de forma bem completa e detalhada no nosso{" "}
              <strong>
                <a href="#privacy-alert">Aviso de Privacidade.</a>
              </strong>
            </p>
            <p>
              Além disso, estamos sempre disponíveis para tirar qualquer dúvida que você tenha por meio do e-mail contato@{window.location.hostname}. Para falar especificamente sobre seus dados pessoais, possuímos um canal específico: privacy@{window.location.hostname}
            </p>
          </div>
          <div className="box-privacy">
            <div style={{ width: "30%" }}>
              <h5 style={{ color: "#4b4e5c" }}>1. Quem é o responsável pelo tratamento de dados?</h5>
            </div>
            <div style={{ width: "70%", fontSize: "16px", color: "#666" }}>
              <p>
                O {window.location.hostname.indexOf("interpays.com.br") > -1 ? "Interpays Bank" : "Anna Bank"} é controlador do tratamento dos dados pessoais referentes aos Usuários, ou seja, uma vez que o Usuário entra em nossa plataforma ou preenche formulários, coletaremos algumas informações e tomaremos decisões, a fim de prestar o nosso serviço da
                melhor e mais segura forma possível.
              </p>
              <p>Além disso, a Nome e DADOS DA IF ou IP, é também uma controladora singular dos dados, uma vez que realiza o tratamento das informações do Usuário, tomando decisões para suas finalidades específicas de prestar serviços financeiros.</p>
              <p>Somos operadores do tratamento dos dados pessoais de terceiros inseridos em nossa solução pelos Usuários, ou seja, realizamos o tratamento de dados com base nas decisões de nossos clientes, controladores.</p>
              <p>Alguns Suboperadores são necessários para que a plataforma esteja disponível, como servidores, plataformas de hospedagem, ferramentas de marketing entre outros. Com isso, essas empresas passam a ter acesso aos seus dados. Contudo, nossas contratações sempre prezam pelo tratamento seguro das suas informações.</p>
            </div>
          </div>
          <div className="box-privacy">
            <div style={{ width: "30%" }}>
              <h5 style={{ color: "#4b4e5c" }}>2. Como faremos a segurança de seus dados?</h5>
            </div>
            <div style={{ width: "70%", fontSize: "16px", color: "#666" }}>
              <p>
                O {window.location.hostname.indexOf("interpays.com.br") > -1 ? "Interpays Bank" : "Anna Bank"} se preocupa muito com a segurança de seus dados pessoais. Por isso, implementa todas as medidas sugeridas pela Autoridade Nacional de Proteção de Dados (ANPD) em seu Guia Orientativo para Agentes de Tratamento de Pequeno Porte, o que compreende uma
                variedade de tecnologias e procedimentos de segurança para ajudar a proteger as suas informações.
              </p>
              <p>
                Para garantir que suas informações são armazenadas em ambiente seguro, a plataforma utiliza servidores da digital ocean, cujo acesso é restrito. Com isso, essa empresa passa a ter acesso aos seus dados, somente para prestar esse serviço, assim que você os fornece na{" "}
                {window.location.hostname.indexOf("interpays.com.br") > -1 ? "Interpays Bank" : "Anna Bank"}.{" "}
              </p>
            </div>
          </div>
          <div className="box-privacy">
            <div style={{ width: "30%" }}>
              <h5 style={{ color: "#4b4e5c" }}>3. Quais dados você precisa nos informar para utilizar nossa plataforma?</h5>
            </div>
            <div style={{ width: "70%", fontSize: "16px", color: "#666" }}>
              <p>
                O Usuário deverá realizar seu cadastro e criar uma conta de acesso no {window.location.hostname.indexOf("interpays.com.br") > -1 ? "Interpays Bank" : "Anna Bank"}, fornecendo inicialmente os seguintes dados: Nome ou Razão Social, CPF ou CNPJ, E-mail, Telefone e Endereço. Nos casos de pessoas jurídicas, serão solicitadas cópias do contrato
                social e dos documentos pessoais dos sócios. Ainda serão solicitadas informações das cobranças geradas pelos clientes.
              </p>
            </div>
          </div>
          <div className="box-privacy">
            <div style={{ width: "30%" }}>
              <h5 style={{ color: "#4b4e5c" }}>4. Para quais finalidades utilizamos os seus dados pessoais?</h5>
            </div>
            <div style={{ width: "70%", fontSize: "16px", color: "#666" }}>
              <p>Todos os seus dados são tratados com finalidades específicas e de acordo com a Lei Geral de Proteção de Dados Pessoais. Nós poderemos tratar essas informações para:</p>
              <ul style={{ marginLeft: "50px", marginTop: "10px" }}>
                <li>Prestar o nosso serviço;</li>
                <li>Dar suporte e realizar atendimento ao cliente;</li>
                <li>Repassar dados essenciais para realizar transações financeiras;</li>
                <li>Repassar dados necessários para cumprir regulações, como normas do Bacen e da CVM;</li>
                <li>Oferecer nossos serviços ou produtos.</li>
              </ul>
              <p>Para entender melhor o que fazemos com as informações, disponibilizamos uma tabela no nosso Aviso de Privacidade.</p>
            </div>
          </div>
          <div className="box-privacy">
            <div style={{ width: "30%" }}>
              <h5 style={{ color: "#4b4e5c" }}>5. Com quem compartilhamos seus dados pessoais?</h5>
            </div>
            <div style={{ width: "70%", fontSize: "16px", color: "#666" }}>
              <p>Nós não iremos compartilhar seus dados com terceiros, salvo nos casos citados no Aviso de Privacidade, em caso de consentimento legal do titular dos dados pessoais e por força de ordem judicial ou determinação legal.</p>
            </div>
          </div>
          <div className="box-privacy">
            <div style={{ width: "30%" }}>
              <h5 style={{ color: "#4b4e5c" }}>6. Seus registros de acesso serão coletados?</h5>
            </div>
            <div style={{ width: "70%", fontSize: "16px", color: "#666" }}>
              <p>
                Quando você entra em nossa plataforma, colhemos seus registros de acesso, ou seja, conjunto de informações referentes à data e hora de uso de uma determinada aplicação de internet a partir de um determinado endereço IP. Essas informações serão mantidas pelo{" "}
                {window.location.hostname.indexOf("interpays.com.br") > -1 ? "Interpays Bank" : "Anna Bank"}, sob sigilo, em ambiente controlado e de segurança, pelo prazo mínimo de 06 (seis) meses, nos termos da Lei n. 12.965/2014, e artigo 7º, II, da Lei n. 13.709/18.{" "}
              </p>
            </div>
          </div>
          <div className="box-privacy">
            <div style={{ width: "30%" }}>
              <h5 style={{ color: "#4b4e5c" }}>7. Dados pessoais serão coletados de forma indireta?</h5>
            </div>
            <div style={{ width: "70%", fontSize: "16px", color: "#666" }}>
              <p>
                Além dos registros de acesso, podemos também coletar algumas informações de forma indireta, como endereço de IP, conforme as nossas{" "}
                <strong>
                  <a href="/cookies">Políticas de Cookies.</a>
                </strong>
              </p>
            </div>
          </div>
          <div className="box-privacy">
            <div style={{ width: "30%" }}>
              <h5 style={{ color: "#4b4e5c" }}>8. Registros de comunicações serão armazenados?</h5>
            </div>
            <div style={{ width: "70%", fontSize: "16px", color: "#666" }}>
              <p>Nós iremos armazenar também as conversas que você tiver conosco em nossos canais de comunicação, pois isso irá melhorar o seu atendimento, torná-lo mais eficiente e trazer segurança para as partes.</p>
            </div>
          </div>
          <div className="box-privacy">
            <div style={{ width: "30%" }}>
              <h5 style={{ color: "#4b4e5c" }}>9. Quais são seus direitos?</h5>
            </div>
            <div style={{ width: "70%", fontSize: "16px", color: "#666" }}>
              <p>
                Mesmo que você já tenha nos fornecido seus dados pessoais, você possui total direito de, a qualquer momento, solicitar ao controlador: confirmar a existência de tratamento dos seus dados; acessar os seus dados; corrigir os seus dados; tornar anônimo os dados; bloquear ou eliminar os dados desnecessários, excessivos ou tratados em
                desconformidade com a Lei; pedir a portabilidade dos dados a outro fornecedor; eliminar dados, exceto aqueles exigidos por lei; obter informação sobre quem o controlador realizou uso compartilhado de dados; obter informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; e voltar atrás e revogar o seu
                consentimento.
              </p>
            </div>
          </div>
          <div className="box-privacy" id="privacy-alert">
            <div style={{ width: "30%" }}>
              <h5 style={{ color: "#4b4e5c" }}>10. Qual é o conteúdo do Aviso de Privacidade?</h5>
            </div>
            <div style={{ width: "70%", fontSize: "16px", color: "#666" }}>
              <p>O Aviso de Privacidade a seguir está dividida da seguinte forma para facilitar o seu acesso à informação:</p>
              <ul style={{ marginLeft: "50px", marginTop: "10px" }}>
                <li>Data de Disponibilização do Texto;</li>
                <li>Explicação dos Termos Técnicos ou em Língua Estrangeira;</li>
                <li>Agentes de Tratamento;</li>
                <li>Segurança da Informação;</li>
                <li>Coleta de Dados;</li>
                <li>Tratamento de Dados Pessoais;</li>
                <li>Cancelamento da Plataforma, de Contas de Acesso e Exclusão de Dados;</li>
                <li>Direitos do Titular dos Dados Pessoais;</li>
                <li>Mudanças no Aviso de Privacidade;</li>
                <li>Canal de Comunicação sobre Privacidade e Disposições Gerais;</li>
                <li>Contato Sobre Assuntos Gerais.</li>
              </ul>
              <div style={{ textAlign: "right" }}>
                <p>Equipe do {window.location.hostname.indexOf("interpays.com.br") > -1 ? "Interpays Bank" : "Anna Bank"}.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
