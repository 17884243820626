import React, { useEffect, useState } from "react";
import { Divider, Modal, Button, Box, TextField, Typography, IconButton, Avatar } from "@mui/material";
import { Close as CloseIcon, InsertDriveFile as FileIcon, BackupSharp as BackupSharpIcon } from "@mui/icons-material";

interface TicketReply {
  id: string;
  descricao: string;
  anexos: File[];
}

interface ResponderTicketModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: TicketReply) => void;
  ticketReply: TicketReply;
  setTicketReplyForm: React.Dispatch<React.SetStateAction<TicketReply>>;
}

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
const MAX_FILES = 5;
const MAX_DESCRIPTION_LENGTH = 500;
const MIN_DESCRIPTION_LENGTH = 10;
const ALLOWED_FILE_TYPES = ["image/jpeg","image/jpg", "image/png", "application/pdf"];

const ResponderTicketModal: React.FC<ResponderTicketModalProps> = ({ open, onClose, onSubmit, ticketReply, setTicketReplyForm }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [errors, setErrors] = useState({ descricao: "", anexos: "" });
  const [previews, setPreviews] = useState<{ id: string; url: string }[]>([]);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const validateForm = () => {
    let valid = true;
    let newErrors = { descricao: "", anexos: "" };

    if (ticketReply.descricao.length < MIN_DESCRIPTION_LENGTH) {
      newErrors.descricao = `Descrição deve ter no mínimo ${MIN_DESCRIPTION_LENGTH} caracteres.`;
      valid = false;
    } else if (ticketReply.descricao.length > MAX_DESCRIPTION_LENGTH) {
      newErrors.descricao = `Descrição deve ter no máximo ${MAX_DESCRIPTION_LENGTH} caracteres.`;
      valid = false;
    }

    if (ticketReply.anexos.some((file) => file.size > MAX_FILE_SIZE)) {
      newErrors.anexos = "Arquivos devem ter no máximo 5 MB.";
      valid = false;
    }

    if (ticketReply.anexos.some((file) => !ALLOWED_FILE_TYPES.includes(file.type))) {
      newErrors.anexos = "Apenas arquivos JPG, JPEG, PNG ou PDF são permitidos.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files).filter(
        (file) => ALLOWED_FILE_TYPES.includes(file.type) && file.size <= MAX_FILE_SIZE
      );
      if (ticketReply.anexos.length + newFiles.length > MAX_FILES) {
        setErrors({ ...errors, anexos: "Você pode adicionar no máximo 5 arquivos." });
        return;
      }

      setTicketReplyForm((prevForm) => ({
        ...prevForm,
        anexos: [...prevForm.anexos, ...newFiles],
      }));

      setPreviews((prevPreviews) => [
        ...prevPreviews,
        ...newFiles.map((file) => ({
          id: file.name + file.size,
          url: URL.createObjectURL(file),
        })),
      ]);
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = ticketReply.anexos.filter((_, i) => i !== index);
    const removedFileId = ticketReply.anexos[index].name + ticketReply.anexos[index].size;

    setTicketReplyForm((prevForm) => ({ ...prevForm, anexos: newFiles }));
    setPreviews((prevPreviews) => prevPreviews.filter((preview) => preview.id !== removedFileId));
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit(ticketReply);
      onClose();
    }
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    return () => {
      previews.forEach((preview) => URL.revokeObjectURL(preview.url));
    };
  }, [previews]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: windowWidth <= 720 ? 0 : "50%",
          left: "50%",
          transform: windowWidth <= 720 ? "translateX(-50%)" : "translate(-50%, -50%)",
          width: windowWidth <= 720 ? "100vw" : 600,
          height: windowWidth <= 720 ? "100vh" : "auto",
          borderRadius: windowWidth <= 720 ? 0 : "8px",
          bgcolor: "background.paper",
          boxShadow: 24,
          overflowY: "auto",
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }} component="header">
          <Typography variant="h6">Responder Ticket</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box component="main" sx={{ mt: 2 }}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Resposta"
            id="descricao"
            name="descricao"
            value={ticketReply.descricao}
            onChange={(e) => setTicketReplyForm((prevForm) => ({ ...prevForm, descricao: e.target.value }))}
            sx={{ mb: 2 }}
            error={!!errors.descricao}
            helperText={errors.descricao ? `${errors.descricao} ${ticketReply.descricao.length}/${MAX_DESCRIPTION_LENGTH}` : `${ticketReply.descricao.length}/${MAX_DESCRIPTION_LENGTH}`}
          />
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            Permitido jpg, jpeg, png e pdf. Máximo de 5 anexos com tamanho máximo de 5MB cada anexo.
          </Typography>
          <input ref={inputRef} accept={ALLOWED_FILE_TYPES.join(",")} style={{ display: "none" }} id="anexos" name="anexos" type="file" onChange={handleFileChange} multiple />
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", alignItems: "center", mb: 2 }}>
            {ticketReply.anexos.length < MAX_FILES && (
              <Box
                onClick={() => inputRef.current?.click()}
                sx={{
                  width: "80px",
                  height: "80px",
                  border: "1px dashed #ccc",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  borderRadius: "4px",
                }}
              >
                <BackupSharpIcon fontSize="large" />
              </Box>
            )}
            {previews.map((preview, index) => (
              <Box key={preview.id} sx={{ width: "80px", height: "80px", position: "relative", borderRadius: "4px", overflow: "hidden" }}>
                {ticketReply.anexos[index].type.startsWith("image") ? (
                  <Avatar src={preview.url} variant="square" sx={{ width: "100%", height: "100%" }} />
                ) : (
                  <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f0f0f0" }}>
                    <FileIcon fontSize="large" />
                  </Box>
                )}
                <IconButton onClick={() => handleRemoveFile(index)} sx={{ position: "absolute", top: 0, right: 0, bgcolor: "white" }}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Box>
          {errors.anexos && <Typography color="error" sx={{ mb: 2 }}>{errors.anexos}</Typography>}
        </Box>
        <Divider />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }} component="footer">
          <Button variant="contained" onClick={handleSubmit} sx={{ backgroundColor: "#0e8ba4", color: "white", "&:hover": { backgroundColor: "#0d7e8b" } }}>
            Responder
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ResponderTicketModal;
