import { useEffect, useRef, useState } from "react";
import { Card, CardContent, CardHeader, Grid, Stack, TextField, Typography } from "@mui/material";
import logoInterpaysBig from '../../assets/logo1.png';
import logoAnnaSmall from '../../assets/logoAnna1.png';

import { colors } from "../../styles/global";
import { PixIcon } from "../../components/Icon";
import { IoCopyOutline } from "react-icons/io5";
import { Button } from "../../components/Button";
import * as apiInterpays from '../../services/apiInterpays';
import { useParams } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import { useSnackbar } from 'notistack';
import * as configSnackbar from '../../utils/configDefaultSnackbar';
import * as Mask from 'vanilla-masker';
import moment from 'moment';
import * as utils from '../../utils/utils';
import { AiOutlineCheckCircle } from "react-icons/ai";

export function Payment() {
    const refQRcodeText = useRef(null);
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [showAlertCopy, setShowAlertCopy] = useState(false);
    const [qrcodeText, setQrcodeText] = useState("")
    const [searching, setSearching] = useState(true);
    const [invoice, setInvoice] = useState<any>({});
    const [error, setError] = useState<string | null>(null);
    const [status, setStatus] = useState(null);

    const handleCopyCode = () => {
        if (!navigator.clipboard) {
            // refQRcodeText?.current.select();

            document.execCommand('copy');
            setShowAlertCopy(true);

        } else {
            setShowAlertCopy(true);
            navigator.clipboard.writeText(qrcodeText);
        }

        setTimeout(() => {
            setShowAlertCopy(false);
        }, 4000)
    }

    const searchInvoice = async () => {
        await apiInterpays.API.get(`/invoice/${params.id}`)
            .then(({ data }) => {
                setInvoice(data);
                setQrcodeText(data.dynamicCob.textoImagemQRcode);
                setStatus(data.dynamicCob.status);
                if (data.dynamicCob.status === 'ATIVA') {
                    const timeAwait = 10000;
                    setTimeout(() => {
                        checkStatus();
                    }, timeAwait);
                }
            }).catch(({ response }) => {
                setError('Ocorreu um erro ao buscar informações da sua compra, desculpe!');
            });

        setSearching(false);
    }

    const checkStatus = () => {
        apiInterpays.API.get(`/invoice/status/check/${params.id}`)
            .then(({ data }) => {
                setStatus(data.dynamicCob.status);

                if (data.dynamicCob.status === 'ATIVA') {
                    const timeAwait = 10000;
                    setTimeout(() => {
                        checkStatus();
                    }, timeAwait);
                }
            }).catch((error) => {
                console.log(error)
                enqueueSnackbar('Erro ao checar status atual da sua cobrança', configSnackbar.warning);
            });
    }

    useEffect(() => {
        searchInvoice();
        //eslint-disable-next-line
    }, [])

    return (
        <Stack padding={3} sx={{ background: 'white', minHeight: '100vh' }}>
            <div className="display-flex justify-content-center width-100">
                <img src={window.location.hostname.indexOf('interpays.com.br') > -1 ? logoInterpaysBig : logoAnnaSmall} alt="logo interpays" style={{ width: 150, height: 'auto' }} />

            </div>
            <Stack direction={{ xs: 'row' }} justifyContent="center" marginTop={3}>
                <h5>Detalhes do pagamento</h5>
            </Stack>

            {
                searching ? (
                    <Stack direction={{ xs: 'row' }} justifyContent="center" marginTop={15} alignItems="center">
                        <span style={{ marginRight: 10 }}>Buscando informações </span>
                        <PacmanLoader size={8} color={colors.turquoise} />
                    </Stack>)
                    :
                    (
                        <Grid container marginTop={3} spacing={2}>
                            {
                                error ? (
                                    <Grid item xs={12} lg={12}>
                                        <strong style={{ color: colors.red, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {error}
                                        </strong>
                                    </Grid>
                                )
                                    :
                                    (<>
                                        <Grid xs={12} sm={12} md={6} item>
                                            <Card>
                                                <CardHeader
                                                    title={<h5 style={{ color: 'white' }}>Informações da compra</h5>}
                                                    sx={{ background: colors.turquoise }}
                                                />

                                                <CardContent>
                                                    <Stack spacing={2}>
                                                        <Stack direction={{ sm: 'column', md: 'column' }} justifyContent="space-between">
                                                            <span style={{ color: colors.turquoise }}>Nome</span>
                                                            <span>{invoice?.name}</span>
                                                        </Stack>

                                                        <Stack direction={{ sm: 'column', md: 'column' }} justifyContent="space-between">
                                                            <span style={{ color: colors.turquoise }}>CPF</span>
                                                            <span>{Mask.toPattern(invoice?.doc ?? "", '999.999.999-99')}</span>
                                                        </Stack>

                                                        <Stack direction={{ sm: 'column', md: 'column' }} justifyContent="space-between">
                                                            <span style={{ color: colors.turquoise }}>Vencimento</span>
                                                            <span>{invoice ? moment(invoice.expiresAt).format('DD/MM/YYYY HH:mm:ss') : ""}</span>
                                                        </Stack>

                                                        <Stack direction={{ sm: 'column', md: 'column' }} justifyContent="space-between">
                                                            <span style={{ color: colors.turquoise }}>Descrição</span>
                                                            <Stack direction={{ sm: 'column' }} spacing={1} marginTop={1}>
                                                                {
                                                                    invoice && invoice.itemOnInvoice?.map((item: any) => (
                                                                        <span key={utils.randomString()}>{item.itemInvoice.description} {utils.toMoney(item.itemInvoice.value)}</span>
                                                                    ))
                                                                }
                                                            </Stack>
                                                        </Stack>

                                                        <Stack direction={{ sm: 'column', md: 'column' }} justifyContent="space-between">
                                                            <span style={{ color: colors.turquoise }}>Quantidade</span>
                                                            {
                                                                invoice && invoice.itemOnInvoice.length
                                                            }
                                                        </Stack>

                                                        <Stack direction={{ sm: 'column', md: 'column' }} justifyContent="space-between">
                                                            <span style={{ color: colors.turquoise }}>Valor</span>
                                                            <strong style={{ fontSize: 20 }}>{utils.toMoney(invoice.value)}</strong>
                                                        </Stack>
                                                    </Stack>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid xs={12} sm={12} md={6} item>
                                            <Card>
                                                <CardHeader
                                                    title={<h5 style={{ color: 'white' }}>Dados para pagamento</h5>}
                                                    sx={{ background: colors.turquoise }}
                                                />
                                                {
                                                    status !== "ATIVA" ? (
                                                        <Stack direction={{ xs: 'row' }} padding={10} justifyContent="center">
                                                            {
                                                                status === 'COMPLETA' && (
                                                                    <strong style={{ color: colors.success, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <AiOutlineCheckCircle size={25} />
                                                                        <strong style={{ marginLeft: 5 }}>Pagamento recebido com sucesso!</strong>
                                                                    </strong>
                                                                )
                                                            }

                                                            {
                                                                status === 'DEVOLVIDO' && (
                                                                    <strong style={{ color: colors.warning }}>
                                                                        Pagamento recebido foi devolvido para o pagador
                                                                    </strong>
                                                                )
                                                            }

                                                            {
                                                                status === 'EXPIRADO' && (
                                                                    <strong style={{ color: colors.textLight }}>
                                                                        Este QR Code pix expirou!
                                                                    </strong>
                                                                )
                                                            }



                                                        </Stack>
                                                    ) : (
                                                        <CardContent>
                                                            <Stack padding={2} spacing={2}>
                                                                <Stack direction={{ sm: 'column', md: 'column' }} justifyContent="space-between">
                                                                    <span style={{ color: colors.turquoise }}>Forma de pagamento</span>
                                                                    <div className="display-flex margin-top1">
                                                                        <PixIcon height={25} width={25} color={colors.turquoise} /> <span style={{ fontSize: 22, marginLeft: 8 }}>Pix</span>
                                                                    </div>
                                                                </Stack>

                                                                <Stack direction={{ sm: 'column', md: 'column' }} justifyContent="center" alignItems="center">
                                                                    <div className="display-flex justify-content-center width-100">
                                                                        {/* <span style={{ color: colors.turquoise }}>QR Code Pix</span> */}
                                                                    </div>
                                                                    <Stack direction={{ xs: 'column', sm: 'column', lg: 'row' }} spacing={1}>
                                                                        <img
                                                                            src={`data:image/png;base64,${invoice?.dynamicCob?.qrCodeImage}`}
                                                                            alt="qrcode"
                                                                            style={{
                                                                                width: 190,
                                                                                height: 190
                                                                            }}
                                                                        />
                                                                        <div>
                                                                            <Typography variant="h6">O que é Pix?</Typography>
                                                                            <Typography variant="body2" color={colors.textLight}>
                                                                                O Pix é a nova modalidade de transferências do banco central, que funcionam 24 horas por dia e possuem confirmação em tempo real.
                                                                            </Typography>
                                                                            <Typography variant="body2" marginTop={1} color={colors.textLight}>
                                                                                Procure em seu aplicativo de banco ou conta digital a funcionalidade e escaneie o QR Code ao lado ou copie o código usando o Pix Copia e Cola para efetuar um pagamento.
                                                                            </Typography>
                                                                        </div>
                                                                    </Stack>

                                                                </Stack>

                                                                <Stack direction={{ sm: 'column', md: 'column' }} justifyContent="center" alignItems="center">
                                                                    {showAlertCopy && (
                                                                        <span style={{ color: colors.success, marginBottom: 5 }}>
                                                                            <IoCopyOutline size={18} /> Código QRCode copiado com sucesso!
                                                                        </span>
                                                                    )}

                                                                    <TextField
                                                                        ref={refQRcodeText}
                                                                        size="small"
                                                                        fullWidth
                                                                        multiline
                                                                        value={qrcodeText}
                                                                    />
                                                                    <Button color="turquoise" onClick={() => handleCopyCode()} style={{ width: '100%' }}>
                                                                        <span>Copiar QR Code</span>
                                                                        <IoCopyOutline size={25} />
                                                                    </Button>

                                                                </Stack>


                                                            </Stack>
                                                        </CardContent>

                                                    )
                                                }
                                            </Card>
                                        </Grid>
                                    </>)
                            }
                        </Grid>
                    )
            }

        </Stack>
    )
}