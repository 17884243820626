import styled from 'styled-components';

export const Container = styled.div`
    background: white;
    border-radius: 10px;
    padding: 20px;
    
    .subtitle {
        font-size: 13px;
        color: var(--shadow);
    }

    #transactions {
        display: flex;
        flex-direction: column;
    }
`;