import { AppContent } from "../../../../components/AppContent";
import { Breadcrumps } from "../../../../components/Breadcrumbs";
import { BiBarcodeReader } from "react-icons/bi";
import { List, ListItem, ListItemButton, ListItemText, Collapse, Typography, CircularProgress, Card, Grid } from "@mui/material";

export function PagamentosBarras() {
  return (
    <AppContent>
      <Breadcrumps Icon={BiBarcodeReader} title="Pagamentos com código de barras" links={[{ name: "Pagamentos com código de barras", href: "/app/pagamentos/com-codigo-de-barras" }]} />
      <Grid container justifyContent="center" marginTop={-2} padding={1}>
        <Grid xs={12} sm={12} lg={10} item>
          <Card sx={{ p: 2, mt: 3, width: "100%" }}></Card>
        </Grid>
      </Grid>
    </AppContent>
  );
}
