import { MdChevronRight } from "react-icons/md";
import { Container, Content } from "./style";
import { colors } from "../../styles/global";
import * as utils from "../../utils/utils";
import { GiReceiveMoney, GiPayMoney } from "react-icons/gi";
import { formatDistanceToNow, addHours, differenceInMinutes, format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

interface IProps {
  name: string;
  status: string;
  data: Date;
  value: number;
  onClick: () => void;
}

export function ItemCashIn(props: IProps) {
  // const dataCorrigida = addHours(props.data, 3);
  const dataCorrigida = props.data;

  const now = new Date();
  const differenceMinutes = differenceInMinutes(now, dataCorrigida);

  let dateDisplay;

  if (differenceMinutes < 60) {
    dateDisplay = formatDistanceToNow(dataCorrigida, {
      addSuffix: true,
      includeSeconds: true,
      locale: ptBR,
    });
  } else {
    dateDisplay = format(dataCorrigida, 'dd MMM yyyy HH:mm:ss', { locale: ptBR });
  }
  
  return (
    <Container onClick={props.onClick}>
      <Content>
        {props.value >= 0 ? <GiReceiveMoney size={22} color={colors.success} /> : <GiPayMoney size={22} color={colors.red} />}
        <div className="content-detail">
          <div className="details">
            <strong className="name">{props.name.toUpperCase()}</strong>
            <div>
              <span>{props.status}</span>
              <br />
              <span>{dateDisplay}</span>
            </div>
          </div>

          <strong className="amount">{utils.toMoney(props.value)}</strong>
        </div>
      </Content>
      <MdChevronRight size={18} />
    </Container >
  );
}
