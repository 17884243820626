import { HelperTextError } from '../style';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { useSnackbar } from 'notistack';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormikProvider, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import * as snackConfig from '../../../utils/configDefaultSnackbar';
import * as apiInterpays from '../../../services/apiInterpays';
import { Stack, Typography } from '@mui/material';
import { CheckBoxThema } from '../../../components/CheckboxThema';
import { FormPj } from './FormPJ';
import { FormPF } from './FormPF';
// import { AxiosRequestConfig } from 'axios';


const schemaForm = Yup.object().shape({
    typeDoc: Yup.string().required('Selecione um tipo de documento'),
    fileFrontDOCPF: Yup.mixed().test('fileType', 'Somente arquivos de imagem são permitidos', (value) => {
        if (!value) {
            return true;
        }
        if (value.length > 0) {
            const fileType = value[0].blobFile.type

            return fileType.indexOf('image/') >= 0
        }
        return false
    })
        .required('Você deve selecionar um arquivo'),
    fileVersusDOCPF: Yup.mixed().test('fileType', 'Somente arquivos de imagem são permitidos', (value) => {
        if (!value) {
            return true;
        }
        if (value.length > 0) {
            const fileType = value[0].blobFile.type

            return fileType.indexOf('image/') >= 0
        }
        return false
    })
        .required('Você deve selecionar um arquivo'),
    fileCSPJ: Yup.mixed().when('type', {
        is: 'PJ',
        then: Yup.mixed().test('fileType', 'Somente arquivos PDF são permitidos', (value) => {
            if (!value) {
                return true;
            }
            if (value.length > 0) {
                const fileType = value[0].blobFile.type
                return fileType === 'application/pdf' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            }
            return true
        })
            .required('Você deve selecionar um arquivo'),
        otherwise: Yup.mixed()
    }),
    // fileCIPJ: Yup.mixed().when('type', {
    //     is: 'PJ',
    //     then: Yup.mixed().test('fileType', 'Somente arquivos PDF são permitidos', (value) => {
    //         if (!value) {
    //             return true;
    //         }

    //         if (value.length > 0) {
    //             const fileType = value[0].blobFile.type
    //             return fileType === 'application/pdf' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    //         }
    //         return true
    //     })
    //         .required('Você deve selecionar um arquivo'),
    //     otherwise: Yup.mixed()
    // }),

});

interface IRegister {
    id: string;
    type: string,
    doc: string,
    name: string,
    birthdate: Date | null,
    phone: string,
    term: boolean,
    companyName: string,
    email: string
    step: number,
    fileFrontDOCPF: string,
    fileVersusDOCPF: string,
    fileCIPJ: string,
    fileCSPJ: string,
}

interface IProps {
    data: IRegister,
    setOnData: (data: IRegister) => void;
}


export function FormUploadDocuments({ data, setOnData }: IProps) {
    const { enqueueSnackbar } = useSnackbar();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            typeDoc: '',
            fileFrontDOCPF: '',
            fileVersusDOCPF: '',
            // fileCIPJ: '',
            fileCSPJ: '',
            type: data.type
        },
        validationSchema: schemaForm,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                if (!executeRecaptcha) {
                    enqueueSnackbar('Ocorreu um erro no recaptcha!', snackConfig.warning);
                    return;
                }
                const resultCaptcha = await executeRecaptcha('register');
                await handleSave(values);
                setOnData({ ...data, step: 4 })
            } catch (error: any) {
                const message = error.response?.data.message ?? 'Ocorreu um erro ao fazer requisição';
                enqueueSnackbar(message, snackConfig.warning);
            }
        }
    });

    const handleSave = async (values: any) => {
        const formData = new FormData();

        formData.append('idUser', data.id);
        formData.append('cs', values.fileCSPJ[0]?.blobFile);
        formData.append('front', values.fileFrontDOCPF[0]?.blobFile);
        formData.append('versus', values.fileVersusDOCPF[0]?.blobFile);
        formData.append('typeDoc', values.typeDoc);

        const optionsRequest = {
            method: 'PUT',
            url: '/create-account/doc',
            data: formData
        }

        const response = await apiInterpays.API.request(optionsRequest);

        return response.data;
    }

    const { errors, touched, isSubmitting, setFieldValue, values } = formik;

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='h5' sx={{ fontSize: 28, color: 'black' }} marginTop={3} >Estamos quase lá</Typography>
            <Typography variant='body2' width={450} align='center' marginTop={2}>Precisamos de alguns documentos para validarmos seus dados, selecione qual dos documentos deseja enviar</Typography>
            {
                data.type === 'PJ' ?
                    <>
                        <Stack direction="row" spacing={2} marginTop={2}>
                            <CheckBoxThema
                                label='Contrato social + RG'
                                name="doc"
                                checked={values.typeDoc === 'Contrato social + RG'}
                                onChange={() => setFieldValue('typeDoc', 'Contrato social + RG')}
                            />

                            <CheckBoxThema
                                label='CNH do responsável'
                                name="doc"
                                checked={values.typeDoc === 'CNH do responsável'}
                                onChange={() => setFieldValue('typeDoc', 'CNH do responsável')}
                            />
                        </Stack>
                        {errors.typeDoc && <HelperTextError>{errors.typeDoc}</HelperTextError>}
                    </>
                    :
                    <>
                        <Stack direction="row" spacing={2} marginTop={2}>
                            <CheckBoxThema
                                label='RG'
                                name="doc"
                                checked={values.typeDoc === 'RG'}
                                onChange={() => setFieldValue('typeDoc', 'RG')}
                            />

                            <CheckBoxThema
                                label='CNH'
                                name="doc"
                                checked={values.typeDoc === 'CNH'}
                                onChange={() => setFieldValue('typeDoc', 'CNH')}
                            />
                        </Stack>
                        {errors.typeDoc && <HelperTextError>{errors.typeDoc}</HelperTextError>}
                    </>
            }
            {
                Boolean(values.typeDoc) &&
                <FormikProvider value={formik}>
                    <Form noValidate autoComplete="off" className='form'>
                        {
                            data.type === 'PJ' ?
                                <FormPj
                                    typeDoc={values.typeDoc}
                                    formik={formik}
                                />
                                :
                                <FormPF
                                    typeDoc={values.typeDoc}
                                    formik={formik}
                                />
                        }

                        <Button type='submit' color='turquoise' typeLoad='clock' load={isSubmitting} className='margin-top2' style={{ height: 36, alignItems: 'center', fontSize: '16px' }}>
                            Continuar
                        </Button>

                    </Form>
                </FormikProvider>
            }
            <div className='flex-direction-column display-flex text-align-center margin-top2 margin-bottom2'>
                <Link to="/login" style={{ fontSize: '14px', margin: '25px 0' }}>Iniciar Sessão</Link>
                {/* <Link to="#" style={{ marginTop: 10 }}>Criar uma conta</Link> */}
            </div>
        </div>
    )
}