import { useEffect, useState, useCallback } from "react";
import { Grid, Stack, Dialog } from "@mui/material";
import { MdClose, MdDashboard, MdQrCodeScanner } from "react-icons/md";
import { AppContent } from "../../../components/AppContent";
import { Breadcrumps } from "../../../components/Breadcrumbs";
import { ContentPage } from "../../../components/ContentPage";
import { CardBalance2 } from "../../../components/CardBalance2";
import { LastTransactions } from "../../../components/LastTransactions";
import { ButtonOptionDashboard } from "../../../components/ButtonOptionDashboard";
import { PixOptionsDashboard } from "../../../components/PixOptionsDashboard";
import { CiBank, CiBarcode, CiWallet } from "react-icons/ci";
import * as apiInterpays from "../../../services/apiInterpays";
import { useSnackbar } from "notistack";
import * as handleRequestError from "../../../utils/handleRequestError";
import CreateDepositModal from "./DepositV2/CreateDepositModal";
import PagamentosBarrasModal from "./PagamentosBarras/PagamentosBarrasModal";
import TransferenciaInternaModal from "./TransferenciaInterna/TransferenciaInternaModal";
import TransferenciaPixModal from "./TransferenciaPix/TransferenciaPixModal";
import LoadingGear from "../../../components/LoadGear/LoadGear";

export function Dashboard() {
  const [transactions, setTransactions] = useState<any[]>([]);
  const [balances, setBalances] = useState<any[]>([]);
  const [searchingTransactions, setSearchingTransactions] = useState(true);
  const [searchingBalances, setSearchingBalances] = useState(true);
  const [accountFromDeposit, setAccountFromDeposit] = useState<any>({});
  const [showModalDeposit, setShowModalDeposit] = useState(false);
  const [showModalPagamanetosBarras, setShowModalPagamanetosBarras] = useState(false);
  const [showModalPagamanetosTransferenciaPix, setShowModalTransferenciaPix] = useState(false);
  const [showModalTransferenciaInterna, setShowModalTransferenciaInterna] = useState(false);
  const [idLastTimeoutCheck, setIDLastTimeoutCheck] = useState();
  const [loading, setLoading] = useState(true);

  const handleLastTransactions = useCallback(async () => {
    setSearchingTransactions(true);

    try {
      const { data } = await apiInterpays.API.get("/dashboard/last-transactions");
      const newData: any[] = [];

      data.forEach((item: any) => {
        newData.push({
          name: item.valor > 0 ? item.origem[0].nome : item.destino[0].nome,
          value: item.valor,
          account: item.account.name,
          data: new Date(item.data),
        });
      });

      setTransactions(newData);
      setSearchingTransactions(false);
    } catch (error: any) {
      handleRequestError.handleError(error.response, useSnackbar);
    }
  }, [useSnackbar]);

  const handleToggleModalDeposit = useCallback((show: boolean, account?: any) => {
    if (!show) {
      setAccountFromDeposit({});
    } else {
      setAccountFromDeposit(account);
    }
    setShowModalDeposit(show);
  }, []);

  const handleBalances = useCallback(async () => {
    setLoading(true);
    setSearchingBalances(true);

    try {
      const { data } = await apiInterpays.API.get("/dashboard/balances");
      const newData: any[] = [];

      data.forEach((item: any) => {
        newData.push({
          amount: item.balance,
          name: item.name,
          agency: item.branch,
          account: item.account,
          id: item.id,
          onAdd: () => {
            const account = {
              id: item.id,
              name: item.name,
              agency: item.branch,
              account: item.account,
              balance: item.balance,
              ownerName: item.ownerName,
              ownerDoc: item.ownerDoc,
            };

            const openModal = true;

            handleToggleModalDeposit(openModal, account);
          },
        });
      });

      setBalances(newData);
      setSearchingBalances(false);
      setLoading(false);
    } catch (error) {
      console.log(error); // Trate o erro conforme necessário
    }
  }, [handleToggleModalDeposit]);

  useEffect(() => {
    handleLastTransactions();
    handleBalances();
  }, [handleBalances, handleLastTransactions]);

  return (
    <div>
      {loading ? (
        <LoadingGear/>
      ) : (
        <AppContent paddingBottom="20px">
          <Breadcrumps Icon={MdDashboard} title="Dashboard" links={[{ name: "Dashboard", href: "#" }]} />
          <ContentPage style={{ display: "flex", justifyContent: "center", marginTop: 35 }}>
            <div style={{ maxWidth: 1350, width: "100%" }}>
              <Stack sx={{ padding: "0 15px 0 15px" }} display="flex" justifyContent="center">
                <Grid container spacing={{ xs: 2, md: 3 }} justifyContent="center" paddingTop={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CardBalance2 balances={balances} load={searchingBalances} onRefresh={() => handleBalances()} />
                    {/*<PixOptionsDashboard />*/}
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                    <LastTransactions load={searchingTransactions} data={transactions} onRefresh={() => handleLastTransactions()} />
                  </Grid>
                </Grid>
              </Stack>
               {/*<Stack sx={{ padding: "15px 15px 0 15px" }} display="flex" justifyContent="center">
                <Grid container spacing={{ xs: 2, md: 3 }} justifyContent="center" paddingTop={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <ButtonOptionDashboard Icon={CiBarcode} onClick={() => setShowModalPagamanetosBarras(true)} title="Pagamentos Boletos e Convênios" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <ButtonOptionDashboard Icon={MdQrCodeScanner} onClick={() => setShowModalTransferenciaPix(true)} title="Pix Transferências e Pagamentos" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <ButtonOptionDashboard Icon={CiBank} onClick={() => setShowModalTransferenciaInterna(true)} title="Transferência Interna" />
                  </Grid>
                </Grid>
              </Stack> */}
            </div>
          </ContentPage>
          <CreateDepositModal accountDeposit={accountFromDeposit} onChangeIDTimeout={(idTime) => setIDLastTimeoutCheck(idTime)} open={showModalDeposit} onClose={() => setShowModalDeposit(false)} setShowModalDeposit={setShowModalDeposit} />
         {/* <PagamentosBarrasModal open={showModalPagamanetosBarras} onClose={() => setShowModalPagamanetosBarras(false)} setShowModalPagamanetosBarras={setShowModalPagamanetosBarras}/>
          <TransferenciaInternaModal open={showModalTransferenciaInterna} onClose={() => setShowModalTransferenciaInterna(false)} setShowModalTransferenciaInterna={setShowModalTransferenciaInterna} balances={balances}/>
          <TransferenciaPixModal open={showModalPagamanetosTransferenciaPix} onClose={() => setShowModalTransferenciaPix(false)} setShowModalTransferenciaPix={setShowModalTransferenciaPix} balances={balances} /> */}
        </AppContent>
      )}
    </div>
  );
}
