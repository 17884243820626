import styled, { css } from 'styled-components';

interface IProps {
    checked?: boolean;
}

const getStyleChecked = () => {
    return css`
        background: var(--turquoise);
        color: var(--white);
        font-weight: 500;
        border: 0;
    `;
}

export const Container = styled.div.attrs((props: IProps) => { })`
    padding: 0.6rem 1.2rem;
    border-radius: 0.25rem;
    color: var(--text-Clean-light);
    display: flex;
    justify-content: center;
    /* min-width: 350px; */

    border: 1px solid var(--text-Clean-light);
    ${props => props.checked ? getStyleChecked() : ''}
    
    &:hover, label:hover {
        cursor: pointer;
    }
`