import { MdChevronRight } from "react-icons/md";
import { Container, Content } from "./style";

interface IProps {
    name?: string;
    subtitle?: string;
    onClick: () => void;
}

export function ItemAccount(props: IProps) {
    return (
        <Container onClick={props.onClick}>
            <Content>
                <div className="content-detail">
                    <strong className="name">{props.name}</strong>
                    <span>{props.subtitle}</span>
                </div>
            </Content>
            <MdChevronRight size={18} />
        </Container>
    )
}