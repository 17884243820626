import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { MdClose } from "react-icons/md";
import { Button } from "../../../../../components/Button";
import * as Yup from 'yup';
import { FormikProvider, Form, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as configSnackbar from '../../../../../utils/configDefaultSnackbar';
import Mask from 'vanilla-masker';
import * as apiInterpays from '../../../../../services/apiInterpays';

const schemaForm = Yup.object().shape({
    description: Yup.string().required("informe uma descrição"),
    quantity: Yup.number().min(1).required("informe uma quantidade"),
    value: Yup.string().required("informe o valor")
});

interface IProps {
    onClose: () => void;
    updateList: () => void;
}

export function RegisterItem(props: IProps) {
    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            description: '',
            quantity: '',
            value: ''
        },
        validationSchema: schemaForm,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                await handleSave(JSON.parse(JSON.stringify(values)));
                enqueueSnackbar('Tranferência finalizada com sucesso!', configSnackbar.success);
                props.updateList();
                props.onClose();
            } catch (error: any) {
                const message = error.response?.data.message ?? 'Ocorreu um erro ao fazer requisição';
                enqueueSnackbar(message, configSnackbar.warning);
            }
        }
    });

    const handleSave = async (values: any) => {
        values.value = parseFloat(values.value.replace(/\./g, '').replace(',', '.'));
        values.quantity = parseInt(values.quantity);

        const options = {
            url: '/invoices/item',
            method: 'POST',
            data: values
        }

        const response = await apiInterpays.API.request(options);

        return response.data;
    }

    const { errors, touched, getFieldProps, handleChange, isSubmitting } = formik;

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off">
                <Stack padding="5px 15px 15px 15px">

                    <Stack direction={{ xs: "row", sm: 'row' }} justifyContent="space-between">
                        <div style={{ width: 150 }}>
                            <Typography variant="body1"><strong>Novo item</strong></Typography>
                        </div>
                        <Button onClick={() => props.onClose()} style={{ width: 20 }} color="borderless">
                            <div>
                                <MdClose size={15} />
                            </div>
                        </Button>
                    </Stack>

                    <Stack direction={{ sm: 'column', md: 'row' }} marginTop={2}>
                        <TextField
                            fullWidth
                            multiline
                            maxRows={3}
                            size="small"
                            label="Descrição"
                            {...getFieldProps("description")}
                            error={Boolean(errors.description && touched.description)}
                            helperText={touched.description && errors.description}

                        />
                    </Stack>

                    <Stack direction={{ sm: 'column', md: 'row' }} marginTop={2}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Quantidade"
                            {...getFieldProps("quantity")}
                            error={Boolean(errors.quantity && touched.quantity)}
                            helperText={touched.quantity && errors.quantity}
                        />
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" marginTop={2} spacing={2}>
                        <TextField
                            size="small"
                            label="Valor"
                            fullWidth
                            name="value"
                            onChange={event => {
                                event.target.value = Mask.toMoney(event.target.value);
                                handleChange(event);
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>
                            }}
                            error={Boolean(errors.value && touched.value)}
                            helperText={touched.value && errors.value}
                        />
                    </Stack>

                    <Stack direction={{ sm: 'column', md: 'row' }} justifyContent="flex-end" marginTop={2}>
                        <Button type="submit" load={isSubmitting} typeLoad="pacman" color="turquoise">Salvar</Button>
                    </Stack>

                </Stack>
            </Form>
        </FormikProvider>
    )
}