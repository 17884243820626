import { FormikProps } from "formik";
import { Uploader } from "rsuite";
import { HelperTextError } from "../../style";
import { CgFileDocument } from 'react-icons/cg';
import { colors } from "../../../../styles/global";
import { BsPersonVcard, BsPostcard } from 'react-icons/bs';
import { useState } from "react";
import { Button } from "../../../../components/Button";
import { SlTrash } from "react-icons/sl";

type IProps = {
    typeDoc: string;
    formik: FormikProps<IFormikProps>;
}

type IFormikProps = {
    typeDoc: string;
    fileFrontDOCPF: string;
    fileVersusDOCPF: string;
    // fileCIPJ: string;
    fileCSPJ: string;
    type: string;
}

export function FormPj(props: IProps) {
    const [docImage, setDocImage] = useState({
        fileFrontDOCPF: '',
        fileVersusDOCPF: ''
    });

    const handleImage = async (file: any, field: string) => {
        setFieldValue(field, file)
        let _file = await ConvertFile(file);
        setDocImage(prev => ({ ...prev, [field]: _file }));
    }

    const handleDelete = async (field: any) => {
        setFieldValue(field, '')
        setDocImage(prev => ({ ...prev, [field]: '' }));
    }

    const ConvertFile = async (file: any) => {
        const convert = async () => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };

                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(file[0].blobFile);
            });
        }

        return await convert()
    }
    const { errors, setFieldValue, touched, values } = props.formik;

    return (
        <div style={{ display: "flex", gap: "10px", justifyContent: "center", flexDirection: 'column', alignItems: "center" }}>
            {
                props.typeDoc === 'Contrato social + RG' && <>
                    <Uploader
                        multiple={false}
                        accept="application/pdf"
                        autoUpload={false}
                        action="/upload"
                        onChange={(file: any) => setFieldValue('fileCSPJ', file)}
                        onError={() => setFieldValue('fileCSPJ', null)}
                        style={{ border: "1px dashed #cacaca", borderRadius: "5px", maxWidth: "250px" }}>
                        <div style={{
                            height: 60,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: "15px",
                            cursor: "pointer",
                            padding: "10px",
                            width: 250
                        }}>
                            <CgFileDocument size={25} color={colors.textLight} />
                            <span style={{ textAlign: "center", marginLeft: 5 }}>Contrato Social</span>
                        </div>
                    </Uploader>
                    {errors.fileCSPJ && touched.fileCSPJ && (<HelperTextError>{errors.fileCSPJ}</HelperTextError>)}
                </>
            }

            {
                Boolean(values.fileFrontDOCPF) ?
                    <div style={{ marginBottom: 25 }}>
                        <div>
                            <img src={docImage.fileFrontDOCPF} alt="Foto" style={{ width: "250px", maxWidth: '250px' }} />
                        </div>
                        <div style={{ position: "absolute", marginTop: "-25px", marginLeft: "155px" }}>
                            <Button color="turquoise" borderRounded onClick={e => handleDelete('fileFrontDOCPF')}>
                                <SlTrash size={18} />
                            </Button>
                        </div>
                    </div>
                    :
                    <>
                        <Uploader
                            multiple={false}
                            accept="image/*"
                            autoUpload={false}
                            action="/upload"
                            onChange={(file: any) => handleImage(file, 'fileFrontDOCPF')}
                            onError={() => setFieldValue('fileFrontDOCPF', null)}
                            style={{ border: "1px dashed #cacaca", borderRadius: "5px", maxWidth: "250px" }}>
                            <div style={{
                                height: 150,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: "15px",
                                cursor: "pointer",
                                padding: "10px",
                                width: 250
                            }}>
                                <BsPersonVcard
                                    size={25} color={colors.textLight} />
                                <span style={{ textAlign: "center", marginLeft: 5 }}>Frente do {props.typeDoc === 'RG' ? 'RG' : 'CNH'}</span>
                            </div>
                        </Uploader>
                        {errors.fileFrontDOCPF && touched.fileFrontDOCPF && (<HelperTextError>{errors.fileFrontDOCPF}</HelperTextError>)}
                    </>
            }

            {
                Boolean(values.fileVersusDOCPF) ?
                    <div style={{ marginBottom: 25 }}>
                        <div>
                            <img src={docImage.fileVersusDOCPF} alt="Foto" style={{ width: "250px", maxWidth: '250px' }} />
                        </div>
                        <div style={{ position: "absolute", marginTop: "-25px", marginLeft: "155px" }}>
                            <Button color="turquoise" borderRounded onClick={e => handleDelete('fileVersusDOCPF')}>
                                <SlTrash size={18} />
                            </Button>
                        </div>
                    </div>
                    :
                    <>
                        <Uploader
                            multiple={false}
                            accept="image/*"
                            autoUpload={false}
                            action="/upload"
                            onChange={(file: any) => handleImage(file, 'fileVersusDOCPF')}
                            onError={() => setFieldValue('fileVersusDOCPF', null)}
                            style={{ border: "1px dashed #cacaca", borderRadius: "5px", maxWidth: "250px" }}>
                            <div style={{
                                height: 150,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: "15px",
                                cursor: "pointer",
                                padding: "10px",
                                width: 250
                            }}>
                                <BsPostcard
                                    size={25} color={colors.textLight} />
                                <span style={{ textAlign: "center", marginLeft: 5 }}>Verso do {props.typeDoc === 'RG' ? 'RG' : 'CNH'}</span>
                            </div>
                        </Uploader>
                        {errors.fileVersusDOCPF && touched.fileVersusDOCPF && (<HelperTextError>{errors.fileVersusDOCPF}</HelperTextError>)}
                    </>
            }
        </div>
    )
}