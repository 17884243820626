import { useState } from "react";
import { Card, Grid } from "@mui/material";
import { AppContent } from "../../../../components/AppContent";
import { Breadcrumps } from "../../../../components/Breadcrumbs";
import { PixIcon } from "../../../../components/Icon";
// import { RecentList } from "./RecentList";
import { SearchKey } from "./SearchKey";
import { Finish } from "./Finish";
import { AccountList } from "./AccountList";

export function Pix() {
    const [accountInterpays, setAccountInterpays] = useState<any>(null);
    const [accountDestiny, setAccountDestiny] = useState<any>(null);
    const [steps, setSteps] = useState(0);

    return (
        <AppContent>
            <Breadcrumps
                Icon={PixIcon}
                title="Pix"
                links={[
                    { name: 'Transferência', href: '#' },
                    { name: 'Pix', href: '/app/pix' }
                ]}
            />

            <Grid container justifyContent="center" padding="0 15px" marginBottom={5}>
                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ maxHeight: 660, minHeight: 150, borderRadius: 5, paddingBottom: 1 }}>
                        {
                            steps === 0 && (
                                <AccountList
                                    setAccountSelected={(account: any) => {
                                        setAccountInterpays(account)
                                        setSteps(1);
                                    }
                                    } />
                            )
                        }
                        {
                            steps === 1 && (
                                <SearchKey
                                    account={accountInterpays}
                                    setDestiny={(destiny: any) => {
                                        setAccountDestiny(destiny)
                                        setSteps(2)
                                    }}
                                    onBack={() => {
                                        setAccountInterpays(null);
                                        setSteps(0)
                                    }}
                                />
                            )
                        }
                        {
                            steps === 2 && (
                                <Finish
                                    accountInterpays={accountInterpays}
                                    account={{
                                        account: accountDestiny.conta,
                                        agency: accountDestiny.agencia,
                                        bank: accountDestiny.banco,
                                        doc: accountDestiny.cpf || accountDestiny.cnpj,
                                        owner: accountDestiny.nome,
                                        key: accountDestiny.key,
                                        typeKey: accountDestiny.typeKey
                                    }}
                                    onReset={() => {
                                        setAccountDestiny(null);
                                        setAccountInterpays(null);
                                        setSteps(0);
                                    }}
                                    onBack={() => {
                                        setAccountDestiny(null)
                                        setSteps(1);
                                    }}

                                />
                            )
                        }
                        {/* <RecentList /> */}
                    </Card>
                </Grid>
            </Grid>
        </AppContent>
    )
}