import { useState } from 'react';

import logoInterpaysSmall from '../../assets/logo2.png';
import logoInterpaysBig from '../../assets/logo1.png';

import logoAnnaSmall from '../../assets/logoAnna1.png';
import logoAnnaBig from '../../assets/logoAnna2.png';

import { FormRegister } from './FormRegister';
import { FormUploadDocuments } from './FormUploadDocuments';

import { Container, Content, Footer, Card, Header } from './style';
import { Button } from '../../components/Button';
import { SlOptions, SlCheck } from 'react-icons/sl';
import { FormValidatePhone } from './FormValidatePhone';
import { FormValidateEmail } from './FormValidateEmail';
import { Link } from 'react-router-dom';
import { FormPassword } from './FormPassword';

interface IRegister {
    id: string;
    type: string,
    doc: string,
    name: string,
    birthdate: Date | null,
    phone: string,
    term: boolean,
    companyName: string,
    email: string
    step: number,
    fileFrontDOCPF: string,
    fileVersusDOCPF: string,
    fileCIPJ: string,
    fileCSPJ: string,
}

export function Register() {
    const [data, setData] = useState<IRegister>({
        id: '',
        type: '',
        doc: '',
        name: '',
        birthdate: null,
        phone: '',
        term: false,
        companyName: '',
        email: '',
        step: 0,
        fileFrontDOCPF: '',
        fileVersusDOCPF: '',
        fileCIPJ: '',
        fileCSPJ: ''
    });

    document.title = 'Annabank | Abra sua conta';

    const handleOnData = (onData: IRegister) => {
        setData(onData)
    }

    return (
        <Container>
            <Header style={{ zIndex: 999 }}>
                <img className={window.location.hostname.indexOf('interpays.com.br') > -1 ? '' : 'logo-top-annabank'} src={window.location.hostname.indexOf('interpays.com.br') > -1 ? logoInterpaysBig : logoAnnaSmall} alt={window.location.hostname.indexOf('interpays.com.br') > -1 ? 'Interpays Bank' : 'Anna Bank'} />
                <Button color='transparent'>
                    <SlOptions size={18} />
                </Button>
            </Header>
            <Content>
                <Card style={{ justifyContent: 'center' }}>
                    <img className={window.location.hostname.indexOf('interpays.com.br') > -1 ? '' : 'logo-form-annabank'} src={window.location.hostname.indexOf('interpays.com.br') > -1 ? logoInterpaysSmall : logoAnnaBig} alt={window.location.hostname.indexOf('interpays.com.br') > -1 ? 'Interpays Bank' : 'Anna Bank'} />
                    {(data.step === 0) && (<FormRegister setOnData={data => handleOnData(data)} />)}

                    {data.step === 1 && <FormValidatePhone data={data} setOnData={data => handleOnData(data)} />}

                    {data.step === 2 && <FormValidateEmail data={data} setOnData={data => handleOnData(data)} />}

                    {(data.step === 3) && (<FormUploadDocuments data={data} setOnData={data => handleOnData(data)} />)}

                    {(data.step === 4) && (<FormPassword data={data} setOnData={data => handleOnData(data)} />)}

                    {(data.step === 5) && (
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: "60px", padding: "0px 50px" }}>
                            <div style={{ padding: "30px", display: "flex", gap: "15px", border: "1px solid #cacaca", borderRadius: "5px", background: "#f7f8f9" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <SlCheck size={52} style={{ color: '#007d0c' }} />
                                </div>
                                <div>
                                    <span style={{ fontSize: "18px", textAlign: "center" }}>Agradecemos seu cadastro, inicie sua sessão para ter acesso ao nosso sistema, breve enviaremos suas credencias de acesso a api.</span>
                                </div>
                            </div>
                            <div className='flex-direction-column display-flex text-align-center' style={{ marginBottom: 15 }}>
                                <Link to="/login" style={{ fontSize: '14px', margin: '25px 0' }}>Iniciar Sessão</Link>
                            </div>
                        </div>)
                    }
                </Card>
            </Content>
            <Footer>
                <div className='content'>
                    <div>
                        <a href="/privacy" target="_blank" rel=''>Política de privavidade</a>
                        <span className='separator'>|</span>
                        <a href="/police" target="_blank" rel=''>Alerta de privavidade</a>
                        <span className='separator'>|</span>
                        <a href="/terms-and-conditions" target="_blank" rel=''>Termos e condições</a>
                        <span className='separator'>|</span>
                        <a href="/cookies" target="_blank" rel=''>Política de Cookies</a>
                    </div>

                    <div>
                        <span>Copyright &copy; {new Date().getFullYear()} {window.location.hostname.indexOf('interpays.com.br') > -1 ? 'Interpays Bank' : 'Anna Bank'}. Todos os direitos reservados.</span>
                    </div>

                </div>
            </Footer>
        </Container>
    )
}