import axios, { AxiosHeaders } from 'axios';
import * as auth from '../auth';

let interceptorAuth: any = null;

// export const URL_API = 'http://127.0.0.1:5001/manager';
export const URL_API = 'https://api.annabank.com.br/manager';

export const API = axios.create({
    baseURL: URL_API
});

export const setInterceptorAuth = () => {
    interceptorAuth = API.interceptors.request.use(config => {
        const token = auth.getToken();

        (config.headers as AxiosHeaders).set('Authorization', `Bearer ${token ? token : ''}`);

        return config;
    });
}

export const removeInterceptorAuth = () => {
    API.interceptors.request.eject(interceptorAuth);
}

if (auth.isAuthenticated())
    setInterceptorAuth();