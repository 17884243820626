import { useState } from 'react';
import { HelperTextError } from '../style';
import { Input, InputGroup } from 'rsuite';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { useSnackbar } from 'notistack';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormikProvider, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import Mask from 'vanilla-masker';
import * as snackConfig from '../../../utils/configDefaultSnackbar';
import * as apiInterpays from '../../../services/apiInterpays';
import { Typography } from '@mui/material';

const schemaForm = Yup.object().shape({
    doc: Yup.string().required('informe seu CPF/CNPJ'),
    password: Yup.string()
        .required('informe sua senha')
        .min(8, 'senha precisa ter no minimo 8 caracteres')
        .test('validPassword', 'Senha não pode conter espaços', (value, context) => {
            return typeof value !== 'undefined' && value?.indexOf(' ') < 0;
        })
});

interface IProps {
    setTokenLogin: (token: string) => void;
    onShowForgot: (show: boolean) => void;
}

export function FormLogin({ setTokenLogin, onShowForgot }: IProps) {
    const { enqueueSnackbar } = useSnackbar();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [showPassword, setShowPassword] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            doc: '',
            password: '',
        },
        validationSchema: schemaForm,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                if (!executeRecaptcha) {
                    enqueueSnackbar('Ocorreu um erro no recaptcha!', snackConfig.warning);
                    return;
                }
                const resultCaptcha = await executeRecaptcha('login');
                const result = await handleLogin({ ...values, token: resultCaptcha });
                setTokenLogin(result.accessToken);
                // auth.cookies.set('auth', result.accessToken);
                // apiInterpays.setInterceptorAuth();
                // navigate('/app/dashboard');
            } catch (error: any) {
                const message = error.response?.data.message ?? 'Ocorreu um erro ao fazer requisição';
                enqueueSnackbar(message, snackConfig.warning);
            }
        }
    });

    const handleLogin = async ({ token, doc, password }: { token: string, doc: string, password: string }) => {
        const optionsRequest = {
            method: 'POST',
            url: '/authorize',
            data: {
                doc: doc.replace(/\D/g, ''),
                password,
                token
            }
        }

        const response = await apiInterpays.API.request(optionsRequest);

        return response.data;
    }

    const { errors, touched, isSubmitting, setFieldValue, values } = formik;

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='h5' sx={{ fontSize: 28, color: 'black' }} marginTop={3} >Iniciar sessão</Typography>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" className='form'>

                    <InputGroup >
                    
                        <Input placeholder="CPF/CNPJ"
                            value={values.doc.length <= 14 ? Mask.toPattern(values.doc, '999.999.999-99') : Mask.toPattern(values.doc, '99.999.999/9999-99')}
                            size='lg'
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9./-]*"
                            onChange={text => {
                                setFieldValue('doc', text);
                            }}
                        />
                    </InputGroup>
                    {errors.doc && touched.doc && (<HelperTextError>{errors.doc}</HelperTextError>)}

                    <InputGroup className='margin-top1' size='lg' >
                        <Input
                            placeholder="Senha"
                            type={showPassword ? 'text' : 'password'}
                            onChange={text => setFieldValue('password', text)}
                        />
                        <InputGroup.Addon style={{ background: 'white' }}>
                            <button className='button-next' type='button' onClick={() => setShowPassword(!showPassword)}>
                                {
                                    showPassword ? <AiOutlineEyeInvisible size={15} /> : <AiOutlineEye size={15} />
                                }
                            </button>
                        </InputGroup.Addon>
                    </InputGroup>
                    {errors.password && touched.password && (<HelperTextError>{errors.password}</HelperTextError>)}


                    <Button type='submit' color='turquoise' typeLoad='clock' load={isSubmitting} className='margin-top2' style={{ height: 36, alignItems: 'center', fontSize: '16px' }}>
                        Entrar
                    </Button>
                    {/*<Button color="turquoise" className="button-open-account-login" type="link" to="/register" style={{ height: 36, alignItems: 'center', fontSize: '16px', marginTop:"10px" }}>Abra sua conta</Button>*/}
                    <div className='flex-direction-column display-flex text-align-center margin-top2'>
                        <Link to="#" onClick={() => onShowForgot(true)} style={{ fontSize: '14px' }}>Esqueceu sua senha?</Link>
                        {/* <Link to="#" style={{ marginTop: 10 }}>Criar uma conta</Link> */}
                    </div>
                </Form>
            </FormikProvider>
        </div>
    )
}