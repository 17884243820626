import { OptionsObject } from 'notistack';
import { ActionCloseNotification } from '../components/ManagerNotifications/ActionCloseNotification';

export const success: OptionsObject = {
    variant: 'success',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
    },
    autoHideDuration: 3000,
    action: (key) => (<ActionCloseNotification snackbarId={key} />)
}

export const warning: OptionsObject = {
    variant: 'warning',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
    },
    autoHideDuration: 3000,
    action: (key) => (<ActionCloseNotification snackbarId={key} />)
}

export const info: OptionsObject = {
    variant: 'info',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
    },
    autoHideDuration: 3000,
    action: (key) => (<ActionCloseNotification snackbarId={key} />)
}

export const error: OptionsObject = {
    variant: 'error',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
    },
    autoHideDuration: 3000,
    action: (key) => (<ActionCloseNotification snackbarId={key} />)
}