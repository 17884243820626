import { useState } from 'react';
import { Input, InputGroup } from 'rsuite';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { Button } from '../../../../components/Button';
import { useSnackbar } from 'notistack';
import { FormikProvider, Form, useFormik } from 'formik';
import { HelperTextError } from './style';
import * as Yup from 'yup';

import * as snackConfig from '../../../../utils/configDefaultSnackbar';
import * as apiInterpays from '../../../../services/apiInterpays';
import { Typography } from '@mui/material';

const schemaForm = Yup.object().shape({
    password: Yup.string()
        .required('informe sua senha')
        .min(8, 'senha precisa ter no minimo 8 caracteres')
        .test('validPassword', 'Senha não pode conter espaços', (value, context) => {
            return typeof value !== 'undefined' && value?.indexOf(' ') < 0;
        }),
    confirmPassword: Yup.string()
        .required('informe sua senha')
        .min(8, 'senha precisa ter no minimo 8 caracteres')
        .test('validPassword', 'Senha não pode conter espaços', (value, context) => {
            return typeof value !== 'undefined' && value?.indexOf(' ') < 0;
        })
        .test('comparePassword', 'Senha de confirmação está diferente', (value, context) => {
            return value === context.parent.password;
        })
});

interface IProps {
    hash: string,
    user: {
        name: string
    }
    onBack: () => void;
}

export function Password(props: IProps) {
    const { enqueueSnackbar } = useSnackbar();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: schemaForm,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                const result = await handleSubmit(values);
                enqueueSnackbar(result.message, snackConfig.success);
                props.onBack();
            } catch (error: any) {
                const message = error.response?.data.message ?? 'Ocorreu um erro ao fazer requisição';
                enqueueSnackbar(message, snackConfig.warning);
            }
        }
    });

    const handleSubmit = async ({ password, confirmPassword }: { confirmPassword: string, password: string }) => {
        const optionsRequest = {
            method: 'PUT',
            url: `/forgot/${props.hash}`,
            data: {
                password: password
            }
        }

        const response = await apiInterpays.API.request(optionsRequest);

        return response.data;
    }

    const { errors, touched, isSubmitting, setFieldValue } = formik;

    return (

        <div className='text-align-center'>

            <Typography variant='h6' marginTop={3}>Olá, {props.user?.name}</Typography>
            <Typography variant='body1'>Defina sua nova senha</Typography>

            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" className='form'>

                    <InputGroup className='margin-top1' size='lg' >
                        <Input placeholder="Nova senha" type={showPassword ? 'text' : 'password'} onChange={text => setFieldValue('password', text)} />
                        <InputGroup.Addon style={{ background: 'white' }}>
                            <button className='button-next' type='button' onClick={() => setShowPassword(!showPassword)}>
                                {
                                    showPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />
                                }
                            </button>
                        </InputGroup.Addon>
                    </InputGroup>
                    {errors.password && touched.password && (<HelperTextError>{errors.password}</HelperTextError>)}

                    <InputGroup className='margin-top1' size='lg' >
                        <Input placeholder="Confirmar senha" type={showConfirmPassword ? 'text' : 'password'} onChange={text => setFieldValue('confirmPassword', text)} />
                        <InputGroup.Addon style={{ background: 'white' }}>
                            <button className='button-next' type='button' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                {
                                    showConfirmPassword ? <AiOutlineEyeInvisible size={30} /> : <AiOutlineEye size={30} />
                                }
                            </button>
                        </InputGroup.Addon>
                    </InputGroup>
                    {errors.confirmPassword && touched.confirmPassword && (<HelperTextError>{errors.confirmPassword}</HelperTextError>)}


                    <Button type='submit' color='turquoise' typeLoad='clock' load={isSubmitting} className='margin-top2' style={{ marginRight: 10, minHeight: 36, height: 36, alignItems: 'center', fontSize: '16px' }}>Definir senha</Button>
                </Form>
            </FormikProvider>

        </div>
    )
}