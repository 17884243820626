import styled from 'styled-components';

export const Container = styled.div`
    background: var(--turquoise);
    color: white;
    min-height: 160px;

    #amount{
        padding: 25px 25px 10px 25px;
        
    }

    button#add-value {
        width: 100%;
        background: var(--turquoise);
        padding: 20px 20px;
        border-end-end-radius: 10px;
        border-end-start-radius: 10px;
        border-top: solid 1px rgba(255, 255, 255, 0.2);
        transition: filter 0.3s;

        &:hover{
            filter: brightness(1.1);
        }
    }
`