import { Button } from "../Button";
import { SnackbarKey, useSnackbar } from 'notistack';
import { MdClose } from 'react-icons/md'

interface IProps {
    snackbarId: SnackbarKey
}

export function ActionCloseNotification({ snackbarId }: IProps) {
    const { closeSnackbar } = useSnackbar();
    return (
        <Button color="transparent" onClick={() => closeSnackbar(snackbarId)}><MdClose color="white" /></Button>
    )
}