import styled from 'styled-components';


export const Container = styled.div`
    max-width: 350px;
    padding-top: 0.5rem;
    color: var(--text-light);

    header {
        padding: 0.5rem 1rem;
        border-bottom: 1px dashed var(--dark-light);
        span {
            font-size: 0.9rem;
            color: var(--dark);
        }
    }

    a{
        display: block;
        text-align: center;
        padding: 0.5rem 0;
        font-weight: 600;
        transition: background 0.2s;
        text-decoration: none;
        color: var(--turquoise);

        &:hover{
            background: var(--turquoise);
            color: var(--white);
        }
    }
`;

export const Content = styled.div`
    /* padding: 0.5rem 1rem; */
    max-height: 330px;
    overflow-y: auto;

    .subcategory {
        color: var(--dark);
        display: block;
        margin: 0.5rem 1rem;
    }
  
`;