import { Box, Typography, IconButton } from '@mui/material';
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import logo from "../../../assets/logos/logoFooter.png";

const Footer = () => {
  return (
    <Box 
      component="footer" 
      sx={{
        backgroundColor: '#00579f', // Cor primária aplicada
        padding: { xs: '0.5rem', sm: '1rem', md: '1.5rem', lg: '2rem', xl: '2.5rem' },
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        alignItems: { xs: 'center', md: 'flex-start' },
        textAlign: { xs: 'center', md: 'left' },
        gap: { xs: '2rem', md: '0' }
      }}
    >
      {/* Logo e Redes Sociais */}
      <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '100%', md: '50%' }, alignItems: { xs: 'center', md: 'flex-start' }, padding: { xs: '0.5rem', sm: '1rem', md: '1.5rem', lg: '2rem', xl: '2.5rem' } }}>
        <Box
          component="img"
          src={logo}
          alt="Logo Annabank"
          sx={{ width: { xs: '8rem', sm: '9rem', md: '10rem' }, marginBottom: '1rem' }}
        />
        <Typography variant="body2" sx={{ marginBottom: '1rem', color: 'white' }}>
          Copyright © 2024. Direitos reservados.
        </Typography>
        <Typography variant="body2" sx={{ color: 'white' }}>
          CNPJ n.º 49.372.130/0001-44.<br />
        </Typography>
        <Box sx={{ display: 'flex', gap: '1rem', mt : 2 }}>
          <IconButton href="https://www.facebook.com/profile.php?id=61551620023930" target="_blank" aria-label="Facebook" sx={{ color: 'white' }}>
            <FaFacebook />
          </IconButton>
          <IconButton href="https://www.instagram.com/annabank.com.br/" target="_blank" aria-label="Instagram" sx={{ color: 'white' }}>
            <FaInstagram />
          </IconButton>
          <IconButton href="https://wa.me/556230914896" target="_blank" aria-label="Whatsapp" sx={{ color: 'white' }}>
            <FaWhatsapp />
          </IconButton>
        </Box>
      </Box>

      {/* Informações da Empresa */}
      <Box sx={{ marginTop: { xs: '2rem', md: '0' }, padding: { xs: '2rem', sm: '2.5rem', md: '3rem', lg: '4rem', xl: '5rem' } }}>

      </Box>
    </Box>
  );
}

export default Footer;
