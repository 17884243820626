import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 120px);

    p {
        margin-top:0px
        margin-bottom:15px
    }

    .containt-privacy {
        padding:50px;
        display:flex;
        gap:50px;
        flex-direction:column
    }

    #video-home {
        width: 100%;
        height:250px;
        object-fit:cover;
        bottom: 0;
    }

    .box-privacy {
        marginTop:25px;
        display:flex;
        width:100%;
        gap:10px
    }

    .privacy-title{
        font-size:38px;
        color:#0d7e8b;
        margin-top:-50px;
        margin-left:45px;
        z-index:999
    }

    @media(max-width: 790px){
        .box-privacy {
            flex-direction:column;
            div:first-child {
                width: 100% !important;
            }
            div:last-child {
                width: 100% !important;
            }
        } 
        .containt-privacy {
            padding:25px;
        }
        .privacy-title{ 
            margin-top:-100px;
            margin-left:25px;
        }
    }
`

export const Content = styled.div`
    width: 100%;
    position: absolute;
    /* max-width: 1936px; */
    /* padding: 15px 0; */
    justify-content: center;
    display: flex;
    z-index: 1;

   
`;
