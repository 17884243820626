import { colors } from "../../styles/global";
import { IconType } from "react-icons";
import { MdChevronRight, MdFilterAlt, MdHome } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";
import { Container, ContentLeft, ContentRight, ListLinks } from "./styles";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { useEffect } from "react";

interface ILinks {
  name: string;
  href: string;
}

interface IBreadcrumps {
  Icon?: IconType | any;
  title?: string;
  links?: Array<ILinks>;
  onClickButtonFilter?: () => void;
  onClickButtonExport?: () => void;
}

export function Breadcrumps({ Icon, title, links, onClickButtonFilter, onClickButtonExport }: IBreadcrumps) {
  links = links ?? [];

  useEffect(() => {
    document.title = `${title} | Anna Bank`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ContentLeft>
        <div>
          {Icon && <Icon size={25} width={25} height={25} color={colors.white} style={{ marginRight: 5 }} />}
          <h5>
            <strong>{title}</strong>
          </h5>
        </div>

        <ListLinks>
          <Link to="/app/dashboard">
            <MdHome size={13} color={colors.white} />
          </Link>

          <MdChevronRight size={15} color={colors.white} />

          {links.map((item, indice) => {
            return indice + 1 === links?.length ? (
              <span key={`${item}-${Math.random().toString(36).slice(2)}`} style={{ color: "var(--dark-light)" }}>
                {item.name}
              </span>
            ) : (
              <div key={`${item}-${Math.random().toString(36).slice(2)}`}>
                <Link to={item.href}>{item.name}</Link>
                <MdChevronRight size={15} color={colors.white} />
              </div>
            );
          })}
        </ListLinks>
      </ContentLeft>

      <ContentRight>
        {onClickButtonExport && (
          <Button onClick={onClickButtonExport} color="turquoise" borderRounded>
            <SiMicrosoftexcel size={20} />
          </Button>
        )}
        {onClickButtonFilter && (
          <Button onClick={onClickButtonFilter} color="turquoise" borderRounded>
            <MdFilterAlt size={20} />
          </Button>
        )}
      </ContentRight>
    </Container>
  );
}
