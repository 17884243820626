import { useEffect, useState } from "react";
import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { colors } from "../../../../../styles/global";
import { Button } from "../../../../../components/Button";
import Mask from 'vanilla-masker';
import { PulseLoader } from "react-spinners";
import * as apiInterpays from '../../../../../services/apiInterpays';
import { handleError } from "../../../../../utils/handleRequestError";
import { useSnackbar } from "notistack";
import * as utils from '../../../../../utils/utils';
import * as configSnack from '../../../../../utils/configDefaultSnackbar';
import { SuccessIcon } from "../../../../../components/Icon";
import { MdOutlineChevronLeft } from "react-icons/md";


interface IProps {
    accountInterpays: any;
    account?: {
        owner: string;
        doc: string;
        bank: string;
        agency: string;
        account: string;
        key: string;
        typeKey: string;
    }
    onReset: () => void;
    onBack: () => void;
}

export function Finish(props: IProps) {
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = useState('0,00');
    const [searchingBalance, setSearchingBalance] = useState(true);
    const [balance, setBalance] = useState(0);
    const [messageError, setMessageError] = useState<string | null>(null);
    const [firstSubmit, setFirstSubmit] = useState(false);
    const [concluded, setConcluded] = useState(false);
    const [saving, setSaving] = useState(false);

    const handleOnSubmit = async () => {
        if (!firstSubmit) setFirstSubmit(true);

        if (!messageError) {
            setSaving(true);
            try {
                await transfer();
                setConcluded(true);
            } catch (error: any) {
                const message = error.response?.data.message ?? 'Ocorreu um erro ao fazer requisição';
                enqueueSnackbar(message, configSnack.warning);
            }
            setSaving(false);
        }
    }

    const checkValue = () => {
        const valueConverted = parseFloat(value.replace(/\./g, '').replace(',', '.'));

        if (valueConverted > balance) {
            setMessageError('Valor informado é maior que saldo disponível!');
        } else if (valueConverted <= 0) {
            setMessageError('Informe um valor maior que 0!')
        } else {
            setMessageError(null);
        }
    }


    const transfer = async () => {
        const valueConverted = parseFloat(value.replace(/\./g, '').replace(',', '.'));

        const options = {
            method: 'POST',
            url: '/transfer/pix',
            data: {
                virtualAccount: props.accountInterpays,
                keyPix: props.account?.key,
                typeKey: props.account?.typeKey,
                value: valueConverted
            }
        }

        const response = await apiInterpays.API.request(options);

        return response.data;
    }

    const searchBalance = async () => {
        await apiInterpays.API.get(`/dashboard/balances/${props.accountInterpays}`)
            .then(({ data }) => {
                setBalance(data[0].balance);
            }).catch(({ response }) => handleError(response, enqueueSnackbar))
            .finally(() => setSearchingBalance(false));
    }

    useEffect(() => {
        searchBalance();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (firstSubmit) checkValue();
        //eslint-disable-next-line
    }, [value]);

    if (concluded) {
        return (
            <Stack justifyContent="center" padding={2}>
                <Stack spacing={2} padding={2} sx={{ overflowY: 'hidden' }} direction={{ xs: 'row' }} justifyContent="center" alignItems="center">
                    <SuccessIcon /> <Typography variant="h6" color={colors.success}>Transferência pix solicitado com sucesso!</Typography>
                </Stack>
                <Button color="turquoise" type="button" onClick={props.onReset}>Nova transferência</Button>
            </Stack>
        )
    } else {
        return (
            <Stack padding={2} spacing={3}>
                <Stack direction="row" alignContent="center" alignItems="center" >

                    <Button type="button" onClick={props.onBack} color="borderless" style={{ height: 35, width: 35, padding: 2, alignItems: 'center' }}><MdOutlineChevronLeft size={18} /></Button>
                    <div className="display-flex flex-direction-column">
                        <Typography variant="caption" color={colors.textLight}>Conta</Typography>
                        <h5>{props.accountInterpays.name}</h5>
                    </div>
                </Stack>

                <Stack>
                    <h6>Saldo disponível</h6>
                    {
                        searchingBalance ?
                            <PulseLoader color={colors.turquoise} size={9} style={{ marginTop: 5 }} speedMultiplier={0.7} />
                            :
                            <span style={{ color: colors.turquoise, fontSize: 28 }}>{utils.toMoney(balance)}</span>

                    }
                </Stack>

                <Stack direction={{ sm: 'row', md: 'row' }} justifyContent="space-between">
                    <strong>Titular</strong>
                    <span>{props.account?.owner}</span>
                </Stack>

                <Stack direction={{ sm: 'row', md: 'row' }} justifyContent="space-between">
                    <strong>Documento</strong>
                    <span>{props.account?.doc}</span>
                </Stack>

                <Stack direction={{ sm: 'row', md: 'row' }} justifyContent="space-between">
                    <strong>Banco</strong>
                    <span>{props.account?.bank}</span>
                </Stack>

                <Stack direction={{ sm: 'row', md: 'row' }} justifyContent="space-between">
                    <strong>Agência</strong>
                    <span>{props.account?.agency}</span>
                </Stack>

                <Stack direction={{ sm: 'row', md: 'row' }} justifyContent="space-between">
                    <strong>Conta</strong>
                    <span>{props.account?.account}</span>
                </Stack>

                <Stack direction={{ sm: 'row', md: 'row' }} justifyContent="space-between">
                    <strong>Chave pix</strong>
                    <span>{props.account?.key}</span>
                </Stack>

                <Stack >
                    <TextField
                        label="Valor"
                        size="small"
                        onChange={event => {
                            event.target.value = Mask.toMoney(event.target.value);
                            setValue(event.target.value);
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                        }}
                        error={firstSubmit && Boolean(messageError)}
                        helperText={firstSubmit && messageError}
                    />
                </Stack>

                <Stack>
                    {
                        balance > 0 && (
                            <Button
                                type="button"
                                onClick={handleOnSubmit}
                                load={saving}
                                color="turquoise">
                                Transferir
                            </Button>
                        )
                    }
                </Stack>
            </Stack>
        )
    }
}