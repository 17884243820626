import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    width: calc(100% + 80px);
    margin-left: -80px;
    padding: 0.25rem 0.5rem;
    padding-left: 85px;
    position: sticky;
    top: 0;
    background: var(--turquoise);
    min-height: 64px;

    button {
        cursor: pointer;
        border: 0;
        padding: 0.5rem;
        border-radius: 5rem;
        background: transparent;
        color: var(--white);
        transition: transform 0.2s;

        &:hover{
            transform: scale(1.15);
        }

        &:not(:last-child){
            margin-right: 1rem;
        }

    }
`;


export const Content = styled.div`
    display: flex;
    align-items: center;
    
`
