import styled, { css } from 'styled-components';

interface IPropsContainer {
    menuIsOpen: boolean;
}

const poppoverMenu = (top: number) => {
    return css`
        div {
            span {
                display: block;
                color: var(--turquoise);
            }
        }
    `
}

export const Container = styled.div.attrs((props: IPropsContainer) => { })`
    display: flex;
    align-items: center;
    padding-top: 0.25rem;
    padding: 0.5rem 0.5rem 0.5rem 0.71rem;
    color: var(--turquoise);
    border-radius: 0.25rem;
    margin-bottom: 8px;
    height: 48px;

    div {
        height: 100%;
        display: flex;
        align-items: center;
    }

    span {
        display: ${props => props.menuIsOpen ? 'flex' : 'none'};
        margin-left: 12px;
        transition: display linear 1s;
        position: absolute;
    }

    &:hover {
        background: var(--turquoise);
        color: var(--white);
        cursor: pointer;

        ${props => !props.menuIsOpen ? poppoverMenu(props.top) : ''}
    }
`;