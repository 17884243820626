import { useState } from 'react';
import logoInterpaysSmall from '../../assets/logo2.png';
import logoInterpaysBig from '../../assets/logo1.png';

import logoAnnaSmall from '../../assets/logoAnna1.png';
import logoAnnaBig from '../../assets/logoAnna2.png';


import { Container, Content, Footer, Card, Header } from './style';
import { FormLogin } from './FormLogin';
import { FormPin } from './FormPin';
import { Forgot } from './Forgot';
import { Button } from '../../components/Button';
import { SlOptions } from 'react-icons/sl';

export function Login() {
    const [tokenAccess, setTokenAccess] = useState<string | null>(null);
    const [showForgotPassword, setShowForgout] = useState(false);

    return (
        <Container>
            <Header >
                <img className={window.location.hostname.indexOf('interpays.com.br') > -1 ? '' : 'logo-top-annabank'} src={window.location.hostname.indexOf('interpays.com.br') > -1 ? logoInterpaysBig : logoAnnaSmall} alt={window.location.hostname.indexOf('interpays.com.br') > -1 ? 'Interpays Bank' : 'Anna Bank'} />
                <Button color='transparent'>
                    <SlOptions size={18} />
                </Button>
            </Header>
            <Content>
                <Card style={{ justifyContent: 'center' }}>
                    <img className={window.location.hostname.indexOf('interpays.com.br') > -1 ? '' : 'logo-form-annabank'} src={window.location.hostname.indexOf('interpays.com.br') > -1 ? logoInterpaysSmall : logoAnnaBig} alt={window.location.hostname.indexOf('interpays.com.br') > -1 ? 'Interpays Bank' : 'Anna Bank'} />

                    {
                        !tokenAccess && !showForgotPassword && (
                            <FormLogin
                                setTokenLogin={token => setTokenAccess(token)}
                                onShowForgot={show => setShowForgout(show)}
                            />
                        )
                    }
                    {
                        tokenAccess && !showForgotPassword && (
                            <FormPin tokenLogin={tokenAccess} setTokenLogin={token => setTokenAccess(token)} />
                        )
                    }
                    {
                        showForgotPassword && (
                            <Forgot onBack={() => setShowForgout(false)} />
                        )
                    }
                </Card>
            </Content>
            <Footer>
                <div className='content'>
                    <div>
                        <a href="/politica-de-privacidade" target="_blank" rel=''>Política de privacidade</a>
                        <span className='separator'>|</span>
                        <a href="/alerta-de-privacidade" target="_blank" rel=''>Alerta de privacidade</a>
                        <span className='separator'>|</span>
                        <a href="/termos-de-uso" target="_blank" rel=''>Termos e condições</a>
                        <span className='separator'>|</span>
                        <a href="/politica-de-cookies" target="_blank" rel=''>Política de Cookies</a>
                    </div>

                    <div>
                        <span>Copyright &copy; {new Date().getFullYear()} {window.location.hostname.indexOf('interpays.com.br') > -1 ? 'Interpays Bank' : 'Anna Bank'}. Todos os direitos reservados.</span>
                    </div>

                </div>
            </Footer>
        </Container>
    )
}