import React from 'react';
import {
  Box,
  Stack,
  Typography,
  Divider,
  IconButton,
  StackProps,
} from '@mui/material';
import { MdClose } from 'react-icons/md';
import moment from 'moment';
import * as utils from '../../../../utils/utils';
import { addHours} from "date-fns";

interface StatementParty {
  nome: string;
  cpf?: string;
  cnpj?: string;
}

interface Statement {
  id: string;
  valor: number;
  origem: StatementParty[];
  destino: StatementParty[];
  tipo: string;
  data: string;
  endToEndId: string;
  cobrancaId?: string;
  natureza?: string;
}

interface DetailsProps {
  item: Statement;
  onClose: () => void;
}

interface LabelValueProps extends StackProps {
  label: string;
  value: React.ReactNode;
}

function LabelValue({ label, value, ...rest }: LabelValueProps) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      mt={2}
      {...rest}
    >
      <Typography variant="subtitle2" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body2" fontWeight="bold">
        {value}
      </Typography>
    </Stack>
  );
}

export function Details({ item, onClose }: DetailsProps) {
const dataCorrigida = new Date(item.data) //addHours(new Date(item.data), 3);
  const formatDocument = (party: StatementParty): string => {
    if (party.cpf) {
      return party.cpf
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (party.cnpj) {
      return party.cnpj
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
      return '';
    }
  };

  return (
    <Box padding={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{item.tipo}</Typography>
        <IconButton onClick={onClose}>
          <MdClose />
        </IconButton>
      </Stack>
      <Typography
        variant="h6"
        color="textSecondary"
        mt={1}
        align="center"
        fontWeight="bold"
      >
        {moment(dataCorrigida).format('DD MMM YYYY HH:mm:ss')}
      </Typography>

      {item.cobrancaId && (
        <LabelValue label="ID Cobrança" value={item.cobrancaId} />
      )}

      <LabelValue label="EndToEndID" value={item.endToEndId} />

      <LabelValue label="Valor" value={utils.toMoney(item.valor)} />

      <LabelValue label="Tipo de Lançamento" value={item.tipo} />

      <LabelValue label="Natureza" value={item.natureza || '-'} mb={3} />

      <Divider sx={{ mt: 3, mb: 2 }}>
        <Typography variant="subtitle1">Origem</Typography>
      </Divider>

      {item.origem && item.origem[0] && (
        <>
          <LabelValue label="Nome" value={item.origem[0].nome} />

          <LabelValue
            label="Documento"
            value={formatDocument(item.origem[0])}
          />
        </>
      )}

      <Divider sx={{ mt: 3, mb: 2 }}>
        <Typography variant="subtitle1">Destino</Typography>
      </Divider>

      {item.destino && item.destino[0] && (
        <>
          <LabelValue
            label="Nome"
            value={item.destino[0].nome}
          />

          <LabelValue
            label="Documento"
            value={formatDocument(item.destino[0])}
            mb={5}
          />
        </>
      )}
    </Box>
  );
}
