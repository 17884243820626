import { useContext } from "react";
import { BackDrop, Container } from "./styles";
import { Avatar } from "./Avatar";
import { MenuListItems } from "./MenuListItems";
import logoImgOpen from '../../assets/logos/logo-open.png';
import logoImgClosed from '../../assets/logos/logo-closed.png';
import { MdSegment } from "react-icons/md";
import { colors } from "../../styles/global";
import { ContextMenu } from '../../contexts/Contexts';

interface IProps {
  idUser: string | null;
  name?: string | null;
  levelAccess?: string | null;
  urlAvatar: string | null;
}

export function Menu(props: IProps) {
  const menuContext = useContext(ContextMenu);

  return (
    <>
      <Container open={menuContext.menuIsOpen}>
        <div id="container-logo">
          <div id="content-img-logo">
            <img src={menuContext.menuIsOpen ? logoImgOpen : logoImgClosed} alt="Logo AnnaBank" />
          </div>

          <button type="button" onClick={() => menuContext.handleToggleMenu?.()}>
            <MdSegment color={colors.turquoiseDark} size={24} />
          </button>
        </div>
        <MenuListItems idUser={props.idUser} />

        <Avatar name={props.name} urlAvatar={props.urlAvatar} levelAccess={props.levelAccess === 'Admin' ? 'Administrador' : 'Cliente'} />
      </Container>
      <BackDrop open={menuContext.menuIsOpen} onClick={() => menuContext.handleToggleMenu?.()} />
    </>
  );
}
