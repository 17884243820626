import { Container } from './style';
import video from '../../assets/videos/video.mp4';

export function TermsAndConditions() {
    return (
        <Container>
            <div style={{display:"flex", flexDirection:"column"}}>
                <div style={{display:"flex", flexDirection:"column"}}>
                    <video autoPlay muted loop id="video-home">
                        <source src={video} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                    <span className='privacy-title'>Termos e Condições de uso</span>
                </div>
                <div className="containt-privacy">
                    <div style={{fontSize:"16px", color:"#666"}}>
                        <p>Esta plataforma, cujo nome é <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, é de propriedade e mantida por <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS SOLUÇÕES DE PAGAMENTOS LTDA' : 'ANNA BANK SOLUÇÕES DE PAGAMENTOS LTDA'}</strong> , pessoa jurídica de direito privado, inscrita no CNPJ sob o nº {window.location.hostname.indexOf('interpays.com.br') > -1 ? '47.766.168/0001-76' : '49.372.130/0001-44'}, com endereço na {window.location.hostname.indexOf('interpays.com.br') > -1 ? 'Rua Arthur Maia, 44, Maravista, Niteroi/RJ - CEP 24.342-076' : 'Avenida C255, 270, Nova Suiça, Goiânia/GO - CEP 74.280-010'}.</p>
                        <p>Este documento visa prestar informações sobre o modo de utilização da plataforma pelos Usuários e suas ferramentas, condições, nomenclaturas, direitos e deveres. Alertamos que todo o texto deve ser lido com atenção e, caso você não concorde com o conteúdo de nossos termos e/ou aviso de privacidade, não dê prosseguimento à navegação ou a utilização de nossos serviços.</p>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>1. Data de disponibilização do texto</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>O presente documento foi redigido e disponibilizado em 13/04/2023.</p>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>2. Explicação dos Termos Técnicos ou em Língua Estrangeira</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>
                                    <p>Abaixo estão dispostos os significados das nomenclaturas técnicas e termos na língua inglesa.</p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>API: Interface de Programação de Aplicações é um conjunto de mecanismos que permitem que dois componentes de software se comuniquem usando um conjunto de definições e protocolos.</li>
                                        <li>Bank as a Service: Serviço de fornecimento de produtos bancários a terceiros não bancários por meio de APIs.</li>
                                        <li>Login: É o processo que permite o acesso a um sistema informático, controlado por meio de identificação e autenticação do Usuário pelas credenciais fornecidas por esse mesmo internauta.  </li>
                                        <li>Online: Termo da língua inglesa cujo significado literal é “em linha”. É habitualmente usado para designar que um determinado Usuário da internet ou de outra rede de computadores está conectado à rede.  </li>
                                        <li>PIX: Modo de transferência monetária instantâneo e de pagamento eletrônico instantâneo em real brasileiro, oferecido pelo Banco Central do Brasil. </li>
                                        <li>QR Code: Código de resposta rápida que, ao ser lido, permite que o Usuário acesse informações de modo instantâneo</li>
                                        <li>Vírus: Software malicioso que infecta sistemas e computadores, com objetivo de causar algum dano, faz cópias de si mesmo e tenta se espalhar para outros computadores, utilizando-se de diversos meios.</li>
                                    </ul>
                                </li>
                                <li>
                                    <p>São os tipos de Usuários da plataforma:</p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>Usuários: Pessoas físicas ou jurídicas que se cadastram na plataforma para utilização dos serviços disponíveis</li>
                                    </ul>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>3. Serviços</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <li>
                                <p>O que somos: O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> consiste, mas não se limita, a uma plataforma digital especializada em fornecer soluções de gestão financeira eficientes e seguras para nossos Usuários. </p>
                                <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                    <li>Através de nosso sistema de pagamento com integração de contas, possibilitamos a realização de transações financeiras de forma rápida e conveniente, incluindo intermediação de pagamentos via PIX, pagamentos de contas, envio de cobranças personalizadas via PIX e boleto, além do envio de links de pagamento e transferências instantâneas via PIX</li>
                                    <li>Com nossos recursos inovadores e tecnologia de ponta, oferecemos uma experiência de gestão financeira simplificada e eficiente para nossos Usuários.</li>
                                </ul>
                            </li>
                            <li>
                                <p>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> não é uma Instituição Financeira ou Instituição de Pagamento e não oferece, diretamente, quaisquer serviços privativos dessas, sendo apenas a plataforma digital que viabiliza ao Usuário uma melhor gestão financeira, mediante parceria com uma Instituição de Pagamento viabilizadora, na modalidade Bank as a Service (BaaS).</p>
                                <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                    <li>
                                        <p>Os parceiros envolvidos no serviço da <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> são:</p>
                                        <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                            <li>xxxx</li>
                                            <li>yyyyy</li>
                                        </ul>
                                    </li>  
                                </ul>
                            </li>
                            <li>
                                Outros produtos e serviços, além dos previstos nestes Termos, poderão ser ofertados pelo <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> e poderão estar regulados nestes Termos e/ou em outros instrumentos contratuais, que serão realizados de acordo com o preenchimento de pré-requisitos específicos e que dependerão do aceite do Usuário.
                            </li>
                            <li>
                                Ao utilizar o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, o Usuário deve compreender e aceitar que todas as suas ações e escolhas são livres e não possuem nenhum tipo de influência ou ingerência do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> ou de seus parceiros.
                            </li>
                            <li>
                                Fica esclarecido que o presente serviço não estabelece entre as partes qualquer vínculo empregatício, societário ou associativo, permanecendo cada parte como única responsável por todas as suas respectivas despesas e encargos, sejam de natureza trabalhista, previdenciária, fiscal, securitária, civil, penal ou de qualquer outra natureza ou espécie.
                            </li>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>4. Conta Digital</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>
                                    <p>A conta utilizada na plataforma é uma conta de pagamento pré-paga, de titularidade do Usuário, aberta junto à Instituição de Pagamento xxx (número de banco xx). O Usuário poderá movimentar a conta exclusivamente em ambiente digital, por meio da plataforma e usufruir das funcionalidades previstas nestes Termos de Uso. </p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>Não é possível realizar depósitos na conta por meio de dinheiro em espécie ou cheques, apenas por meio de TED, DOC ou PIX.</li>
                                        <li>A abertura da conta está condicionada ao prévio cadastro do Usuário, a ser realizado exclusivamente no <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> e à verificação, validação e aprovação, pela Instituição de Pagamento xxx, do cadastro do Usuário realizado na plataforma.</li>
                                        <li>Para realização do cadastro do Usuário no <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, além da leitura e o aceite destes Termos de Uso, é obrigatória a leitura e o aceite do Aviso de Privacidade.</li>
                                        <li>
                                            <p>Para fins de cumprimento da Resolução BCB n° 282/2022, bem como de quaisquer outras normas referentes à prevenção aos crimes de lavagem de dinheiro e ao financiamento do terrorismo que venham a substitui-la, o Usuário reconhece e concorda que a Instituição de Pagamento xxx poderá:</p>
                                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                                <li>Adotar procedimentos para assegurar a devida diligência na identificação, qualificação e classificação do Usuário da conta;</li>
                                                <li>Realizar a verificação da condição do Usuário de pessoa exposta politicamente, bem como a verificação da condição de representante, familiar ou estreito colaborador de pessoa exposta politicamente;</li>
                                                <li>Coletar informações sobre a renda e/ou o faturamento do Usuário, conforme aplicável; e</li>
                                                <li>Monitorar as operações realizadas pelo Usuário com o objetivo de identificar suspeitas de lavagem de dinheiro e/ou de financiamento ao terrorismo, bem como comunicar aos órgãos competentes as operações ou situações suspeitas.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <p>No processo de abertura da conta, o Usuário deverá informar os dados cadastrais solicitados, sendo certo que o titular deverá possuir CPF ou CNPJ, conforme aplicável, nos termos da regulamentação do Banco Central do Brasil. A Instituição de Pagamento xxx ou o parceiro poderão solicitar novos dados cadastrais após a abertura da conta, bem como adotar medidas para verificação, validação e aprovação dessas novas informações, as quais deverão ser providas dentro de prazo razoável de acordo com a solicitação.</p>
                                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                                <li>
                                                    <p>Os dados cadastrais fornecidos pelo Usuário deverão ser completos, com informações exatas, precisas e verdadeiras, sendo o Usuário o único responsável, civil e criminalmente, pelos dados fornecidos, obrigando-se, ainda, a atualizá-los sempre que necessário e/ou solicitado pela Instituição de Pagamento xxx ou pelo parceiro, sob pena de: </p>
                                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                                        <li>Restrições à utilização dos serviços; </li>
                                                        <li>Suspensão dos serviços; </li>
                                                        <li>Suspensão, bloqueio, limitação de acesso ou cancelamento da conta; e/ou </li>
                                                        <li>Responsabilização nas esferas cível, administrativa e criminal na forma prevista na legislação aplicável.</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Caso a Instituição de Pagamento xxx e/ou o parceiro verifiquem qualquer divergência, inexatidão ou irregularidade dos dados de cadastro do Usuário, poderão solicitar ao Usuário que atualize e/ou regularize sua situação cadastral e, caso tal solicitação não seja atendida no prazo estipulado, poderão bloquear ou encerrar a conta do Usuário.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            O Usuário, no momento da abertura da conta, será informado do número de identificação de sua conta, bem como definirá a sua senha confidencial para uso pessoal e intransferível.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Esclarece-se que o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> e seus prestadores de serviços são obrigados a seguirem regras de cada Instituição Parceira e não tem qualquer controle ou gerência sobre elas. 
                                </li>
                                <li>Se, por qualquer motivo, uma transação não for efetivada, o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> iniciará tentativas de resolução do problema junto ao Pagador e, até essa resolução, a transação ficará suspensa e o recurso voltará para a sua origem.</li>
                                <li>
                                    <p>Em caso de suspeita de fraude, ou qualquer outra conduta contrária a estes Termos de Uso ou ao Aviso de Privacidade, a plataforma poderá cancelar e/ou estornar uma transação realizada por meio do Anna Bank.</p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>
                                            <p>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> poderá não processar as transações e solicitações de crédito que:</p>
                                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                                <li>Contenham dados incompletos, contraditórios ou inválidos;</li>
                                                <li>Envolvam riscos de qualquer natureza; </li>
                                                <li>Estejam em desacordo aos presentes Termos de Uso ou Aviso de Privacidade.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Caso essas situações sejam denunciadas ou verificadas pelo <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, os pagamentos efetuados e os saldos na conta digital do Usuário poderão ser bloqueados total ou parcialmente: </p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>Por riscos de ações judiciais, reclamações pendentes ou por débitos de qualquer natureza contra o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>;</li>
                                        <li>Ocorrência de chargebacks, inclusive como garantia para cobrir potenciais danos à plataforma e seus demais Usuários;</li>
                                        <li>Em caso de suspeita de qualquer irregularidade, fraude ou qualquer outro ato contrário às disposições dos presentes Termos de Uso ou no Aviso de Privacidade; </li>
                                        <li>Questões relativas à idoneidade do Usuário ou a informações cadastrais; </li>
                                        <li>Caso seja identificada abertura de nova conta de mesma titularidade do Usuário ou em nome de terceiros, em que há suspeita de que a finalidade de tal nova conta seja a de fraudar credores e/ou burlar os presentes Termos de Uso ou Aviso de Privacidade;</li>
                                        <li>Ilegalidade das transações realizadas;</li>
                                        <li>Por ordem judicial ou por ordem de autoridade policial.</li>
                                    </ul>
                                </li>
                                <li>
                                    É de responsabilidade exclusiva do Usuário o pagamento, recolhimento de taxas e/ou retenção de tributos devidos em razão das transações realizadas por meio da plataforma. Caso os impostos ou tributos estejam incluídos na transação, as informações serão fornecidas antes da contratação e/ou no contrato celebrado entre as partes.
                                </li>
                                <li>
                                    <p>Ainda, o Usuário se declara ciente que:</p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>Qualquer transação efetuada está dependente da existência de saldo suficiente em sua origem</li>
                                        <li>As transações efetuadas por meio de PIX serão processadas no mesmo dia.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>5. Cobranças, PIX, Geração de Boletos e QRCode</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>Pela plataforma, o Usuário poderá proceder à cobrança de valores por intermédio de pagamentos instantâneos, via PIX. Para tanto, o Usuário poderá emitir QR Code e/ou boleto para efetuar recebimentos.</li>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> não tem qualquer responsabilidade pelas informações de pagamento inseridas durante a emissão dos boletos e/ou QR Code. O Usuário é integralmente responsável pela inserção correta das informações relacionadas a suas cobranças.</li>
                                <li>O Usuário se compromete a somente emitir boletos e/ou QR Codes com informações reais e válidas, podendo ter sua conta bloqueada ou encerrada em caso de uso de informações indevidas e/ou irregulares.</li>
                                <li>Os boletos gerados são automaticamente registrados, em atendimento à regulamentação do Banco Central do Brasil.</li>
                                <li>O Usuário é responsável por controlar a emissão e o envio dos boletos e/ou QR Codes emitidos, bem como monitorar os respectivos recebimentos em sua conta.</li>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> não possui qualquer responsabilidade pelo não pagamento dos boletos e/ou QR Codes gerados, nem por documentos gerados com incorreções e/ou por lançamentos inconsistentes, efetuados pelo Usuário, tampouco pelas situações em que o cliente/devedor não receba ou não consiga acessar o boleto e/ou QR Code, seja qual for a forma de envio disponibilizada.</li>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> não realiza protestos de forma automática. O Usuário é o único responsável pela realização de qualquer protesto de títulos.</li>
                                <li>Para todos os fins de direito, presume-se que os boletos e/ou QR Codes emitidos pelo Usuário são correspondentes aos documentos originais dos títulos, sendo, portanto, instrumentos legais para o pagamento das obrigações neles contidas.</li>
                                <li>É responsabilidade exclusiva do Usuário, quando e onde exigida, a apresentação dos documentos relativos aos títulos em cobrança, que comprovem a compra, venda, entrega de mercadorias ou prestação de serviços, conforme o caso, assim como a prova do vínculo contratual que autoriza a cobrança. O Usuário é o fiel depositário dos documentos comprobatórios e, em eventuais casos de discordância de valor, vencimento ou qualquer outro dado impresso no boleto e/ou QR Code, deverão ser resolvidos entre o Usuário e o seu cliente/devedor.</li>
                                <li>Em sendo imposto ao <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> qualquer ônus ou responsabilidade financeira decorrente de protesto ou cobrança levado a efeito no interesse do Usuário, em razão da inexigibilidade ou irregularidade do Usuário, caberá a este ressarcir ao <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> os valores eventualmente despendidos em face de eventuais questionamentos judiciais e/ou administrativos.</li>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> está isento de qualquer responsabilidade por ações judiciais, extrajudiciais e em órgãos de defesa do consumidor, de iniciativa dos clientes/devedores do Usuário, em razão de cobranças indevidas.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>6. Transferências</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>O Usuário poderá realizar transferências, via PIX. Para isso, o Usuário está ciente de que:
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>Deve incluir todas as informações solicitadas pelo <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> para a realização de transferências na modalidade escolhida. Essas informações são obrigatórias de acordo com a legislação aplicável e o Usuário é o único responsável pela inserção correta das informações</li>
                                        <li>Caso as informações solicitadas estejam incorretas e/ou em desconformidade, a transferência não será processada e haverá a incidência da tarifa referente a tentativa de transferência.</li>
                                    </ul>
                                </li>
                                <li>
                                O Usuário sempre deverá armazenar o comprovante da transação realizada. Em caso de divergência, a qualquer momento, a Instituição de Pagamento xxx poderá solicitar o comprovante para análise do ocorrido. Caso o Usuário não apresente o comprovante, o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> e a Instituição de Pagamento xxx ficarão isentos de qualquer responsabilidade em relação à transação em questão.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>7. Cadastro de Usuários</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>Para o cadastro e utilização dos serviços do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> serão requeridas inicialmente as seguintes informações: Nome ou Razão Social, CPF ou CNPJ, E-mail, Telefone e Endereço. Nos casos de pessoas jurídicas, serão solicitadas cópias do contrato social e dos documentos pessoais dos sócios. Ainda serão solicitadas informações das cobranças geradas pelos clientes.</li>
                                <li>Cada Usuário determinará o seu login e senha de acesso, sendo de sua exclusiva responsabilidade a manutenção do sigilo dessas informações. O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> não se responsabiliza pelas ações e danos que poderão ser causados pelo acesso irregular da conta de acesso por terceiros.</li>
                                <li>É permitida a realização de apenas 01 (uma) conta por CPF/CNPJ.</li>
                                <li>Os Usuários são responsáveis por fornecer informações verdadeiras, precisas e atualizadas</li>
                                <li>No caso de pessoa jurídica, deve ser nomeado um responsável pela administração de sua conta. Dessa forma, é de inteira responsabilidade do Administrador a fiscalização e o gerenciamento da conta, assim como os acessos de terceiros nomeados por ele, isentando o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> de qualquer responsabilidade por mau uso, atividades ilícitas ou fraudulentas realizadas por esse terceiro, que venham a causar danos à empresa, ao <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, aos parceiros ou à terceiros. </li>
                                <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> poderá recusar, suspender ou cancelar sem notificação prévia a conta de acesso de um Usuário sempre que suspeitar que as informações fornecidas são falsas, incompletas, desatualizadas ou imprecisas ou, ainda, nos casos indicados nas leis e regulamentos aplicáveis, nestes Termos de Uso, no Aviso de Privacidade ou em qualquer Política.  </li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>8. Pagamentos</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>Os Usuários não deverão pagar valores para se cadastrar e utilizar a plataforma, contudo, estarão sujeitos ao pagamento de uma % (porcentagem) sobre cada cobrança liquidada ou preço fixo de acordo com a quantidade de cobranças diárias. Esses valores estarão sempre disponíveis na plataforma e poderão ser alterados a qualquer momento pelo <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, mediante simples aviso prévio ao Usuário</p>
                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>9. Cancelamento Pelo Usuário </h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>
                                    <p>Direito de arrependimento:  O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> respeita todos os direitos de seus Usuários, em especial o direito de arrependimento das compras feitas pela internet no prazo de 07 (sete) dias, contados após a contratação, conforme disposto no Código de Defesa do Consumidor. Após a manifestação de arrependimento, será feito o reembolso de todos os valores eventualmente pagos por serviços do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>pelo Usuário.</p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li><strong style={{textDecoration:"underline"}}>Em nenhuma hipótese será realizado reembolso por taxas cobradas por transações financeiras, como PIX e/ou boleto, uma vez que são serviços que foram totalmente consumidos e que não são passíveis de cancelamento.</strong></li>
                                        <li><strong style={{textDecoration:"underline"}}>Além disso, não é possível cancelar ou se arrepender de qualquer transferência de valores, motivo pelo qual ressaltamos a importância de conferir e confirmar todos os dados antes de realizar qualquer ação.</strong></li>
                                    </ul>
                                </li>
                                <li>
                                    Para cancelar os serviços da plataforma o Usuário deverá acessar a funcionalidade <strong>Minha Conta | Encerrar Conta</strong>. <span style={{textDecoration:"underline"}}>Saldos disponíveis poderão ser sacados imediatamente antes do encerramento, contas com pendências e saldos bloqueados não poderão sacar o saldo bloqueado.</span>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className='box-privacy'>
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>10. Responsabilidade das Partes</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>
                                    <p>Responsabilidade do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>: </p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>Realizar os serviços conforme o descrito nestes Termos de Uso;  </li>
                                        <li>Responsabilizar-se pelo funcionamento da plataforma e pelas correções que eventualmente sejam obrigatórias por lei ou regulações;  </li>
                                        <li>Informar qualquer alteração dos serviços aos Usuários, por meio de comunicados simples.</li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Responsabilidade dos Usuários:  </p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>Utilizar a plataforma conforme os critérios de utilização definidos pelo <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> , sem alterar a sua programação, quebrar senhas ou realizar procedimentos que venham causar prejuízos ao <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> , aos demais Usuários, aos parceiros ou a terceiros;  </li>
                                        <li>Responsabilizar-se para todos os efeitos, inclusive jurídicos, pelo teor das informações que introduzir, as transações que realizar e pelos compromissos que assumir por meio da plataforma;  </li>
                                        <li>Respeitar integralmente estes Termos de Uso, Aviso de Privacidade, regras das Instituições Parceiras, normas do BACEN, legislação vigente e contratos entre as partes;</li>
                                        <li>Responsabilizar-se pelas operações efetuadas na plataforma mediante o uso do seu login e senha;</li>
                                        <li>Fornecer e manter informações exatas, corretas, precisas, verdadeiras, atuais e completas, eximindo o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>  de qualquer responsabilidade nesse sentido;</li>
                                        <li>Manter o sigilo dos dados de acesso à plataforma;</li>
                                        <li>Conhecer plenamente o funcionamento da plataforma, bem como os seus parceiros e os riscos envolvidos na sua utilização, sendo que o Usuário deve ter plena capacidade e competência para realizar as operações;</li>
                                        <li>Adotar boas práticas na criação de senha de acesso, não compartilhar dados com terceiros, como logins e senhas, utilizar senhas fortes, não utilizar a senha do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>  em outros sites ou serviços, trocando-a periodicamente;</li>
                                        <li>Deslogar-se (sair) da plataforma ao fim de sua utilização;</li>
                                        <li>Evitar o uso em computadores ou redes de acesso público; e</li>
                                        <li>Manter o sistema operacional e antivírus atualizados.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy' id="privacy-alert">
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>11. Isenção de Responsabilidade do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> </h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>
                                    <p>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> não se responsabiliza por:  </p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>Eventual indisponibilidade da plataforma, a qual não tenha dado causa.</li>
                                        <li>Condutas dos Usuários, das Instituições Parceiras ou de terceiros e pelo descumprimento de suas obrigações.  </li>
                                        <li>Inserção de informações falsas pelos Usuários.</li>
                                        <li>Danos que o Usuário possa experimentar por ações exclusivas de terceiros, bem como falhas na conexão de rede e interações maliciosas como vírus.  </li>
                                        <li>Danos que o Usuário possa ter em decorrência do mau uso da plataforma em desconformidade com estes Termos, com o Aviso de Privacidade, com a lei ou ordens judiciais.  </li>
                                        <li>Casos fortuitos ou de força maior. </li>
                                        <li>Cumprimento das normas e requisitos legais por parte das Instituições Parceiras ou de terceiros, sendo que o Usuário é responsável pela verificação prévia da regularidade e idoneidade desses agentes.</li>
                                        <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> não garante que as Instituições Parceiras cumpram suas obrigações contratuais e legalmente estabelecidas, não assegura a continuidade dos serviços ou a manutenção das ofertas e cotações, sem prejuízo da boa conclusão dos serviços já iniciados.</li>
                                        <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> não atua e nem substituí consultores e assessores especializados na contratação de serviços financeiros.</li>
                                        <li>Pelas decisões financeiras que o Usuário possa vir a fazer por meio da plataforma.</li>
                                        <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> não promete qualquer forma de rentabilidade ou de previsibilidade de taxas, tarifas, ganhos, lucros, perdas ou condições de mercado.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy' id="privacy-alert">
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>12.  Regras de Conduta e Proibições</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>
                                    <p>Os Usuários não podem:  </p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>Lesar direitos do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, dos operadores da plataforma, dos parceiros, de outros Usuários, de terceiros ou agir sob qualquer meio ou forma que possa contribuir com tal violação;  </li>
                                        <li>Executar atos que limitem ou impeçam a utilização da plataforma ou acessar ilicitamente ao Anna Bank;  </li>
                                        <li>Utilizar a ferramenta para praticar ações ilícitas, tais como fraude, estelionato, roubo, furto, apropriação indébita e lavagem de dinheiro;</li>
                                        <li>Inserir dados que sejam falsos, desatualizados ou incompletos;  </li>
                                        <li>Responsabilizar ao <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> por condutas de Usuários que estejam cadastrados na plataforma, de terceiros e das Instituições Parceiras;</li>
                                        <li>Difundir mensagens não relacionadas com a plataforma ou com suas finalidades, incluindo mensagens com conteúdo impróprio ou que ofendam Usuários, ao <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>ou a terceiros.</li>
                                    </ul>
                                </li>
                                <li>
                                    Ao sinal de qualquer descumprimento de conduta, o Usuário poderá ser bloqueado ou excluído da plataforma e os serviços interrompidos, sem necessidade de aviso prévio.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy' id="privacy-alert">
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>13.  Propriedade Intelectual</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>
                                    A titularidade e os direitos relativos à plataforma pertencem exclusivamente à empresa proprietária do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>. O acesso à plataforma e a sua regular utilização pelo Usuário não lhe conferem qualquer direito ou prerrogativa sobre propriedade intelectual ou outro conteúdo nela inserido.
                                </li>
                                <li>
                                    Todo o conteúdo da plataforma, incluindo nome, marca, domínio, programas, bases de dados, arquivos, textos, desenhos, fotos, layouts, cabeçalhos e demais elementos, foi criado, desenvolvido ou cedido à empresa proprietária do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>, sendo, portanto, de propriedade exclusiva da empresa proprietária do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> ou a ela licenciado e encontra-se protegido pelas leis brasileiras e tratados internacionais que versam sobre direitos de propriedade intelectual.
                                </li>
                                <li>
                                    São proibidas: a exploração, cessão, imitação, cópia, plágio, aplicação de engenharia reversa, tentativa de invasão (hackear), armazenamento, alteração, modificação de características, ampliação, venda, locação, doação, alienação, transferência, reprodução, integral ou parcial, de qualquer conteúdo da plataforma.
                                </li>
                                <li>
                                    A pessoa que violar as proibições contidas na legislação sobre propriedade intelectual e nestes Termos será responsabilizada, civil e criminalmente, pelas infrações cometidas, além de poder ser penalizado na Plataforma.  
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy' id="privacy-alert">
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>14.  Tratamento de Dados Pessoais, Privacidade e Segurança</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> dispõe de uma política específica para regular a coleta, guarda e tratamento de dados pessoais, bem como a sua segurança: o Aviso de Privacidade. Esse Aviso integra inseparavelmente estes Termos, ressaltando-se que os dados de utilização da plataforma serão arquivados nos termos da legislação em vigor.</p>
                        </div>
                    </div>
                    <div className='box-privacy' id="privacy-alert">
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>15.  AntiCorrupção, Antissuborno e Antilavagem de Dinheiro</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>
                                    <p>O Usuário e o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> se comprometem a observar as normas de prevenção e combate à corrupção previstas na legislação brasileira, dentre elas, a Lei nº 12.846 de 2013 e o Decreto nº 11.129 de 2022, dentre outras normatizações expedidas pelos órgãos competentes (em conjunto, “Leis Anticorrupção”), devendo cumpri-las, por si e por seus sócios, diretores, gerentes e colaboradores, exigindo ainda o seu cumprimento dos terceiros e parceiros contratados. O Usuário e o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> estão cientes que as seguintes condutas são proibidas pela legislação brasileira e para fins deste Contrato, sendo consideradas atos lesivos à administração pública, nacional e estrangeira, e a boa-fé contratual: i. prometer, oferecer e dar, direta ou indiretamente, vantagem indevida a agente público, ou a terceira pessoa a ele relacionada ou ainda a quaisquer outras pessoas, sejam físicas ou jurídicas, com ou sem fins lucrativos, com o objetivo de obter vantagem indevida direta ou indireta, influenciar ato ou decisão ou orientar práticas comerciais ilicitamente; ii. financiar, custear, patrocinar ou de qualquer modo subvencionar a prática de atos ilícitos previsto na Lei n. 12.846/2013; iii. utilizar-se de interposta pessoa física ou jurídica para ocultar ou dissimular seus reais interesses ou a identidade dos beneficiários dos atos praticados. Ainda, ressalta-se que as condutas aqui listadas não são taxativas, devendo o Usuário e o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> conhecer e cumprir fielmente à legislação brasileira e/ou estrangeira, porventura aplicável, em matéria de anticorrupção e antissuborno.</p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>Na hipótese de violação dessa cláusula ou a acusação por autoridades públicas de práticas corruptas e lesivas à administração pública, seja nacional ou estrangeira, a Parte inocente poderá resolver o presente Contrato, com base no descumprimento contratual da Parte infratora, estando esta sujeita a indenizar as perdas e danos causados, devendo ainda ressarcir as despesas que a Parte inocente porventura incorrer na cooperação com inquéritos, processos administrativos ou judiciais.</li>
                                    </ul>
                                </li>
                                <li>
                                    O Usuário e o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> declaram-se conhecedoras das leis que dispõem sobre a prevenção e combate aos crimes de “Lavagem de Dinheiro”, como a Lei nº 9.613 de 1998 e das normas complementares editadas pelo CMN, BACEN, Ministério da Fazenda e/ou COAF que disciplinam a Prevenção e Combate aos Crimes de "Lavagem" ou Ocultação de Bens, Direito e Valores ("Lavagem de Dinheiro”).
                                </li>
                                <li>
                                    <p>O Usuário e o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> declaram que não constam nos históricos de crimes financeiros e que não se encontram enquadrados em listas restritivas, como a Specially Designated Nationals and Blocked Persons (SDN) e Office of Foreign Assets Control (OFAC) ou qualquer lista de natureza similar promulgada por qualquer outra jurisdição relevante.</p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>O Usuário e o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> se comprometem a realizar Due Diligence de terceiros, sejam eles clientes, fornecedores ou parceiros, principalmente aos que se enquadrem como Pessoas Expostas Politicamente (PEP’s), conforme definição do Conselho de Controle de Atividades Financeiras (COAF), a partir da Resolução nº 29 de 2017. Além disso, firmam as mesmas obrigações de investigação de terceiros para empresas cuja atividade principal esteja listada na Lei nº 9.613 de 1998, cuja localização geográfica residencial dos sócios ou comercial da empresa seja definida pelas autoridades brasileiras como paraíso fiscal ou região de fronteira.</li>
                                    </ul>
                                </li>
                                <li>
                                    O Usuário e o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> se comprometem a realizar qualquer operação de natureza cambial de acordo com os requisitos definidos pelo Banco Central do Brasil, além de se comprometerem a não incorrer em nenhuma modalidade dos tipos penais descritos no artigo 22 da Lei nº 7.492 de 1986.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy' id="privacy-alert">
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>16.  Desdobramento do Acesso a Plataforma</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>
                                    <p>Apesar dos melhores esforços do <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> para fornecer as melhores tecnologias para manter a conexão e sincronização online e acesso seguro aos Usuários, em virtude de dificuldades técnicas, aplicações de internet ou problemas de transmissão, é possível ocorrer cópias inexatas ou incompletas das informações contidas na plataforma. Além disso, vírus de computador ou outros programas danosos também poderão ser baixados inadvertidamente da plataforma.</p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> recomenda a instalação de antivírus ou protetores adequados.</li>
                                    </ul>
                                </li>
                                <li>
                                    O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> se reserva o direito de unilateralmente modificar a plataforma, bem como a configuração, a apresentação, o desenho, o conteúdo, as funcionalidades, as ferramentas ou qualquer outro elemento, inclusive o seu cancelamento.
                                </li>
                                <li>
                                    O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> pode conter links de sites/plataformas de terceiros. Ao seguir um link, o Usuário deve verificar as políticas de privacidade desses sites/plataformas, pois não aceitamos qualquer responsabilidade ou obrigação por essas políticas. Por favor, verifique essas políticas antes de compartilhar quaisquer dados pessoais com esses sites.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy' id="privacy-alert">
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>17.  Alterações dos Termos e Condições de Uso</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>
                                    O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> poderá unilateralmente adicionar e/ou modificar qualquer cláusula contida nestes Termos de Uso. A versão atualizada valerá para o uso da plataforma realizada a partir de sua publicação. A continuidade de acesso ou utilização da plataforma, depois da divulgação, confirmará a vigência dos novos Termos de Uso pelos Usuários.
                                </li>
                                <li>
                                    Caso a mudança efetuada necessite de consentimento do Usuário, será apresentada a opção de aceitar de forma livre, inequívoca e informada o novo texto ou recusá-lo.
                                </li>
                                <li>
                                    Caso o Usuário não esteja de acordo com a alteração, poderá não fornecer consentimento para atos específicos ou poderá rescindir totalmente seu vínculo com o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong>. Essa rescisão não eximirá, no entanto, o Usuário de cumprir com todas as obrigações assumidas sob as versões precedentes dos Termos de Uso. 
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy' id="privacy-alert">
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>18.  Lei Aplicável e Foro de Eleição</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                <li>
                                    <p>A plataforma é controlada e administrada na cidade de Goiânia, Estado de Goiás, Brasil, podendo ser acessada por qualquer dispositivo conectado à Internet, independentemente de sua localização geográfica.</p>
                                    <ul style={{marginLeft:"50px", marginTop:"10px"}}>
                                        <li>As pessoas que acessam ou usam o <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> a partir de outros países o fazem por conta própria e são responsáveis pelo cumprimento das leis regionais/nacionais.</li>
                                    </ul>
                                </li>
                                <li>
                                    O Usuário concorda que a legislação aplicável para fins destes Termos e Condições de Uso e das Políticas de Privacidade será a vigente na República Federativa do Brasil.   
                                </li>
                                <li>
                                    O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> e o Usuário concordam que o Foro de Goiânia, Estado de Goiás, Brasil, será o único competente para dirimir qualquer questão ou controvérsia oriunda ou resultante do uso da plataforma, renunciando expressamente a qualquer outro, por mais privilegiado que seja ou venha a ser.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='box-privacy' id="privacy-alert">
                        <div style={{width:"30%"}}>
                            <h5 style={{color:"#4b4e5c"}}>19.  Contato</h5>
                        </div>
                        <div style={{width:"70%", fontSize:"16px", color:"#666"}}>
                            <p>O <strong>{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'INTERPAYS BANK' : 'ANNA BANK'}</strong> disponibiliza o seguinte canal para receber todas as comunicações que os Usuários desejarem fazer: por meio do e-mail contato@{window.location.hostname.indexOf('interpays.com.br') > -1 ? 'interpays.com.br' : 'annabank.com.br'}.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}