import { useEffect, useState } from "react";
import { AppContent } from "../../../../components/AppContent";
import { Breadcrumps } from "../../../../components/Breadcrumbs";
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import { Autocomplete, Card, FormHelperText, Grid, InputAdornment, Stack, TextField } from "@mui/material";
import { Button } from "../../../../components/Button";
import { useSnackbar } from 'notistack';
import * as apiInterpays from '../../../../services/apiInterpays';
import * as handleRequestError from '../../../../utils/handleRequestError';
import { SyncLoader } from "react-spinners";
import { colors } from "../../../../styles/global";
import * as utils from '../../../../utils/utils';
import * as configSnackbar from '../../../../utils/configDefaultSnackbar';
import Mask from 'vanilla-masker';

export function Interna() {
    const { enqueueSnackbar } = useSnackbar();
    const [origins, setOrigins] = useState<any[]>([]);
    const [searchingOrigins, setSearchingOrigins] = useState(false);
    const [originSelected, setOriginSelected] = useState(null);
    const [destinies, setDestinies] = useState<any[]>([]);
    const [destinySelected, setDestinySelected] = useState(null);
    const [searchingDestinies, setSearchingDestinies] = useState(false);
    const [searchingBalance, setSearchingBalance] = useState(false);
    const [balance, setBalance] = useState(0);
    const [value, setValue] = useState('0');
    const [valueErrorMessage, setValueErrorMessage] = useState<string | null>(null);
    const [saving, setSaving] = useState(false);

    const handleSearchAccountsOrigins = async () => {
        setSearchingOrigins(true);
        await apiInterpays.API.get('/statements/accounts')
            .then(async ({ data }) => {
                const options: any[] = [];
                await data.forEach((item: any) => {
                    options.push({
                        value: item.account.id,
                        label: `${item.account.name} - ${("000000" + item.account.account.toString()).slice(-7)}`
                    })
                });
                setOrigins(options);
            }).catch(({ response }) => handleRequestError.handleError(response, enqueueSnackbar));
        setSearchingOrigins(false)
    }

    const handleSearchAccountsDestiny = async () => {
        setDestinies([]);
        setSearchingDestinies(true);
        await apiInterpays.API.get(`/statements/accounts/${originSelected}`)
            .then(async ({ data }) => {
                const options: any[] = [];
                await data.forEach((item: any) => {
                    options.push({
                        value: item.id,
                        label: `${item.name} - ${("000000" + item.account.toString()).slice(-7)}`
                    })
                });
                setDestinies(options);
            }).catch(({ response }) => handleRequestError.handleError(response, enqueueSnackbar));
        setSearchingDestinies(false);
    }

    const handleBalance = async () => {
        setSearchingBalance(true);
        await apiInterpays.API.get(`/balance/${originSelected}`)
            .then(({ data }) => {
                setBalance(data.value);
            }).catch(({ response }) => handleRequestError.handleError(response, enqueueSnackbar));
        setSearchingBalance(false);
    }

    const handleSave = async () => {
        if (valueErrorMessage) return;

        setSaving(true);

        const options = {
            method: 'POST',
            url: '/transfer',
            data: {
                accountOrigin: originSelected,
                accountDestiny: destinySelected,
                valor: parseFloat(value.replace(/\./g, '').replace(',', '.')),
            }
        }
        await apiInterpays.API.request(options)
            .then(({ data }) => {
                enqueueSnackbar('Tranferência finalizada com sucesso!', configSnackbar.success);
                setOriginSelected(null);
                setDestinySelected(null);
                setValue('0');
                setBalance(0);
            }).catch(({ response }) => handleRequestError.handleError(response, enqueueSnackbar));
        setSaving(false);
    }

    useEffect(() => {
        handleSearchAccountsOrigins();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (originSelected) {
            handleSearchAccountsDestiny();
            handleBalance();
        }
        //eslint-disable-next-line
    }, [originSelected]);



    return (
        <AppContent>
            <Breadcrumps
                Icon={HiOutlineSwitchHorizontal}
                title="Interna"
                links={[
                    { name: 'Transferência', href: '#' },
                    { name: 'Interna', href: '/app/interna' }
                ]}
            />

            <Grid container justifyContent="center" padding="0 15px">
                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ maxHeight: 460, minHeight: 250, borderRadius: 5, paddingBottom: 1 }}>
                        <Stack padding={2} spacing={3}>
                            <h4>Transferência interna</h4>

                            <Stack direction={{ xs: 'row' }} alignItems="center">
                                <Autocomplete
                                    disablePortal
                                    fullWidth
                                    options={origins}
                                    size="small"
                                    disabled={searchingOrigins}
                                    sx={{ marginTop: 2 }}
                                    onChange={(event, value) => setOriginSelected(value?.value ?? null)}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label="Conta origem"
                                    />}
                                />
                                {searchingOrigins && <SyncLoader size={6} speedMultiplier={0.7} color={colors.turquoise} />}

                            </Stack>

                            <Stack direction={{ xs: 'row' }} alignItems="center">
                                <Autocomplete
                                    disablePortal
                                    fullWidth
                                    options={destinies}
                                    size="small"
                                    disabled={searchingDestinies}
                                    sx={{ marginTop: 2 }}
                                    onChange={(event, value) => setDestinySelected(value?.value ?? null)}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label="Conta destino"
                                    />}
                                />
                                {searchingDestinies && <SyncLoader size={6} speedMultiplier={0.7} color={colors.turquoise} />}

                            </Stack>

                            <Stack>
                                {
                                    originSelected ?
                                        <span>Saldo disponível em conta {searchingBalance ? <SyncLoader size={6} speedMultiplier={0.7} color={colors.turquoise} /> : <strong>{utils.toMoney(balance)}</strong>}</span>
                                        :
                                        <span>Selecione uma conta de origem para ver seu saldo</span>
                                }
                            </Stack>
                            {
                                originSelected && !searchingBalance && (
                                    <Stack>
                                        <TextField
                                            size="small"
                                            label="Valor"
                                            value={value}
                                            onChange={event => {
                                                event.target.value = Mask.toMoney(event.target.value.replace(/\D/g, ''))
                                                const newValue = parseFloat(event.target.value.replace(/\./, '').replace(',', '.'));
                                                setValueErrorMessage(newValue > balance ? 'Valor informado é maior que saldo disponível' : null);
                                                setValue(event.target.value);
                                            }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">R$</InputAdornment>
                                            }}
                                        />
                                        {
                                            valueErrorMessage && <FormHelperText error>{valueErrorMessage}</FormHelperText>
                                        }
                                    </Stack>
                                )
                            }

                            <Stack>
                                <Button
                                    onClick={handleSave}
                                    type="button"
                                    disabled={!destinySelected}
                                    load={saving}
                                    typeLoad="pacman"
                                    color="turquoise">Salvar</Button>
                            </Stack>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </AppContent>
    )
}