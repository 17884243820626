import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 120px);

    #video-home {
        top: 0;
        position: fixed;
        width: 260vh;
        /* right: 0;
        bottom: 0; */
        /* min-width: 100vw;  */
        /* min-height: 100%; */

        /* @media (max-width: 1555px) {
            position: fixed;
        } */
    }
`

export const Content = styled.div`
    width: 100%;
    position: absolute;
    /* max-width: 1936px; */
    /* padding: 15px 0; */
    justify-content: center;
    display: flex;
    z-index: 1;

   
`;